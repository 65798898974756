const formatTime = (timeString) => {
    if (!timeString) return ''; // Return an empty string if timeString is null or undefined
    const parts = timeString.split(':');
    return parts[0] + ':' + parts[1]; // Concatenate the hour and minute parts
};

const calculateDaysDifference = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffTime = Math.abs(end - start);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
};

// const formatDate = (date) => {
//     const d = new Date(date);
//     return `${d.getDate().toString().padStart(2, '0')}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getFullYear().toString().substring(2)}`;
// };

// const formatDate = (date) => {
//     const d = new Date(date);
//     const day = d.getDate().toString().padStart(2, '0');
//     const monthIndex = d.getMonth();
//     const year = d.getFullYear().toString().substring(2);
//     const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
//     const month = monthNames[monthIndex];
//     return `${day} ${month} ${year}`;
// }

const formatDate = (date, showDayOfWeek = false, showYear = true) => {
    const d = new Date(date);
    const day = d.getDate().toString().padStart(2, '0');
    const monthIndex = d.getMonth();
    const year = d.getFullYear().toString().substring(2);
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = monthNames[monthIndex];

    // Determine if the day of the week should be added
    let dayOfWeek = '';
    if (showDayOfWeek) {
        const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        dayOfWeek = dayNames[d.getDay()] + ', ';
    }

    // Determine if the year should be included
    const yearPart = showYear ? ` ${year}` : '';

    return `${dayOfWeek}${day} ${month}${yearPart}`;
}



export { formatTime, calculateDaysDifference, formatDate };