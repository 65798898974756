import React, { createContext, useState, useEffect } from 'react';
import axios from '../axios'; // Adjust the path as needed
import { useAuth } from '../contexts/AuthContext'; // Import useAuth if not already imported
import { useLoading } from './LoadingContext'; // Import useLoading

export const AdminDataContext = createContext();

export const AdminDataProvider = ({ children }) => {
    const { user } = useAuth();
    const [data, setData] = useState({
        locations: [],
        preferences: {},
        companies: [], // Added for storing company data
        selectedCompanyId: null // To store the currently selected company ID
    });
    const [localLoading, setLocalLoading] = useState({ locations: true, companies: true });
    const { setLoading } = useLoading();

    const updatePreferences = (newPreferences) => {
        setData(prevData => ({
            ...prevData,
            preferences: newPreferences
        }));
    };

    // Fetch data based on the selected company ID
    const fetchData = async (companyId) => {
        setLoading(true); // Set global loading state for spinner
        setLocalLoading({ locations: true }); // Set local loading states
        try {
            // Fetch locations depending on the user role and companyId
            let url = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PREFIX}/workation-locations`;
            let config = {};
            if (user.role === 'admin' && companyId) {
                config.params = { company_id: companyId };
            }

            const locationsResponse = await axios.get(url, config);
            const locationsData = locationsResponse.data;

            setData(prevData => ({
                ...prevData,
                locations: locationsData.locations,
                preferences: locationsData.preferences,
            }));
            setLocalLoading({ locations: false, workations: false });
        } catch (error) {
            console.error('Error fetching data:', error);
            setLocalLoading({ locations: false, workations: false });

        } finally {
            setLoading(false);
        }
    };

    // Function to handle company changes
    const handleCompanyChange = (newCompanyId) => {
        setData(prevData => ({
            ...prevData,
            selectedCompanyId: newCompanyId
        }));
        fetchData(newCompanyId);  // Pass newCompanyId directly
    };

    // Initially fetch companies
    useEffect(() => {
        setLoading(true);
        setLocalLoading({ companies: true });
        if (user.role === 'admin') {
            const fetchCompanies = async () => {
                try {
                    const companiesResponse = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PREFIX}/company-configuration/get-companies/`);
                    setData(prevData => ({
                        ...prevData,
                        companies: companiesResponse.data.companies,
                        selectedCompanyId: companiesResponse.data.companies.length > 0 ? companiesResponse.data.companies[0].company_id : null
                    }));
                } catch (error) {
                    console.error('Error fetching companies:', error);
                } finally {
                    setLoading(false);
                    setLocalLoading({ companies: false });
                }
            };

            fetchCompanies();
        } else if (user.role === 'coordinator') {
            setLoading(false);
            setLocalLoading({ companies: false });
            fetchData();
        }
    }, [user.role]);


    return (
        <AdminDataContext.Provider value={{ data, updatePreferences, handleCompanyChange, loading: localLoading }}>
            {children}
        </AdminDataContext.Provider>
    );
};
