import React, { useState } from 'react';
import { DataProvider } from './components/DataContext'; // Import DataProvider
import { AdminDataProvider } from './components/AdminDataContext'; // Import DataProvider
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { lightTheme, darkTheme } from './theme/appTheme';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { useAuth } from './contexts/AuthContext';
import { LoadingProvider } from './components/LoadingContext';
import GlobalSpinner from './components/GlobalSpinner';
import RouteHandler from './components/RouteHandler'; // Import RouteHandler
import UnauthorizedListener from './components/UnauthorizedListener'; // Adjust the import path as needed
import { Box } from '@mui/material';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Register from './components/Register';
import ForgotPassword from './components/ForgotPassword';
import LoginForm from './components/LoginForm';
import ProtectedRoute from './components/ProtectedRoute';
import Home from './components/Home';
import NotFound from './components/NotFound';
import CompanySettings from './components/CompanySettings';
import VouchersMain from './components/VouchersMain';
import PasswordResetForm from './components/PasswordResetForm';
import RoleBasedRoute from './components/RoleBasedRoute';
import AdminDashboard from './components/AdminDashboard';
import ConfigureLocation from './components/ConfigureLocation';
import DemandRequest from './components/DemandRequest';
import LocationDetail from './components/LocationDetail';
import SlotBookingConfirmation from './components/SlotBookingConfirmation';
import MyWorkations from './components/MyWorkations';
import HandleRequest from './components/Requests/HandleRequest'
import VerifyEmail from './components/Requests/VerifyEmail'
import TravelForm from './components/TravelForm/TravelForm'
import TermsAndConditions from './components/TermsAndConditions'

const AppRoutes = ({ isDarkMode }) => {
  return (
    <Routes>
      <Route path="/login" element={<RouteHandler><LoginForm /></RouteHandler>} />
      <Route path="/forgotpassword" element={<RouteHandler><ForgotPassword /></RouteHandler>} />
      <Route path="/register" element={<RouteHandler><Register /></RouteHandler>} />
      <Route path="/termsandconditions" element={<RouteHandler><TermsAndConditions /></RouteHandler>} />
      <Route path="/password-reset/:token" element={<PasswordResetForm />} />
      <Route path="/requests/:token/:action" element={<HandleRequest />} />
      <Route path="/travel-form/:token" element={<TravelForm />} />
      <Route path="/verify-email/:userId/:token" element={<VerifyEmail />} />
      <Route path="/" element={<ProtectedRoute />}>
        <Route index element={<RoleBasedRoute allowedRoles={[]}></RoleBasedRoute>} />
        <Route path="/home" element={<RoleBasedRoute allowedRoles={['user']}><RouteHandler><Home /></RouteHandler></RoleBasedRoute>} />
        <Route path="/company-settings" element={<RoleBasedRoute allowedRoles={['admin', 'coordinator']}><CompanySettings /></RoleBasedRoute>} />
        <Route path="/vouchers" element={<RoleBasedRoute allowedRoles={['admin', 'coordinator']}><VouchersMain /></RoleBasedRoute>} />
        <Route path="/configure-location/:locationId" element={<RoleBasedRoute allowedRoles={['admin', 'coordinator']}><ConfigureLocation /></RoleBasedRoute>} />
        <Route path="/my-workations" element={<RoleBasedRoute allowedRoles={['user']}><MyWorkations /></RoleBasedRoute>} />
        <Route path="/admin-dashboard" element={<RoleBasedRoute allowedRoles={['admin', 'coordinator']}><AdminDashboard /></RoleBasedRoute>} />
        <Route path="/location/:locationId" element={<RoleBasedRoute allowedRoles={['user', 'admin', 'coordinator']}><LocationDetail isDarkMode={isDarkMode} /></RoleBasedRoute>} />
        <Route path="/demand-request" element={<RoleBasedRoute allowedRoles={['user']}><DemandRequest /></RoleBasedRoute>} />
        <Route path="/booking-confirmation/:locationId/:slotId" element={<RoleBasedRoute allowedRoles={['user']}><SlotBookingConfirmation isDarkMode={isDarkMode} /></RoleBasedRoute>} />
      </Route>
      <Route path="*" element={<RouteHandler><NotFound /></RouteHandler>} />
    </Routes>
  );
};

const App = () => {
  const [isDarkMode, setUseDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('isDarkMode');
    return savedMode === 'true' ? true : false;
  });
  const { user, loading } = useAuth(); // Use the useAuth hook here

  const toggleTheme = () => {
    const newIsDarkMode = !isDarkMode;
    setUseDarkMode(newIsDarkMode);
    // Save the new setting to localStorage
    localStorage.setItem('isDarkMode', newIsDarkMode.toString());
  };

  if (loading) {
    return <div>Loading...</div>; // Or some other loading indicator
  }
  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar />
      <LoadingProvider>
        {user ? (  // Check if there is an authenticated user
          (user.role === "admin" || user.role === "coordinator") ? (
            <AdminDataProvider>
              <MainAppContent toggleTheme={toggleTheme} isDarkMode={isDarkMode} />
            </AdminDataProvider>
          ) : (
            <DataProvider>
              <MainAppContent toggleTheme={toggleTheme} isDarkMode={isDarkMode} />
            </DataProvider>
          )
        ) : (
          <MainAppContent toggleTheme={toggleTheme} isDarkMode={isDarkMode} />  // Render without any provider if no user is logged in
        )}
      </LoadingProvider>
    </ThemeProvider>

  );
};

const MainAppContent = ({ toggleTheme, isDarkMode }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
    <CssBaseline />

    <Router>
      <UnauthorizedListener />
      <GlobalSpinner />
      <Header toggleTheme={toggleTheme} isDarkMode={isDarkMode} />
      <Box component="main" sx={{ flex: 1, py: 3 }}>
        <AppRoutes isDarkMode={isDarkMode} />
      </Box>      <Footer />
    </Router>
  </Box>
);

export default () => (
  <AuthProvider>
    <App />
  </AuthProvider>
);