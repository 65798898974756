import React, { useState, useContext, useEffect } from 'react';
import { Container, Box, Typography, IconButton, Button, Checkbox, TextField, FormControlLabel, CircularProgress, Stack } from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { AdminDataContext } from './AdminDataContext';
import axios from '../axios';
import { useAuth } from '../contexts/AuthContext';
import Highlight from '../utils/Highlight';

import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const CompanySettings = () => {
    const navigate = useNavigate();
    const { data, updatePreferences, loading: dataLoading } = useContext(AdminDataContext);
    const { user } = useAuth();

    const [settings, setSettings] = useState({
        individual: data.preferences.individual || 0,
        invite_individuals: data.preferences.invite_individuals || 0,
        allow_accompanying: data.preferences.allow_accompanying || 0,
        allow_bedroom_sharing: data.preferences.allow_bedroom_sharing || 0,
        family: data.preferences.family || 0,
        team_integration: data.preferences.team_integration || 0,
        limit_in_the_year: data.preferences.limit_in_the_year || 0,
        months_to_refresh_limit: data.preferences.months_to_refresh_limit || 0,
        skip_emails_when_admin_modifies: data.preferences.skip_emails_when_admin_modifies || 0
    });
    const [localLoading, setLoading] = useState(false);
    useEffect(() => {
        if (!dataLoading.locations) { // Ensure data is fully loaded
            setSettings({
                individual: data.preferences.individual || 0,
                invite_individuals: data.preferences.invite_individuals || 0,
                allow_accompanying: data.preferences.allow_accompanying || 0,
                allow_bedroom_sharing: data.preferences.allow_bedroom_sharing || 0,
                family: data.preferences.family || 0,
                team_integration: data.preferences.team_integration || 0,
                limit_in_the_year: data.preferences.limit_in_the_year || 0,
                months_to_refresh_limit: data.preferences.months_to_refresh_limit || 0,
                skip_emails_when_admin_modifies: data.preferences.skip_emails_when_admin_modifies || 0
            });
        }
    }, [data.preferences, dataLoading.locations]);

    useEffect(() => {
        if (!settings.individual) {
            setSettings(prev => ({
                ...prev,
                invite_individuals: 0,
                allow_accompanying: 0,
                limit_in_the_year: 0,
                months_to_refresh_limit: 0
            }));
        }

        if (!settings.individual && !settings.team_integration) {
            setSettings(prev => ({
                ...prev,
                allow_bedroom_sharing: 0
            }));
        }
    }, [settings.individual, settings.team_integration]);
    if (dataLoading.locations) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                {/* <CircularProgress /> */}
            </Box>
        );
    }

    const handleChange = (event) => {
        const { name, value, checked, type } = event.target;
        if (type === 'number') {
            const numValue = parseInt(value, 10);
            // Check if the parsed number is within the desired range
            if (numValue < 0 || numValue > 12) return; // Optionally show a validation message or ignore the input
        }
        if (type === 'checkbox') {
            // Convert boolean checked value to 0 or 1
            setSettings({
                ...settings,
                [name]: checked ? 1 : 0
            });
        } else {
            setSettings({
                ...settings,
                [name]: value
            });
        }
    };

    const validateAndSave = async () => {
        setLoading(true);
        const params = { ...settings };
        if (user.role === 'admin') {
            params.company_id = data.selectedCompanyId;
        }

        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PREFIX}/company-configuration/update`, params);
            toast.success('Settings updated successfully!');
            updatePreferences(response.data.preferences); // Assuming the new preferences are in the response
        } catch (error) {
            toast.error(error.response?.data?.error || 'Failed to update settings.');
        } finally {
            setLoading(false);
        }
    };


    const handleBack = () => {
        navigate(-1);
    };

    return (
        <Container maxWidth="lg" sx={{ mt: '20px' }} key={dataLoading.locations ? 'loading' : 'loaded'}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <IconButton onClick={handleBack} aria-label="back" >
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h3">Company Settings</Typography>
            </Box>
            <Stack spacing={3} sx={{ m: 2 }}>
                <Box>
                    <Typography variant="h4"><Highlight type="individual">Individual</Highlight>{" settings"}</Typography>
                    <FormControlLabel
                        control={<Checkbox checked={settings.individual} onChange={handleChange} name="individual" />}
                        label="Individual workation"
                    />
                    <Stack direction="row" mb={1}>

                        <FormControlLabel
                            control={<Checkbox checked={settings.invite_individuals} onChange={handleChange} name="invite_individuals" disabled={!settings.individual} />}
                            label="Invite Individuals"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={settings.allow_accompanying} onChange={handleChange} name="allow_accompanying" disabled={!settings.individual} />}
                            label="Allow Accompanying"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={settings.allow_bedroom_sharing} onChange={handleChange} name="allow_bedroom_sharing" disabled={!settings.individual && !settings.team_integration} />}
                            label="Allow Bedroom Sharing"
                        />
                    </Stack>
                    <Typography mb={2}>Limits for individuals if invites not enabled.</Typography>

                    <Stack direction="row" spacing={1}>
                        <TextField
                            label="Limit in the Year"
                            type="number"
                            value={settings.limit_in_the_year}
                            onChange={handleChange}
                            name="limit_in_the_year"
                            disabled={!settings.individual || settings.invite_individuals}
                            inputProps={{ min: "0", max: "12", step: "1" }}  // Restricts input from 0 to 12
                            sx={{ width: 150 }}
                        />
                        <TextField
                            label="Months to Refresh Limit"
                            type="number"
                            value={settings.months_to_refresh_limit}
                            onChange={handleChange}
                            name="months_to_refresh_limit"
                            disabled={!settings.individual || settings.invite_individuals}
                            inputProps={{ min: "0", max: "12", step: "1" }}  // Restricts input from 0 to 12
                            sx={{ width: 150 }}

                        />
                    </Stack>
                </Box>
                <Box>
                    <Typography variant="h4"><Highlight type="family">Family</Highlight>{" workation"}</Typography>
                    <FormControlLabel
                        control={<Checkbox checked={settings.family} onChange={handleChange} name="family" />}
                        label="Family workation"
                    />
                </Box>
                <Box>
                    <Typography variant="h4"><Highlight type="team_integration">Team integration</Highlight>{" workation"}</Typography>
                    <FormControlLabel
                        control={<Checkbox checked={settings.team_integration} onChange={handleChange} name="team_integration" />}
                        label="Team integration workation"
                    />
                </Box>
                <Box>
                    <Typography variant="h4">Notifications</Typography>
                    <FormControlLabel
                        control={<Checkbox checked={settings.skip_emails_when_admin_modifies} onChange={handleChange} name="skip_emails_when_admin_modifies" />}
                        label="Skip notifications when admin modifies"
                    />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Button onClick={validateAndSave} variant="contained" color="primary" disabled={localLoading} sx={{ width: { xs: '100%', sm: 250 } }}>
                        {localLoading ? <CircularProgress size={24} /> : 'Save'}
                    </Button>
                </Box>
            </Stack>
        </Container>

    );
};

export default CompanySettings;
