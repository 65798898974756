import React, { useState, useContext } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Checkbox, FormControlLabel, CircularProgress, Stack, Typography, Tooltip } from '@mui/material';
import { AdminDataContext } from './../AdminDataContext';
import axios from '../../axios';
import { toast } from 'react-toastify';
import Highlight from '../../utils/Highlight';

import { formatDate } from '../../utils/dateAndTime';

const SlotSettingsDialog = ({ open, onClose, slot, locationInfo, onSlotUpdate, resetCountdown }) => {
    const { data } = useContext(AdminDataContext);
    const preferences = data.preferences;
    const [slotSettings, setSlotSettings] = useState({
        individual: slot.individual,
        individual_with_accomp: slot.individual_with_accomp,
        allow_sharing: slot.allow_sharing,
        family: slot.family,
        team_integration: slot.team_integration
    });
    const [loading, setLocalLoading] = useState(false);

    const handleChange = (event) => {
        event.stopPropagation();
        setSlotSettings({
            ...slotSettings,
            [event.target.name]: event.target.checked
        });
    };

    const handleSave = async (event) => {
        event.stopPropagation(); // Prevent event from bubbling up to the accordion
        setLocalLoading(true);
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PREFIX}/slot/update/${slot.slot_id}`, slotSettings);
            toast.success('Slot updated successfully!');
            onClose(event);
            onSlotUpdate();  // Call debounced function to handle any updates
            resetCountdown();
        } catch (error) {
            toast.error((error.response?.data?.error || 'Please try again later.'));
        } finally {
            setLocalLoading(false);
        }
    };

    const handleDialogClose = (event) => {
        event.stopPropagation(); // Prevent event from bubbling up to the accordion
        onClose(event);
    };

    const getTooltipTitle = (name) => {
        const typeMap = {
            individual: "Individual",
            family: "Family",
            team_integration: "Team integration"
        };
        const type = typeMap[name];
        return `${type} workation cannot be updated because it was disabled in the company settings.`;
    };

    return (
        <Dialog open={open} onClose={handleDialogClose}>
            <DialogTitle>Slot Settings</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <Typography><b>{`${formatDate(slot.start_date)} — ${formatDate(slot.end_date)}`}</b></Typography>
                    <Typography>Update the information about the workation types for the slot.</Typography>

                    <Stack spacing={1}>
                        <Tooltip
                            title={getTooltipTitle('individual')}
                            disableHoverListener={locationInfo.individual && preferences.individual} // Enable tooltip only when needed
                        >
                            <span>
                                <FormControlLabel
                                    control={<Checkbox checked={slotSettings.individual} onChange={handleChange} name="individual" />}
                                    label={
                                        <>
                                            <Highlight type="individual">Individual</Highlight>{" workation"}
                                        </>
                                    }
                                    disabled={!locationInfo.individual || !preferences.individual}
                                />
                            </span>
                        </Tooltip>
                        <Stack direction="row" spacing={1}>
                            <Tooltip
                                title="Allow accompanying cannot be enabled when individual workation is not checked. Also, accompanying person needs to be enabled in the company settings."
                                disableHoverListener={!(!slotSettings.individual || !preferences.allow_accompanying)} // Disable tooltip when not needed
                            >
                                <span> {/* Tooltip requires a child element that can accept a ref */}
                                    <FormControlLabel
                                        control={<Checkbox checked={slotSettings.individual_with_accomp} onChange={handleChange} name="individual_with_accomp" />}
                                        label="Accompanying person"
                                        disabled={!slotSettings.individual || !preferences.allow_accompanying}
                                    />
                                </span>
                            </Tooltip>
                            <Tooltip
                                title="Bedroom sharing option needs to be enabled first in the company settings."
                                disableHoverListener={!(!preferences.allow_bedroom_sharing)} // Disable tooltip when not needed
                            >
                                <span> {/* Span wrapper is required because Tooltip children need to forward refs */}
                                    <FormControlLabel
                                        control={<Checkbox checked={slotSettings.allow_sharing} onChange={handleChange} name="allow_sharing" />}
                                        label="Allow bedroom sharing"
                                        disabled={!preferences.allow_bedroom_sharing}
                                    />
                                </span>
                            </Tooltip>
                        </Stack>
                        <Tooltip
                            title={getTooltipTitle('family')}
                            disableHoverListener={locationInfo.family && preferences.family} // Enable tooltip only when needed
                        >
                            <span>
                                <FormControlLabel
                                    control={<Checkbox checked={slotSettings.family} onChange={handleChange} name="family" />}
                                    label={
                                        <>
                                            <Highlight type="family">Family</Highlight>{" workation"}
                                        </>
                                    }
                                    disabled={!locationInfo.family || !preferences.family}
                                />
                            </span>
                        </Tooltip>
                        <Tooltip
                            title={getTooltipTitle('team_integration')}
                            disableHoverListener={locationInfo.team_integration && preferences.team_integration} // Enable tooltip only when needed
                        >
                            <span>
                                <FormControlLabel
                                    control={<Checkbox checked={slotSettings.team_integration} onChange={handleChange} name="team_integration" />}
                                    label={
                                        <>
                                            <Highlight type="team_integration">Team integration</Highlight>{" workation"}
                                        </>
                                    }
                                    disabled={!locationInfo.team_integration || !preferences.team_integration}
                                />
                            </span>
                        </Tooltip>
                    </Stack>
                </Stack>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose} variant='outlined'>Cancel</Button>
                <Button
                    onClick={handleSave}
                    color="primary"
                    disabled={loading}
                    variant='contained'
                >
                    {loading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SlotSettingsDialog;
