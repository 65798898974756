import React from 'react';
import { Grid, Card, CardMedia, Skeleton } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const LocationSmallPictures = ({ locationDetails, handleOpenGallery }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Function to generate skeletons
    const renderSkeletons = () => {
        return [...Array(3)].map((_, index) => (
            <Grid item key={index} sx={{ flex: 1, display: 'flex' }}>
                <Skeleton variant="rectangular" width="100%" height="100%" sx={{ borderRadius: '20px' }} /> {/* Adjust height as needed */}
            </Grid>
        ));
    };

    return (
        <>
            {locationDetails ? (
                locationDetails.pictures.slice(1, 4).map((picture, index) => (
                    <Grid item key={index} style={{ display: 'flex', justifyContent: 'center', height: '30.69%' }}>
                        <Card sx={{ width: '100%', height: '100%' }}>
                            <CardMedia
                                component="img"
                                image={`${process.env.REACT_APP_PROPERTIES_PICTURES_ROOT_URL}/${picture.file_path}`}
                                alt={`Image ${index + 1}`}
                                onClick={() => handleOpenGallery()}
                                sx={{
                                    cursor: 'pointer',
                                    height: '100%',
                                    width: '100%',
                                    objectFit: 'cover', // Set objectFit to cover
                                }}
                            />
                        </Card>
                    </Grid>
                ))
            ) : (
                renderSkeletons()
            )}
        </>
    );
};

export default LocationSmallPictures;
