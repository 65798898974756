import { useTheme } from '@mui/material/styles';

const InternetIcon = ({ width = '32', height = '28', color }) => {
    const theme = useTheme();
    const iconColor = color || theme.palette.primary.notUsed; // Default to theme color if not provided

    return (
        <svg width={width} height={height} viewBox="0 0 32 28" fill={iconColor} xmlns="http://www.w3.org/2000/svg">
            <path d="M1.92321 7.5853C1.47817 8.05106 0.778822 8.05106 0.333781 7.5853C-0.11126 7.11953 -0.11126 6.38762 0.333781 5.92185C4.14842 1.99613 9.10745 0 14.0665 0C19.0255 0 23.9845 1.99613 27.7992 5.92185C28.2442 6.38762 28.2442 7.11953 27.7992 7.5853C27.3541 8.05106 26.6548 8.05106 26.2098 7.5853C22.8402 4.0588 18.4533 2.32882 14.0665 2.32882C9.67965 2.32882 5.22923 4.0588 1.92321 7.5853Z" fill={iconColor} />
            <path d="M1.92321 7.5853C1.47817 8.05106 0.778822 8.05106 0.333781 7.5853C-0.11126 7.11953 -0.11126 6.38762 0.333781 5.92185C4.14842 1.99613 9.10745 0 14.0665 0C19.0255 0 23.9845 1.99613 27.7992 5.92185C28.2442 6.38762 28.2442 7.11953 27.7992 7.5853C27.3541 8.05106 26.6548 8.05106 26.2098 7.5853C22.8402 4.0588 18.4533 2.32882 14.0665 2.32882C9.67965 2.32882 5.22923 4.0588 1.92321 7.5853Z" fill={iconColor} />
            <path d="M5.80131 11.644C5.35627 12.1098 4.65692 12.1098 4.21188 11.644C3.83042 11.1783 3.83042 10.4463 4.21188 10.0471C6.9457 7.186 10.506 5.72217 14.0664 5.72217C17.6267 5.72217 21.187 7.186 23.9208 10.0471C24.3023 10.4463 24.3023 11.1783 23.9208 11.644C23.4758 12.1098 22.7765 12.1098 22.3314 11.644C20.0426 9.24866 17.0545 8.05099 14.0664 8.05099C11.0782 8.05099 8.0901 9.24866 5.80131 11.644Z" fill={iconColor} />
            <path d="M5.80131 11.644C5.35627 12.1098 4.65692 12.1098 4.21188 11.644C3.83042 11.1783 3.83042 10.4463 4.21188 10.0471C6.9457 7.186 10.506 5.72217 14.0664 5.72217C17.6267 5.72217 21.187 7.186 23.9208 10.0471C24.3023 10.4463 24.3023 11.1783 23.9208 11.644C23.4758 12.1098 22.7765 12.1098 22.3314 11.644C20.0426 9.24866 17.0545 8.05099 14.0664 8.05099C11.0782 8.05099 8.0901 9.24866 5.80131 11.644Z" fill={iconColor} />
            <path d="M9.67973 15.7029C9.23469 16.1686 8.53534 16.1686 8.0903 15.7029C7.70883 15.2371 7.70883 14.5052 8.0903 14.106C9.74331 12.376 11.9049 11.511 14.0666 11.511C16.2282 11.511 18.3898 12.376 19.9793 14.106C20.4243 14.5052 20.4243 15.2371 19.9793 15.7029C19.5978 16.1686 18.8984 16.1686 18.4534 15.7029C17.2454 14.4386 15.656 13.8398 14.0666 13.8398C12.4771 13.8398 10.8877 14.4386 9.67973 15.7029Z" fill={iconColor} />
            <path d="M9.67973 15.7029C9.23469 16.1686 8.53534 16.1686 8.0903 15.7029C7.70883 15.2371 7.70883 14.5052 8.0903 14.106C9.74331 12.376 11.9049 11.511 14.0666 11.511C16.2282 11.511 18.3898 12.376 19.9793 14.106C20.4243 14.5052 20.4243 15.2371 19.9793 15.7029C19.5978 16.1686 18.8984 16.1686 18.4534 15.7029C17.2454 14.4386 15.656 13.8398 14.0666 13.8398C12.4771 13.8398 10.8877 14.4386 9.67973 15.7029Z" fill={iconColor} />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.8466 18.0983C16.8003 19.0963 16.8003 20.7598 15.8466 21.7579C14.8294 22.7559 13.3035 22.7559 12.2863 21.7579C11.3326 20.7598 11.3326 19.0963 12.2863 18.0983C13.3035 17.1002 14.8294 17.1002 15.8466 18.0983Z" fill={iconColor} />
        </svg>
    );
};
export default InternetIcon;