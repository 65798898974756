import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Radio, RadioGroup, FormControlLabel, FormControl, Typography, Slider, FormLabel, CircularProgress } from '@mui/material';
import axios from '../../axios';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { formatDate } from '../../utils/dateAndTime';


const SplitSlotDialog = ({ open, onClose, slot, onSlotUpdate, resetCountdown }) => {
    const initialDate = dayjs(slot.start_date).add(1, 'day').valueOf();
    const [splitDate, setSplitDate] = useState(initialDate);
    const [assignHalf, setAssignHalf] = useState('first_half');
    const [loading, setLoading] = useState(false);

    const stopPropagation = (e) => {
        e.stopPropagation();
    };
    const handleSplitDateChange = (e, newValue) => {
        stopPropagation(e);
        if (newValue === dayjs(slot.start_date).valueOf() || newValue === dayjs(slot.end_date).valueOf()) {
            return;
        }
        setSplitDate(newValue);
    };

    const handleAssignHalfChange = (e) => {
        stopPropagation(e);
        setAssignHalf(e.target.value);
    };

    const handleSaveSplit = async (e) => {
        stopPropagation(e);
        setLoading(true);
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PREFIX}/slot/split/${slot.slot_id}`, {
                split_date: dayjs(splitDate).format('YYYY-MM-DD'),
                assign_half_to_existing_users: assignHalf
            });
            toast.success('Slot split successfully!');
            onClose();
            onSlotUpdate();
            resetCountdown();
        } catch (error) {
            toast.error((error.response?.data?.error || 'Please try again later.'));
        } finally {
            setLoading(false);
        }
    };

    const totalDays = dayjs(slot.end_date).diff(dayjs(slot.start_date), 'day') + 1;
    const marks = Array.from({ length: totalDays }, (_, index) => ({
        value: dayjs(slot.start_date).add(index, 'day').valueOf(),
        label: index === 0 || index === totalDays - 1 ? dayjs(slot.start_date).add(index, 'day').format('ddd') : '' // Only show labels on first and last marks
    }));

    return (
        <Dialog open={open} onClose={(e) => { e.stopPropagation(); onClose(); }} fullWidth maxWidth="sm">
            <DialogTitle>Split slot</DialogTitle>
            <DialogContent>
                <Typography gutterBottom><b>{`${formatDate(slot.start_date)} — ${formatDate(slot.end_date)}`}</b></Typography>
                <Typography gutterBottom>Selected split date: <b>{formatDate(splitDate, true)}</b></Typography>
                <Slider
                    value={splitDate}
                    onChange={handleSplitDateChange}
                    valueLabelDisplay="off"
                    min={dayjs(slot.start_date).valueOf()}
                    max={dayjs(slot.end_date).valueOf()}
                    marks={marks}
                    step={null}  // Makes the slider "jump" to marks only
                />
                <FormControl component="fieldset" style={{ marginTop: '20px' }}>
                    <FormLabel component="legend" style={{ color: 'rgba(0, 0, 0, 0.87)' }}>If there are employees on the slot, they will be assigned to the</FormLabel>
                    <RadioGroup row name="assignHalf" value={assignHalf} onChange={handleAssignHalfChange}>
                        <FormControlLabel value="first_half" control={<Radio />} label="First part" />
                        <FormControlLabel value="second_half" control={<Radio />} label="Second part" />
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={(e) => { e.stopPropagation(); onClose(); }} variant="outlined" color="primary">Cancel</Button>
                <Button onClick={handleSaveSplit} variant="contained" color="primary" disabled={loading}>
                    {loading ? <CircularProgress size={24} /> : 'Split'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SplitSlotDialog;
