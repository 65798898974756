import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import axios from '../axios';
import { debounce } from '../utils/debounce';
import { useParams, useNavigate } from 'react-router-dom';
import { AdminDataContext } from './AdminDataContext';
import { useAuth } from '../contexts/AuthContext';
import WorkationGoerCard from './Workation/WorkationGoerCard';
import SlotSettingsMenu from './SlotSettings/SlotSettingsMenu';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Button, useMediaQuery, Box, Chip, Container, CircularProgress, IconButton, Card, Grid } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { useLoading } from './LoadingContext'; // Import useLoading
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import AirplanemodeInactiveIcon from '@mui/icons-material/AirplanemodeInactive';

import { formatDate, formatTime, calculateDaysDifference } from '../utils/dateAndTime';

const ConfigureLocation = () => {
    const navigate = useNavigate();
    const [loading, setLocalLoading] = useState(false);

    const { locationId } = useParams();
    const { data } = useContext(AdminDataContext);
    const { user } = useAuth();
    const [slots, setSlots] = useState([]);
    const [locationInfo, setLocationInfo] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [location, setLocation] = useState([]);
    const { setLoading } = useLoading();
    const [error, setError] = useState('');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [countdown, setCountdown] = useState(null);
    const [rangeStartDate, setRangeStartDate] = useState(dayjs().subtract(30, 'day'));
    const [rangeEndDate, setRangeEndDate] = useState(dayjs().add(30, 'day'));

    const rangeStartDateRef = useRef(rangeStartDate);
    const rangeEndDateRef = useRef(rangeEndDate);

    useEffect(() => {
        rangeStartDateRef.current = rangeStartDate;
    }, [rangeStartDate]);

    useEffect(() => {
        rangeEndDateRef.current = rangeEndDate;
    }, [rangeEndDate]);

    const [expandedPanels, setExpandedPanels] = useState(new Set());

    const [dialogOpen, setDialogOpen] = useState(false);

    const handleAccordionChange = (panelId) => (event, isExpanded) => {
        if (!dialogOpen) {
            setExpandedPanels(currentPanels => {
                const newPanels = new Set(currentPanels); // Create a new set from the current state
                if (isExpanded) {
                    newPanels.add(panelId); // Add the panel id to the set if it's expanded
                } else {
                    newPanels.delete(panelId); // Remove the panel id from the set if it's not expanded
                }
                return newPanels;
            });
        }
    };

    const groupSlotsByMonth = (slots) => {
        return slots.reduce((groups, slot) => {
            const monthYear = dayjs(slot.start_date).format('MMMM YYYY');
            if (!groups[monthYear]) {
                groups[monthYear] = [];
            }
            groups[monthYear].push(slot);
            return groups;
        }, {});
    };

    const handleDialogToggle = () => {
        setDialogOpen(!dialogOpen);
    };

    const handleBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        if ((user.role === 'admin' && data.selectedCompanyId) || user.role !== 'admin') {
            fetchSlots(rangeStartDate, rangeEndDate, true);
        }
    }, [locationId, data.selectedCompanyId, user.role]);


    useEffect(() => {
        if (countdown !== null) {
            const intervalId = setInterval(() => {
                setCountdown(countdown => countdown > 0 ? countdown - 1 : 0);
            }, 1000);
            return () => clearInterval(intervalId);
        }
    }, [countdown]);

    const resetCountdown = () => {
        setCountdown(5); // Reset to 5 seconds
    };

    const fetchSlotsDebounced = useCallback(debounce(() => {
        fetchSlots(rangeStartDateRef.current, rangeEndDateRef.current, true);
    }, 5000), []);

    const handleSlotUpdate = () => {
        fetchSlotsDebounced();
    };

    const fetchSlots = async (startDate, endDate, replace = true) => {
        if (!startDate || !endDate) {
            console.error('Start date or end date is undefined');
            return;
        }
        try {
            setLoading(true);
            setLocalLoading(true);

            const params = {
                slots_start_from: startDate.toISOString().split('T')[0],
                slots_end_to: endDate.toISOString().split('T')[0],
                per_page: 60 // Fetch 60 results per request
            };

            if (user.role === 'admin') {
                params.company_id = data.selectedCompanyId;
            }

            const response = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PREFIX}/slots-with-details-for-admins/${locationId}`, {
                params: params
            });

            const newSlots = response.data.slots.data;

            if (replace) {
                setSlots(newSlots);
            } else {
                setSlots(prevSlots => [...prevSlots, ...newSlots]);
            }

            setLocationInfo(response.data.location);
            setHasMore(response.data.slots.current_page < response.data.slots.last_page);
            setCurrentPage(response.data.slots.current_page);
            setLocation(response.data.location);
            setLocalLoading(false);
            setLoading(false);

        } catch (err) {
            setError(err.message);
            setLoading(false);
            setLocalLoading(false);
        }
    };


    const handleLoadPrevious = () => {
        const newEndDate = rangeStartDate;
        const newStartDate = rangeStartDate.subtract(60, 'day');
        setRangeStartDate(newStartDate);
        setRangeEndDate(newEndDate);
        fetchSlots(newStartDate, newEndDate, true);
    };

    const handleLoadNext = () => {
        const newStartDate = rangeEndDate;
        const newEndDate = rangeEndDate.add(60, 'day');
        setRangeStartDate(newStartDate);
        setRangeEndDate(newEndDate);
        fetchSlots(newStartDate, newEndDate, true);
    };

    const handleSearch = () => {
        fetchSlots(1, true); // Trigger search when the button is clicked and replace existing slots
    };

    const today = dayjs();

    const styleByStatus = (slot) => {
        if (slot.entire_property_available === 0 && slot.ind_availability === 0 && slot.ind_w_partner_availability === 0) {
            // Red, fully outlined
            return {
                width: '18px',
                height: '18px',
                borderRadius: '20px',
                border: `2px solid ${theme.palette.status.rejectedText}`,
                backgroundColor: theme.palette.status.rejected
            };

        } else if (slot.entire_property_available === 1) {
            return {
                width: '18px',
                height: '18px',
                borderRadius: '20px',
                border: `2px solid ${theme.palette.status.approvedText}`,
                backgroundColor: theme.palette.status.approved
            };
        } else {
            return {
                width: '18px',
                height: '18px',
                borderRadius: '20px',
                border: `2px solid ${theme.palette.status.pendingText}`,
                backgroundColor: theme.palette.status.pending
            };
        }
    };

    return (
        <Container maxWidth="lg" sx={{ mt: "20px" }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <IconButton onClick={handleBack} aria-label="back">
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h3" m={0}>{location.location_name}</Typography>

            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Button
                    onClick={handleLoadPrevious}
                    variant='contained'
                    disabled={loading}
                    startIcon={<ArrowBackIcon sx={{ mr: 0 }} />}
                    sx={{ px: 2, fontSize: '13px' }}
                >
                    {!isMobile && 'Previous'}
                </Button>

                <Typography alignContent="center" sx={{ fontSize: 16 }} >
                    {loading ? <CircularProgress size={20} /> : `${formatDate(rangeStartDate, false, true)} — ${formatDate(rangeEndDate, false, true)}`}
                </Typography>
                <Button
                    onClick={handleLoadNext}
                    variant='contained'
                    disabled={loading}
                    endIcon={<ArrowForwardIcon />}
                    sx={{ px: 2, fontSize: '13px' }}

                >
                    {!isMobile && 'Next'}
                </Button>
            </Box>

            <Box sx={{ padding: 2, alignItems: "center", px: { xs: 0, sm: 0, md: 2, lg: 2 } }}>
                {slots.length === 0 ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <CalendarMonthIcon sx={{ mr: 1, color: 'lightgrey' }} />
                        <Typography sx={{ color: 'lightgrey' }}>No slots for the date range</Typography>
                    </Box>
                ) : (
                    Object.entries(groupSlotsByMonth(slots)).map(([monthYear, slotsInMonth]) => (
                        <React.Fragment key={monthYear}>
                            <Typography variant="h6" sx={{ mt: 2 }}>{monthYear}</Typography>
                            {slotsInMonth.map(slot => (
                                <Accordion
                                    key={slot.slot_id}
                                    expanded={expandedPanels.has(slot.slot_id)}
                                    onChange={handleAccordionChange(slot.slot_id)}
                                    sx={{ width: '100%', mt: 0, mb: 0, boxShadow: 'none' }}
                                >
                                    <AccordionSummary sx={{ backgroundColor: theme.palette.primary.card }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <CalendarMonthIcon sx={{
                                                    color: dayjs().isBetween(dayjs(slot.start_date), dayjs(slot.end_date), null, '[]')
                                                        ? theme.palette.primary.main
                                                        : slot.is_active === 1
                                                            ? theme.palette.primary.used
                                                            : theme.palette.status.rejected
                                                }} />
                                                <Box sx={styleByStatus(slot)} />
                                                <Typography
                                                    sx={{
                                                        color: dayjs(slot.end_date).isBefore(today) ? 'lightgrey' : 'inherit',
                                                    }}
                                                >{`${formatDate(slot.start_date, isMobile ? false : true, true)}`} — {`${formatDate(slot.end_date, isMobile ? false : true, true)}`}</Typography>
                                                {!isMobile && (
                                                    <Typography
                                                        sx={{
                                                            color: dayjs(slot.end_date).isBefore(today) ? 'lightgrey' : 'inherit',
                                                            fontSize: '11px'
                                                        }}
                                                    >({`${calculateDaysDifference(slot.start_date, slot.end_date)}`} days)</Typography>
                                                )}
                                                {!isMobile && !expandedPanels.has(slot.slot_id) && (
                                                    slot.workationGoers.map((workationGoer, index) => {
                                                        const travelIcon = workationGoer.user.travel?.travel_how
                                                            ? <AirplanemodeActiveIcon style={{ color: 'gray' }} />
                                                            : <AirplanemodeInactiveIcon style={{ color: 'gray' }} />;
                                                        const chipBackgroundColor = workationGoer.user.workation.status === 'pending'
                                                            ? theme.palette.status.pending
                                                            : theme.palette.status.approved;
                                                        const chipTextColor = workationGoer.user.workation.status === 'pending'
                                                            ? theme.palette.status.pendingText
                                                            : theme.palette.status.approvedText;

                                                        return (
                                                            <Chip
                                                                key={index}
                                                                size='small'
                                                                label={workationGoer.user.first_name + ' ' + workationGoer.user.last_name}
                                                                icon={travelIcon}
                                                                sx={{ backgroundColor: chipBackgroundColor, color: 'black' }}
                                                            />
                                                        );
                                                    })
                                                )}
                                            </Box>

                                            <SlotSettingsMenu
                                                key={slot.slot_id}
                                                slot={slot}
                                                locationInfo={locationInfo}
                                                onSlotUpdate={handleSlotUpdate}
                                                countdown={countdown}
                                                resetCountdown={resetCountdown}
                                                handleDialogToggle={handleDialogToggle}
                                            />
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ backgroundColor: theme.palette.primary.card }}>
                                        {slot.entire_property_available !== 1 ? (
                                            <Grid container spacing={2} >
                                                {slot.workationGoers.map((workationGoer, index) => (
                                                    <Grid item xs={12} sm={6} md={4} lg={4} mb={3} key={index}>
                                                        <Card sx={{ my: 1, position: 'relative', overflow: 'visible' }}>
                                                            <WorkationGoerCard
                                                                workationGoer={workationGoer}
                                                                formatDate={formatDate}
                                                                formatTime={formatTime}
                                                                handleRefresh={handleSearch}
                                                                onSlotUpdate={handleSlotUpdate}
                                                                countdown={countdown}
                                                                resetCountdown={resetCountdown}
                                                            />
                                                        </Card>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        ) : (
                                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                <Typography sx={{
                                                    color: 'lightgrey'
                                                }}>No one signed up yet</Typography>
                                            </Box>
                                        )}
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </React.Fragment>
                    ))
                )}
            </Box>
        </Container>
    );
};

export default ConfigureLocation;