import React from 'react';
import { useTheme } from '@mui/material/styles';

const Highlight = ({ children, type }) => {
    const theme = useTheme();

    // Define a fallback if the status type is not found
    const defaultStyle = {
        backgroundColor: 'transparent',
        color: 'inherit',
    };

    // Fetch the color configuration for the given type
    const backgroundColor = theme.palette.status?.[type] || theme.palette[type]?.main || defaultStyle.backgroundColor;
    const textColor = theme.palette.status?.[`${type}Text`] || theme.palette[type]?.contrastText || defaultStyle.color;

    const textStyle = {
        backgroundColor: backgroundColor,
        borderRadius: '5px',
        padding: '0px 5px',
        color: textColor,
        display: 'inline-block',
    };

    return <span style={textStyle}>{children}</span>;
};

export default Highlight;
