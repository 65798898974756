import { Box, Grid, TextField, InputAdornment, FormControlLabel, Checkbox, FormHelperText } from '@mui/material';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';

const Departure = ({ watchedTravelMode, register, errors, displayDepartureCheckbox, getDepartureHeaderText }) => {
    return (
        <section>
            <Box paddingX={2} paddingBottom={2} border={1} borderColor="rgb(54, 54, 54)" borderRadius={5} marginBottom={3}>
                <h2>Departure from Workation</h2>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={12}>{getDepartureHeaderText(watchedTravelMode)}</Grid>
                    {(watchedTravelMode === 'Plane' || watchedTravelMode === 'Car') && (
                        <>
                            <Grid item xs={12} md={watchedTravelMode === 'Car' ? 6 : watchedTravelMode === 'Plane' ? 4 : 4}>
                                <TextField
                                    label="Departure Date"
                                    type="date"
                                    name="departureDate"
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    fullWidth
                                    {...register("departureDate", { required: "Departure date is required" })}
                                    error={Boolean(errors.departureDate)}
                                    helperText={errors.departureDate?.message}
                                />
                            </Grid>
                            <Grid item xs={12} md={watchedTravelMode === 'Car' ? 6 : watchedTravelMode === 'Plane' ? 4 : 4}>
                                <TextField
                                    label="Departure Time"
                                    type="time"
                                    name="departureTime"
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    fullWidth
                                    {...register("departureTime", { required: "Departure time is required" })}
                                    error={Boolean(errors.departureTime)}
                                    helperText={errors.departureTime?.message}
                                />
                            </Grid>
                        </>
                    )}
                    {(watchedTravelMode === 'Plane') && (
                        <>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Flight Number"
                                    type="text"
                                    name="flightNumberDeparture"
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    fullWidth
                                    {...register("flightNumberDeparture", { required: "Flight number is required" })}
                                    error={Boolean(errors.flightNumberDeparture)}
                                    helperText={errors.flightNumberDeparture?.message}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <FlightTakeoffIcon />
                                            </InputAdornment>
                                        ),
                                        inputProps: {
                                            maxLength: 8
                                        }
                                    }}
                                />
                            </Grid>
                            {displayDepartureCheckbox && (
                                <Grid item xs={12} md={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                {...register("lateDepartureCheckbox", { required: "Please acknowledge your late departure." })}
                                            />
                                        }
                                        label={
                                            <span style={{ color: "grey", fontSize: "12px" }}>
                                                My flight departs after my workation slot ends, and I'll arrange my own accommodation until then.
                                            </span>
                                        }
                                    />
                                    {errors.lateDepartureCheckbox && <FormHelperText error>{errors.lateDepartureCheckbox.message}</FormHelperText>}
                                </Grid>
                            )}
                        </>
                    )}
                </Grid>
            </Box>
        </section>
    );
}

export default Departure;
