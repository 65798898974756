import React, { useState, useContext, useEffect } from 'react';
import { DataContext } from './DataContext'; // Adjust the path as needed
import { Select, MenuItem, FormControl, InputLabel, Checkbox, FormControlLabel, Box, Card, CardContent, Typography, Button } from '@mui/material';
import axios from '../axios';
import { useLoading } from './LoadingContext'; // Import useLoading
import WorkationInfo from './HomePageComponents/WorkationInfo'; // Import useLoading

import { toast } from 'react-toastify';

const DemandRequest = () => {
    const [locationId, setLocationId] = useState('');
    const { data, fetchData } = useContext(DataContext);
    const { preferences, locations } = data;
    const [occupancyType, setOccupancyType] = useState('');
    const [isTeamManager, setIsTeamManager] = useState(false);
    const { setLoading } = useLoading(); // Get setLoading from context

    useEffect(() => {
        if (!data.locations.length || !Object.keys(data.preferences).length) {
            fetchData();
        }
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PREFIX}/special-invites/add-demand-request`, {
                location_id: locationId,
                occupancy_type: occupancyType,
            });
            toast.success('The request for a voucher has been successfully submitted and will be reviewed');
        } catch (error) {
            toast.error('The request could not be submitted - it is very likely you submitted a request already');
            console.error('Error sending demand request:');
        }

        setLoading(false);
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="80vh">
            <Card sx={{ minWidth: 275, maxWidth: 360 }}>
                <CardContent >
                    <Typography variant="h2" component="div" gutterBottom sx={{ textAlign: 'center' }}>
                        Request
                    </Typography>
                    <Typography variant="h1" component="div" gutterBottom sx={{ textAlign: 'center' }}>
                        Voucher
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary" >
                        The location field is just for us to understand where you would prefer to go, but it will not mean you can go only there.
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        {/* Location Dropdown */}
                        <Box mt={2} display="flex" flexDirection="column" gap={3}>
                            <FormControl fullWidth>
                                <InputLabel id="preferred-location-label">Preferred location</InputLabel>
                                <Select
                                    required
                                    labelId="preferred-location-label"
                                    id="preferred-location-select"
                                    value={locationId}
                                    label="preferred location"
                                    onChange={(e) => setLocationId(e.target.value)}
                                >
                                    {locations.map((location) => (
                                        <MenuItem key={location.location_id} value={location.location_id}>
                                            {location.location_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {/* Occupancy Type Dropdown */}
                            <FormControl fullWidth>
                                <InputLabel id="workation-type-label">Workation type</InputLabel>
                                <Select
                                    required
                                    labelId="workation-type-label"
                                    id="workation-type-select"
                                    value={occupancyType}
                                    label="Occupancy Type"
                                    onChange={(e) => setOccupancyType(e.target.value)}
                                >
                                    {preferences.invite_individuals === 1 && <MenuItem value="individual">Individual</MenuItem>}
                                    {preferences.family === 1 && <MenuItem value="family">Family</MenuItem>}
                                    {preferences.team_integration === 1 && <MenuItem value="team_integration">Team Integration</MenuItem>}
                                </Select>
                            </FormControl>
                            <WorkationInfo />
                        </Box>
                        {/* Conditional Checkbox for Team Manager */}
                        {occupancyType === 'team_integration' && (
                            <FormControlLabel
                                control={<Checkbox checked={isTeamManager} onChange={(e) => setIsTeamManager(e.target.checked)} required />}
                                label="I confirm I am a Team manager and would like to book a workation for my Team."
                            />
                        )}

                        <Box mt={2}>
                            <Button type="submit" variant="contained" color="primary" fullWidth>
                                Submit
                            </Button>
                        </Box>
                    </form>
                </CardContent>
            </Card>
        </Box>
    );
};

export default DemandRequest;
