import axios from '../axios'; // The path to the axios instance you just created
import Cookies from 'js-cookie';


////////REQUIRED?????????
const getCsrfCookie = async () => {
    try {
        await axios.get('/sanctum/csrf-cookie');
        const csrfTokenFromCookie = Cookies.get('XSRF-TOKEN');
    } catch (error) {
        console.error('Error fetching CSRF cookie:', error);
    }
};

export function getTokenFromStorage() {
    // Retrieve the authentication token (Bearer token) from localStorage
    const authToken = localStorage.getItem('token'); // 'token' should match the key used in localStorage.setItem when storing the token
    return authToken;
}

const login = async (email, password) => {
    try {
        const response = await axios.post('/api/v1/login', { email, password });
        const { token, user } = response.data;

        // Store the token in localStorage
        localStorage.setItem('token', token);

        // Return the user data as well, so that the calling component can use it
        //console.log(user);
        //console.log(`csfrToken in login: ${csrfToken}`);
        return { success: true, data: user };
    } catch (error) {
        // Log the error for debugging
        console.error('Login error:', error);

        // Return a structured error object
        return {
            success: false,
            message: error.response?.data?.message || 'An error occurred during login.'
        };
    }
};


const logout = async () => {
    await axios.post('/api/v1/logout');
    localStorage.removeItem('token'); // Assuming you're storing the token in local storage
    // Additional cleanup if necessary
};

const checkAuth = async () => {
    try {
        //await getCsrfCookie();
        const response = await axios.get('/api/v1/user'); // Correct endpoint
        //console.log("in checkAuth");
        //console.log(response.data.user);
        return response.data.user;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // User is not authenticated
            return null;
        }
        console.error('Error checking authentication', error);
        return null;
    }
};

const sendPasswordResetLink = async (email) => {
    await axios.post('/api/v1/forgot-password', { email });
}

export { login, logout, checkAuth, getCsrfCookie, sendPasswordResetLink };