import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Radio, RadioGroup, FormControlLabel, FormControl, Typography, FormLabel, CircularProgress } from '@mui/material';
import axios from '../../axios';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { formatDate } from '../../utils/dateAndTime';



const MergeSlotDialog = ({ open, onClose, slot, onSlotUpdate, resetCountdown }) => {
    const [mergeDirection, setMergeDirection] = useState('next');
    const [loading, setLoading] = useState(false);

    const handleMergeDirectionChange = (event) => {
        setMergeDirection(event.target.value);
    };

    const handleSaveMerge = async (event) => {
        event.stopPropagation();
        setLoading(true);
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PREFIX}/slots/merge/${slot.slot_id}`, {
                direction: mergeDirection
            });
            toast.success('Slot merged successfully!');
            onClose();
            onSlotUpdate();
            resetCountdown();
        } catch (error) {
            toast.error(`${error.response?.data?.error || 'Please try again later.'}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={(e) => { e.stopPropagation(); onClose(); }} fullWidth maxWidth="sm">
            <DialogTitle>Merge slot</DialogTitle>
            <DialogContent>
                <Typography gutterBottom>Choose the slot to merge with.</Typography>
                <FormControl component="fieldset" style={{ marginTop: '20px' }}>
                    <FormLabel component="legend" style={{ color: 'rgba(0, 0, 0, 0.87)' }}><b>{`${formatDate(slot.start_date)} — ${formatDate(slot.end_date)}`}</b> would be merged with</FormLabel>
                    <RadioGroup row name="mergeDirection" value={mergeDirection} onChange={handleMergeDirectionChange}>
                        <FormControlLabel value="previous" control={<Radio />} label="Previous slot" />
                        <FormControlLabel value="next" control={<Radio />} label="Next slot" />
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={(e) => { e.stopPropagation(); onClose(); }} variant="outlined" color="primary">Cancel</Button>
                <Button onClick={handleSaveMerge} variant="contained" color="primary" disabled={loading}>
                    {loading ? <CircularProgress size={24} /> : 'Merge'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MergeSlotDialog;
