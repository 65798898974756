import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../axios'; // Adjust the path as needed
import {
    Container,
    Typography,
    Card,
    Box,
    Grid,
    Skeleton,
    useTheme,
    useMediaQuery,
    CardContent,
    CardMedia,
    Button,
    IconButton,
} from '@mui/material';
//import Slider from 'react-slick'; // Or any other Carousel component
import { useLoading } from './LoadingContext'; // Import useLoading
import { useAuth } from '../contexts/AuthContext'; // Import useAuth
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import NavigationOutlinedIcon from '@mui/icons-material/NavigationOutlined';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MapComponent from './MapComponent';
import LocationPicture from './LocationDetail/LocationPicture';
import LocationSmallPictures from './LocationDetail/LocationSmallPictures';
import OtherLocationDetails from './LocationDetail/OtherLocationDetails';
import CheckSlotsButton from './LocationDetail/CheckSlotsButton';
import PicturesDialog from './LocationDetail/PicturesDialog';
import CheckSlots from './LocationDetail/CheckSlots';

const LocationDetail = ({ isDarkMode }) => {
    const navigate = useNavigate();
    const { locationId } = useParams();
    const [open, setOpen] = useState(false);
    const [locationDetails, setLocationDetails] = useState(null);
    const { setLoading } = useLoading(); // Get setLoading from context
    const [error, setError] = useState(null);
    const theme = useTheme(); // We use the theme provided by MUI to tap into the breakpoints
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
    const { user } = useAuth(); // Get user from useAuth

    const handleBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        const fetchLocationDetails = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PREFIX}/workation-location/${locationId}`);
                const { location, pictures, amenities } = response.data;
                setLocationDetails({ ...location, pictures, amenities }); // Combine location and pictures
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchLocationDetails();
    }, [locationId, setLoading]);

    const [showCheckSlotsButton, setShowCheckSlotsButton] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
        const handleScroll = () => {
            const slotsSection = document.getElementById("availableSlots");
            if (slotsSection) {
                const slotsSectionTop = slotsSection.getBoundingClientRect().top;
                setShowCheckSlotsButton(slotsSectionTop > window.innerHeight);
            }
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll(); // Initial check

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleOpenGallery = () => {
        setOpen(true);
    };

    const handleCloseGallery = () => {
        setOpen(false);
    };

    const scrollToSlots = () => {
        document.getElementById("checkSlotsButton").scrollIntoView({ behavior: "smooth" });
    };

    if (error) {
        return <Typography variant="h6" color="error">Error: {error}</Typography>;
    }

    return (
        <Container maxWidth="lg" >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1 }}>

                <IconButton onClick={handleBack} aria-label="back">
                    <ArrowBackIcon />
                </IconButton>
                {locationDetails ? (
                    <Typography variant="h3" gutterBottom sx={{ textAlign: 'center', m: 0 }}>
                        {locationDetails.location_name}
                    </Typography>
                ) : (
                    <Skeleton variant="text" width="150px" height={60} />
                )
                }
            </Box>
            <Grid container spacing={2} mb={3} sx={{ display: 'flex', alignItems: 'stretch' }}>
                <Grid item container xs={12} sm={12} md={12} lg={9} spacing={2} >
                    {/* Picture Grid */}
                    <Grid item container xs={12} sm={7} lg={7} sx={{ display: 'flex', flexDirection: 'column', ...(isTablet ? { maxHeight: 425 } : { height: 425 }) }}>
                        {locationDetails ? (
                            <LocationPicture locationDetails={locationDetails} handleOpenGallery={handleOpenGallery} />
                        ) : (
                            <Skeleton variant="rectangular" width="100%" height={425} sx={{ borderRadius: '20px' }} />
                        )
                        }
                    </Grid>
                    {!isTablet && (
                        /* Small picture Grid */
                        <Grid item container sm={4} lg={2} rowGap={2} sx={{ display: 'flex', flexDirection: 'column', height: '100%', maxHeight: 425 }}>
                            <LocationSmallPictures locationDetails={locationDetails} handleOpenGallery={handleOpenGallery} />
                        </Grid>
                    )}
                    {/* Map Grid */}
                    <Grid item xs={12} sm={5} lg={3} sx={{ display: 'flex', flexDirection: 'column' }}>
                        {/* {locationDetails ? (
                            <Typography variant="h3" gutterBottom mb={2} sx={{ textAlign: 'left' }}>
                                {locationDetails.location_name}
                            </Typography>
                        ) : (
                            <Skeleton variant="text" width="100%" height={60} />
                        )
                        } */}
                        {locationDetails ? (

                            <Button
                                startIcon={<NavigationOutlinedIcon />}
                                href={locationDetails.location_map_url}
                                target="_blank"
                                sx={{
                                    fontSize: '15px',
                                    fontWeight: 300,
                                    textAlign: 'center',
                                    alignItems: 'center',
                                    padding: '9px 35px',
                                    lineHeight: 1,
                                    cursor: 'pointer',
                                    justifyContent: 'center',
                                    border: '1px solid',
                                    borderColor: theme.palette.primary.main,
                                    color: theme.palette.primary.main,
                                    width: '100%',
                                    background: 'none',
                                    marginBottom: '5px'
                                }}
                            >
                                Navigate
                            </Button>
                        ) : (
                            <Skeleton variant="text" width="100%" height={100} sx={{ borderRadius: '20px' }} />
                        )
                        }
                        {locationDetails ? (

                            <Typography gutterBottom variant="subtitle2" display="flex" alignItems="center">
                                <LocationOnIcon color="primary" sx={{ marginRight: '8px' }} />
                                {locationDetails.property_address}
                            </Typography>
                        ) : (
                            <Skeleton variant="text" width="100%" height={60} />
                        )
                        }
                        {locationDetails ? (

                            <Box style={{ flexGrow: 1, minHeight: '150px', width: '100%', borderRadius: '20px', overflow: 'hidden' }}>
                                {locationDetails && (
                                    <MapComponent lat={locationDetails.latitude} lng={locationDetails.longitude} isDarkMode={isDarkMode} />
                                )}
                            </Box>
                        ) : (
                            <Skeleton variant="rectangular" width="100%" height="100%" sx={{ borderRadius: '20px' }} />
                        )
                        }
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12} mt={3} sx={{ display: 'flex', flexDirection: 'column' }}>
                        {locationDetails ? (
                            <OtherLocationDetails
                                locationDetails={locationDetails}
                            />
                        ) : (
                            <>
                                <Skeleton variant="text" width="120px" height="30px" sx={{ borderRadius: '5px' }} />
                                <Skeleton variant="rectangular" width="100%" height="150px" sx={{ borderRadius: '5px' }} />

                                <Skeleton variant="text" width="200px" height="30px" sx={{ borderRadius: '5px' }} />
                                <Skeleton variant="text" width="200px" height="30px" sx={{ borderRadius: '5px' }} />
                            </>
                        )
                        }
                    </Grid>
                </Grid>
                {/* Check slots Grid - Conditionally rendered for "user" role */}
                {user.role === "user" && (
                    <Grid item xs={12} sm={12} md={12} lg={3} id="availableSlots">
                        {locationDetails ? (
                            <CheckSlots
                                locationId={locationDetails.location_id}
                                locationDetails={locationDetails}
                                setLoading={setLoading}
                            />
                        ) : (
                            <Skeleton variant="rectangular" width="100%" height="240px" sx={{ borderRadius: '20px' }} />
                        )}

                        {isTablet && <CheckSlotsButton onClick={scrollToSlots} show={showCheckSlotsButton} />}
                    </Grid>
                )}
            </Grid>

            {
                locationDetails && (
                    <PicturesDialog
                        locationDetails={locationDetails}
                        handleCloseGallery={handleCloseGallery}
                        open={open}
                    />)
            }
        </Container >
    );
};

export default LocationDetail;
