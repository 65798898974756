import React from 'react';

const NotFound = () => (
    <div>
        <h1>Page Not Found</h1>
        The page you are looking for doesn't exist or another error occurred.
    </div>
);

export default NotFound;
