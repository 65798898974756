import React, { useState, useContext } from 'react';
import { DataContext } from '../DataContext'; // Adjust the path as needed
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Box, Typography, Stack, Link as MuiLink } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Highlight from '../../utils/Highlight';


const WorkationInfo = () => {
    const [openDialog, setOpenDialog] = useState(false);
    const { data } = useContext(DataContext);
    const { preferences, locations } = data;
    const navigate = useNavigate();


    const handleDialogOpen = () => {
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        navigate(-1);
    };
    const specialInviteLink = (text) => (
        <MuiLink component={RouterLink} to={{
            pathname: "/demand-request",
            state: { locations: locations, preferences: preferences }
        }}>
            {text}
        </MuiLink>
    );

    const getDescription = () => {
        let descriptionElements = [];

        if (preferences.individual) {
            let individualText = (
                <Box>
                    <DialogContentText key="individual">
                        <Highlight type="individual">Individual</Highlight> workation type allows you to participate alone in the event.
                        {preferences.allow_accompanying === 1 && " However, for selected slots, you would be permitted to bring one accompanying person (you would find out while selecting slots). "}
                        {" Some properties might accommodate multiple employees simultaneously. This is typically for larger properties with multiple bedrooms."}
                        {preferences.invite_individuals === 1 && (
                            <span>
                                {" "}Important! You need to {specialInviteLink('regquest for a voucher')} to book individual workation.
                            </span>
                        )}
                    </DialogContentText>
                </Box>
            );
            descriptionElements.push(individualText);
        }
        if (preferences.family) {
            descriptionElements.push(
                <Box>
                    <DialogContentText key="family">
                        <Highlight type="family">Family</Highlight> workation is designed for employees who wish to travel with their spouse and children. Such workation is specially reserved for families and typically occupy the entire property.
                        Booking a family workation {specialInviteLink('requires a voucher')}.

                    </DialogContentText>
                </Box>
            );
        }
        if (preferences.team_integration) {
            descriptionElements.push(
                <Box>

                    <DialogContentText key="team_integration">
                        <Highlight type="team_integration">Team Integration</Highlight> workation is for team-building activities and can only be booked by a team manager. The entire property is for your Team. The Team Manager {specialInviteLink('requires a voucher to book such an event.')}
                    </DialogContentText>
                </Box>

            );
        }

        descriptionElements.push(
            <Box>
                <DialogContentText key="general_info">
                    <b>Note:</b> If you cannot see a workation type while checking availability at the location page, it means you cannot book it at the moment.
                </DialogContentText>
            </Box>

        );
        return descriptionElements;
    };

    return (
        <>
            <Box display="flex" alignItems="center" >
                <InfoIcon color="primary" />
                <Typography variant="body1" sx={{ ml: 1 }}>
                    Learn about{" "}
                    <MuiLink href="#" onClick={handleDialogOpen} color="primary">
                        workation types
                    </MuiLink>
                </Typography>
            </Box>

            <Dialog
                open={openDialog}
                onClose={handleDialogClose}
                aria-labelledby="workation-dialog-title"
                aria-describedby="workation-dialog-description"
            >
                <DialogTitle id="workation-dialog-title">Workation Types</DialogTitle>
                <DialogContent>
                    <Stack spacing={2} mb={3}>
                        {getDescription()}
                    </Stack>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center', padding: 0, position: 'absolute', bottom: 0, left: 0, right: 0 }}>
                    <Button
                        onClick={handleDialogClose}
                        color="primary"
                        variant="contained"
                        style={{
                            //backgroundColor: 'white',
                            position: 'absolute',
                            bottom: '-24px', // Half the height of the button to be outside the bottom edge
                            left: '50%',
                            transform: 'translateX(-50%)',
                            borderRadius: '20px',
                        }}
                    >
                        Close
                    </Button>
                </DialogActions>

            </Dialog>
        </>
    );
};

export default WorkationInfo;