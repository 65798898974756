import React from 'react';
import { Card, Chip, Stack, Box, Link, Typography, Grid } from '@mui/material';
import { Link as RouteLink } from 'react-router-dom';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EventIcon from '@mui/icons-material/Event';
import BedtimeIcon from '@mui/icons-material/Bedtime';
import PhoneIcon from '@mui/icons-material/Phone';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import { formatDate, formatTime, calculateDaysDifference } from '../../utils/dateAndTime';
import OccupancyDescription from '../Booking/OccupancyDescription';
import Highlight from '../../utils/Highlight';
import { useTheme } from '@mui/material/styles';


const WorkationCard = ({ workation }) => {
    const theme = useTheme();
    const withPartnerAvailability = workation.individual_with_accomp === 1 &&
        (workation.sin_beds > 1 || workation.double_beds > 0);
    const chipStyle = {
        pending: {
            backgroundColor: '#FFCC80', // Pastel Orange
            color: '#FFFFFF', // Stronger Orange
        },
        approved: {
            backgroundColor: '#C8E6C9', // Pastel Green
            color: '#FFFFFF'
        }
    };
    // { position: 'absolute', top: 8, right: 8 }
    return (
        <Grid item xs={12} md={12} lg={8}>
            <Card sx={{ maxWidth: '100%' }}>
                <Grid container spacingrow={2}>
                    {/* Image and Details */}
                    <Grid item md={4} lg={4}>
                        <RouteLink to={`/location/${workation.location_id}`}>

                            <img
                                src={`${process.env.REACT_APP_PROPERTIES_PICTURES_ROOT_URL}/${workation.location_picture}`}
                                alt="Location thumbnail"
                                style={{ width: '100%', height: '100%', borderRadius: '15px', objectFit: 'cover' }}
                            />
                        </RouteLink>
                    </Grid>
                    <Grid item md={8} lg={8} container>
                        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ padding: 2 }}>
                            <Stack sx={{ gap: 1 }}>
                                <Typography variant="h4" mb={1}>
                                    <RouteLink to={`/location/${workation.location_id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                        {workation.location_name}
                                    </RouteLink>
                                </Typography>
                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                    <LocationOnIcon sx={{ color: theme.palette.primary.used, fontSize: '20px' }} />
                                    <Link href={workation.location_navigate_url} color="inherit" target="_blank" sx={{ textDecoration: 'none' }}>
                                        {workation.location_address}
                                    </Link>
                                </Box>
                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                    <EventIcon sx={{ color: theme.palette.primary.used, fontSize: '20px' }} />
                                    <Typography >
                                        <b>{formatDate(workation.start_date)}</b> check in {formatTime(workation.check_in)}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                    <BedtimeIcon sx={{ color: theme.palette.primary.used, fontSize: '20px' }} />
                                    <Typography >
                                        Stay for {calculateDaysDifference(workation.start_date, workation.end_date)} nights
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                    <EventIcon sx={{ color: theme.palette.primary.used, fontSize: '20px' }} />
                                    <Typography >
                                        <b>{formatDate(workation.end_date)}</b> check out {formatTime(workation.check_out)}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                    <PhoneIcon sx={{ color: theme.palette.primary.used, fontSize: '20px' }} />
                                    <Typography>
                                        {workation.status === 'pending' ? "(Reveal after approval)" : workation.contact_number}
                                    </Typography>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ padding: 2 }}>
                            <Stack sx={{ gap: 2 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Chip
                                        label={workation.status.charAt(0).toUpperCase() + workation.status.slice(1)}
                                        sx={workation.status === 'pending' ? chipStyle.pending : chipStyle.approved}
                                    />
                                    <Typography variant="body2">

                                        <>by <strong>{workation.workation_approver}</strong></>
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                    <LaptopMacIcon sx={{ color: theme.palette.primary.used, fontSize: '20px' }} />
                                    <Typography >
                                        <Highlight type={workation.occupancy_type}>{workation.occupancy_type.split('_').join(' ')}</Highlight> workation
                                    </Typography>
                                </Box>
                                <OccupancyDescription
                                    occupancyType={workation.occupancy_type}
                                    withPartnerAvailability={withPartnerAvailability}
                                    indWithAccomp={workation.individual_with_accomp}
                                    allowSharing={workation.allow_sharing}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    );
};

export default WorkationCard;
