import React, { useState, useEffect, useContext } from 'react';
// import axios from '../axios';
import { DataContext } from './DataContext';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, Container, Accordion, AccordionSummary, AccordionDetails, Typography, IconButton, Stack, Grid, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WorkationCard from './Workation/WorkationCard';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GlobalSpinner from './GlobalSpinner';
import { useTheme } from '@mui/material/styles';



const MyWorkations = () => {
    // const [workations, setWorkations] = useState([]);
    // const [loading, setLoading] = useState(false);
    // const [error, setError] = useState('');
    const navigate = useNavigate();
    const theme = useTheme();

    const { data, loading } = useContext(DataContext);
    const workations = data.workations;

    const handleBack = () => {
        navigate(-1);
    };

    const isCurrent = (workation) => {
        const today = new Date();
        const startDate = new Date(workation.start_date);
        const endDate = new Date(workation.end_date);
        return startDate <= today && endDate >= today;
    };

    const isUpcoming = (workation) => new Date(workation.start_date) > new Date();
    const isPast = (workation) => new Date(workation.end_date) < new Date();

    const sortWorkationsByStartDate = (a, b) => {
        return new Date(a.start_date) - new Date(b.start_date);
    };

    const currentWorkations = workations.filter(isCurrent).sort(sortWorkationsByStartDate);
    const upcomingWorkations = workations.filter(isUpcoming).sort(sortWorkationsByStartDate);
    const pastWorkations = workations.filter(isPast).sort(sortWorkationsByStartDate);

    return (
        <Container maxWidth="lg">
            {loading.workations ? <GlobalSpinner /> : (
                <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                        <IconButton onClick={handleBack} aria-label="back">
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="h3" m={0}>My Workations</Typography>
                    </Box>
                    <Grid container >
                        {currentWorkations.length > 0 && (
                            <Accordion defaultExpanded={currentWorkations.length > 0} sx={{ width: '100%', mt: 0, mb: 0, boxShadow: 'none' }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: theme.palette.primary.card }}>
                                    <Typography variant="h4">Current Workation</Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{ backgroundColor: theme.palette.primary.card }} >
                                    {currentWorkations.length > 0 ? currentWorkations.map(w => <WorkationCard key={w.workation_id} workation={w} />) : <Typography sx={{ paddingX: 2 }}>No current workations</Typography>}
                                </AccordionDetails>
                            </Accordion>
                        )}
                        <Accordion defaultExpanded={currentWorkations.length === 0 && upcomingWorkations.length > 0} sx={{ width: '100%', mt: 0, mb: 0, boxShadow: 'none' }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: theme.palette.primary.card }}>
                                <Typography variant="h4">Upcoming Workations: {upcomingWorkations.length}</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ backgroundColor: theme.palette.primary.card }}>
                                <Stack sx={{ gap: 2 }}>
                                    {upcomingWorkations.length > 0 ? upcomingWorkations.map(w => <WorkationCard key={w.workation_id} workation={w} />) : <Typography sx={{ paddingX: 2 }}>No upcoming workations</Typography>}
                                </Stack>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion sx={{ width: '100%', mt: 0, mb: 0, boxShadow: 'none' }} >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: theme.palette.primary.card }}>
                                <Typography variant="h4">Past Workations: {pastWorkations.length}</Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ backgroundColor: theme.palette.primary.card }}>
                                <Stack sx={{ gap: 2 }}>
                                    {pastWorkations.length > 0 ? pastWorkations.map(w => <WorkationCard key={w.workation_id} workation={w} />) : <Typography sx={{ paddingX: 2 }}>No past workations</Typography>}
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Box>
            )}
        </Container>
    );
};

export default MyWorkations;
