import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, CircularProgress } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const ConfirmationDialog = ({ open, onClose, onConfirm, loading }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Confirm Action</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to proceed and remove the employee from the slot?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained">Cancel</Button>
                <Button onClick={onConfirm} variant="outlined" disabled={loading} startIcon={loading ? <CircularProgress size={24} /> : <DeleteOutlineIcon />}>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;