import React from 'react';
import { Card, Typography, Box } from '@mui/material';
import Highlight from '../../utils/Highlight';
import { formatDate } from '../../utils/dateAndTime';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import { useTheme } from '@mui/material/styles';

const TicketCard = ({ invite, isSpecial, workationsInfo, preferences, qty }) => {
    // Calculate qty if not provided explicitly
    const theme = useTheme();

    const calculatedQty = typeof qty !== 'undefined' ? qty :
        preferences.individual && !preferences.invite_individuals
            ? workationsInfo.thisYearWorkationsToUse + (workationsInfo.nextYearLimitApplies ? workationsInfo.nextYearWorkationsToUse : 0)
            : 1;

    const getExpiryText = () => {
        // Adjust your conditions using calculatedQty instead of qty
        if (calculatedQty === 0 && preferences.individual && !preferences.invite_individuals && invite.invite_type === "individual") {
            return `You already used all`;
        } else if (calculatedQty !== 0 && preferences.individual && !preferences.invite_individuals && invite.invite_type === "individual") {
            let expiryText = `${workationsInfo.thisYearWorkationsToUse} expiring in ${workationsInfo.currentYear}`;
            if (workationsInfo.nextYearLimitApplies) {
                expiryText += ` and ${workationsInfo.nextYearWorkationsToUse} in ${workationsInfo.currentYear + 1}`;
            }
            return expiryText;
        } else if (isSpecial && calculatedQty === 0) {
            return "No special vouchers available";
        } else {
            // Adjust this part based on your specific requirements for special invites or other types
            return invite.expiry_date ? `Expiring on ${formatDate(invite.expiry_date)} (in ${invite.days_until_expiry})` : "";
        }
    };

    const dontShowHighlight = isSpecial && calculatedQty === 0;

    const qtyStyles = {
        color: calculatedQty === 0 ? theme.palette.primary.used : theme.palette.primary.notUsed, // If qty is 0, use light grey color, otherwise inherit the default color
        borderLeft: `2px dashed ${calculatedQty === 0 ? theme.palette.primary.used : theme.palette.primary.notUsed}` // Same logic for the border color
    };

    return (
        <Card sx={{ display: 'flex', overflow: 'hidden' }}>
            {/* Main Content */}
            <Box sx={{
                width: '80%',
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}>
                {!dontShowHighlight && (
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        <Typography variant="subtitle1" component="div" mb={0.5}>
                            <Highlight type={invite.invite_type}>{invite.invite_type.split('_').join(' ')}</Highlight> workation
                        </Typography>
                    </Box>
                )}
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <ConfirmationNumberOutlinedIcon sx={{ color: calculatedQty === 0 ? theme.palette.primary.used : theme.palette.primary.notUsed }} />
                    <Typography color="text.secondary">
                        {getExpiryText()}
                    </Typography>
                </Box>
            </Box>

            {/* Stub Part */}
            <Box sx={{
                width: '20%',
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                ...qtyStyles // Apply the dynamic styles here
            }}>
                <Typography variant="h5" component="div" style={{ color: qtyStyles.color }}>
                    {calculatedQty}
                </Typography>
                <Typography color="text.secondary">
                    Qty
                </Typography>
            </Box>
        </Card>
    );
};

export default TicketCard;
