// src/components/UnauthorizedListener.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'; // Adjust the import path as necessary

const UnauthorizedListener = () => {
    const navigate = useNavigate();
    const { handleUnauthorized } = useAuth();

    useEffect(() => {
        const listener = () => {
            handleUnauthorized();
            navigate('/login');
        };

        window.addEventListener('unauthorized', listener);
        return () => window.removeEventListener('unauthorized', listener);
    }, [navigate, handleUnauthorized]);

    return null; // This component does not render anything
};

export default UnauthorizedListener; // Ensure this line is present
