import React, { useState, useEffect, useContext } from 'react';
import { Table, TableHead, TableRow, TableCell, Grid, TableBody, CircularProgress, Box, Typography, IconButton, Card, CardContent, LinearProgress, Button, Pagination } from '@mui/material';
import axios from '../../axios';
import dayjs from 'dayjs';
import { AdminDataContext } from '../AdminDataContext';
import { useAuth } from '../../contexts/AuthContext';
import { formatDate, formatTime } from '../../utils/dateAndTime';
import Highlight from '../../utils/Highlight';
import { toast } from 'react-toastify';
import { useLoading } from '../LoadingContext'; // Import useLoading
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import AddVoucherDialog from './AddVoucherDialog'; // Import AddVoucherDialog component

const Vouchers = ({ filters, currentPage, setCurrentPage }) => {
    const { data: adminData, loading: dataLoading } = useContext(AdminDataContext);
    const { user } = useAuth();
    const [vouchers, setVouchers] = useState([]);
    const [totalVouchers, setTotalVouchers] = useState(0);
    const [usedVouchers, setUsedVouchers] = useState(0);
    const otherVouchers = totalVouchers - usedVouchers;
    const [hoveredRow, setHoveredRow] = useState(null); // State to track hovered row
    const [totalPages, setTotalPages] = useState(1);
    const { setLoading } = useLoading();
    const [cancelingInviteId, setCancelingInviteId] = useState(null);  // Track which invite is being sent
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleCancelInvite = async (voucher) => {
        setCancelingInviteId(voucher.invite_id);  // Mark this request as being processed
        const body = {
            send_email: !adminData.preferences.skip_emails_when_admin_modifies,
        };
        if (user.role === 'admin') {
            body.company_id = adminData.selectedCompanyId;
        }
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PREFIX}/special-invites/delete/${voucher.invite_id}`, body);
            toast.success('Voucher removed successfully. It will not be visible after the table refresh.');
            setVouchers(currentVouchers =>
                currentVouchers.map(v =>
                    v.invite_id === voucher.invite_id ? { ...v, status: "removed" } : v
                )
            );  // Update the specific invite's  status
            setCancelingInviteId(null);  // Reset after successful send
        } catch (error) {
            toast.error((error.response?.data?.message || 'Failed to remove the voucher'));
            setCancelingInviteId(null);  // Reset after error
        }
    };

    useEffect(() => {
        fetchVouchers();
    }, [filters, currentPage]);

    const fetchVouchers = async () => {
        setLoading(true);
        const params = {
            from_date: filters.from_date ? dayjs(filters.from_date).format('YYYY-MM-DD') : undefined,
            to_date: filters.to_date ? dayjs(filters.to_date).format('YYYY-MM-DD') : undefined,
            invite_type: filters.occupancy_type ? filters.occupancy_type : undefined,
            status: filters.status ? filters.status : undefined,
            page: currentPage,
            per_page: 10
        };
        if (user.role === 'admin') {
            params.company_id = adminData.selectedCompanyId;
        }
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PREFIX}/special-invites/view-special-invites`, { params });
            setVouchers(response.data.specialInvites.vouchers.data);
            setTotalPages(response.data.specialInvites.vouchers.last_page);
            setTotalVouchers(response.data.specialInvites.totalVouchers);
            setUsedVouchers(response.data.specialInvites.totalUsed);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error((error.response?.data?.message || 'Could not load the vouchers page.'));
        }
    };

    return (
        <Box>
            <Grid container>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Card variant="outlined" sx={{ minWidth: 250, my: 2 }}>
                        <CardContent>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                    <Typography variant="body1" gutterBottom>
                                        Total vouchers
                                    </Typography>
                                    <Typography variant="body1" gutterBottom fontSize={20}>
                                        <b>{totalVouchers}</b>
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mr: 1 }}>
                                    <Typography variant="body1" gutterBottom>
                                        Used vouchers
                                    </Typography>
                                    <Typography variant="body1" gutterBottom fontSize={20}>
                                        <b>{usedVouchers}</b>
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ width: '100%', mr: 1 }}>
                                    <LinearProgress variant="determinate" value={(usedVouchers / totalVouchers) * 100} />
                                </Box>
                                {totalVouchers && (
                                    <Box sx={{ minWidth: 35 }}>
                                        <Typography variant="body2" color="text.secondary">{`${Math.round(
                                            (usedVouchers / totalVouchers) * 100,
                                        )}%`}</Typography>
                                    </Box>
                                )}
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} alignContent={'center'}>
                    <Button
                        variant="contained"
                        //startIcon={<AddIcon />}
                        onClick={handleOpenDialog}
                        sx={{ marginBottom: 2, marginTop: 2 }}
                    >
                        + Voucher
                    </Button>
                </Grid>
                <AddVoucherDialog
                    open={openDialog}
                    handleClose={handleCloseDialog}
                    refreshVouchers={fetchVouchers}
                />
            </Grid>

            <Box sx={{ overflowX: 'auto' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><b>Who</b></TableCell>
                            <TableCell><b>Type</b></TableCell>
                            <TableCell><b>Expiring Time</b></TableCell>
                            <TableCell><b>Voucher Status</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {vouchers.map((voucher, index) => (
                            <TableRow key={voucher.invite_id}
                                onMouseEnter={() => setHoveredRow(index)}
                                onMouseLeave={() => setHoveredRow(null)}
                                sx={{ '&:hover': { cursor: 'default' } }}
                            >
                                <TableCell>{voucher.invitee_email}</TableCell>
                                <TableCell><Highlight type={voucher.invite_type}>{voucher.invite_type}</Highlight></TableCell>
                                <TableCell>{formatDate(formatTime(voucher.expiring_time))}</TableCell>
                                <TableCell sx={{ pt: 0, pb: 0 }}>
                                    {hoveredRow === index ? (
                                        cancelingInviteId === voucher.invite_id ? (
                                            <CircularProgress size={24} />
                                        ) : (
                                            <IconButton onClick={() => handleCancelInvite(voucher)} size="small" color='error'>
                                                <DeleteForeverIcon sx={{ fontSize: 22 }} />
                                            </IconButton>
                                        )
                                    ) : (
                                        cancelingInviteId === voucher.invite_id ? (
                                            <CircularProgress size={24} />) : <Highlight type={voucher.status === "pending" ? "approved" : voucher.status === "accepted" ? "pending" : "rejected"}>{voucher.status === "pending" ? "not used" : voucher.status === "accepted" ? "used" : voucher.status === "removed" ? "removed" : "expired"}</Highlight>
                                    )}
                                </TableCell>
                                {/* <TableCell><Highlight type={voucher.status === "pending" ? "approved" : voucher.status === "accepted" ? "pending" : "rejected"}>{voucher.status === "pending" ? "not used" : voucher.status === "accepted" ? "used" : "expired"}</Highlight></TableCell> */}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

            </Box>
            <Pagination
                count={totalPages}
                page={currentPage}
                onChange={(e, value) => setCurrentPage(value)}
                color="primary"
                sx={{ marginTop: 2, justifyContent: 'center', display: 'flex' }}
            />
        </Box >
    );
};

export default Vouchers;
