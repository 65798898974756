import React, { useState } from 'react';
import { Typography, Link, Dialog, DialogTitle, DialogContent, Button } from '@mui/material';

const OccupancyDescription = ({ occupancyType, withPartnerAvailability, indWithAccomp, allowSharing }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const getDescription = () => {
        let description = '';

        if (occupancyType === 'individual') {
            if (indWithAccomp === 0) {
                return "You are going alone, however there are other employees that may join you. You will share the workspace, but the bedrooms are private.";
            } else if (indWithAccomp === 1) {
                if (withPartnerAvailability === 1) {
                    return "You are allowed to take 1 accompanying person with you. Please also note there are other employees that may join you (also with an accompanying person). You will share the workspace with others, but the bedrooms are private.";
                } else {
                    return "You are going alone, however there are other employees that may join you. You will share the workspace with others, but the bedrooms are private.";
                }
            }

            if (allowSharing === 1) {
                return "You are going alone but there will be other employees going with you. The workspace and bedrooms will be shared.";
            }
        } else if (occupancyType === 'family') {
            return "The whole property is for you, there will be no other employees coming to the property at the time of your stay. Remember this place is dedicated for families. Please mind the maximum guests limit.";
        } else if (occupancyType === 'team_integration') {
            return "You, as a manager organizing the Team Integration, are responsible to inform the employees about the event. Please note the maximum guests limit at the property. You can decide whether the bedrooms would be shared, or you would rather keep them private for everyone.";
        }
        return description;
    };

    const handleDialogOpen = (event) => {
        event.preventDefault(); // Prevent the default link action
        setIsDialogOpen(true);
    }; const handleDialogClose = () => setIsDialogOpen(false);

    const description = getDescription();
    const truncatedDescription = description.length > 90 ? description.substring(0, 90) + '...' : description;

    return (
        <>
            <Typography>
                {<b>Note! </b>} {truncatedDescription}
                {description.length > 90 && (
                    <Link href="#" onClick={handleDialogOpen} style={{ textDecoration: 'none', marginLeft: '5px' }}><b>Read more</b></Link>
                )}
            </Typography>
            <Dialog open={isDialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Note</DialogTitle>
                <DialogContent>
                    <Typography mb={3}>{description}</Typography>
                </DialogContent>
                <Button
                    onClick={handleDialogClose}
                    color="primary"
                    variant="contained"
                    style={{
                        //backgroundColor: 'white',
                        position: 'absolute',
                        bottom: '-24px', // Half the height of the button to be outside the bottom edge
                        left: '50%',
                        transform: 'translateX(-50%)',
                        borderRadius: '20px',
                    }}
                >
                    Close
                </Button>
            </Dialog>
        </>
    );
};

export default OccupancyDescription;
