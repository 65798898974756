import React, { useState, useEffect, useContext } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, Grid, CircularProgress, Box, Typography, IconButton, Pagination, Card, CardContent, LinearProgress } from '@mui/material';
import axios from '../../axios';
import dayjs from 'dayjs';
import { AdminDataContext } from '../AdminDataContext';
import { useAuth } from '../../contexts/AuthContext';
import { formatDate } from '../../utils/dateAndTime';
import Highlight from '../../utils/Highlight';
import SendIcon from '@mui/icons-material/Send';
import { toast } from 'react-toastify';
import { useLoading } from '../LoadingContext'; // Import useLoading


const VoucherRequests = ({ filters, currentPage, setCurrentPage }) => {
    const { data: adminData, loading: dataLoading } = useContext(AdminDataContext);
    const { user } = useAuth();
    const [requests, setRequests] = useState([]);
    //const [localLoading, setLocalLoading] = useState(false);
    const [totalDemand, setTotalDemand] = useState(0);
    const [demandInvitesNotSent, setDemandInvitesNotSent] = useState(0);
    const demandInvitesSent = totalDemand - demandInvitesNotSent;
    const [totalPages, setTotalPages] = useState(1); // Assuming there is at least one page
    const [hoveredRow, setHoveredRow] = useState(null); // State to track hovered row
    const [sendingInviteId, setSendingInviteId] = useState(null);  // Track which invite is being sent
    const { setLoading } = useLoading(); // Use setLoading from LoadingContext for global spinner

    const handleSendInvite = async (request) => {
        setSendingInviteId(request.id);  // Mark this request as being processed
        const body = {
            invite_type: request.occupancy_type,
            invitee_email: request.user.email,
            valid_days: 7,
            send_email: !adminData.preferences.skip_emails_when_admin_modifies,
            demand_request_id: request.id,
        };
        if (user.role === 'admin') {
            body.company_id = adminData.selectedCompanyId;
        }
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PREFIX}/special-invites/create`, body);
            toast.success('Voucher sent successfully!');
            setRequests(currentRequests =>
                currentRequests.map(r =>
                    r.id === request.id ? { ...r, invite_sent: 1 } : r
                )
            );  // Update the specific request's invite_sent status
            setDemandInvitesNotSent(prevCount => prevCount > 0 ? prevCount - 1 : 0);
            setSendingInviteId(null);  // Reset after successful send
        } catch (error) {
            toast.error((error.response?.data?.message || 'Failed to send the voucher'));
            setSendingInviteId(null);  // Reset after error
        }
    };

    useEffect(() => {
        fetchRequests();
    }, [filters, currentPage]);

    useEffect(() => {
        console.log({ totalDemand });
    }, [totalDemand]);


    const fetchRequests = async () => {
        setLoading(true);
        const params = {
            from_date: filters.from_date ? dayjs(filters.from_date).format('YYYY-MM-DD') : undefined,
            to_date: filters.to_date ? dayjs(filters.to_date).format('YYYY-MM-DD') : undefined,
            occupancy_type: filters.occupancy_type ? filters.occupancy_type : undefined,
            invite_sent: filters.invite_sent === 1 ? 1 : filters.invite_sent === 0 ? 0 : undefined,
            page: currentPage,
            per_page: 10
        };
        if (user.role === 'admin') {
            params.company_id = adminData.selectedCompanyId;
        }
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PREFIX}/special-invites/view-demand-requests`, { params });
            setRequests(response.data.demandRequests.demandRequests.data);
            setDemandInvitesNotSent(response.data.demandRequests.totalWithInviteSentFalse);
            setTotalDemand(response.data.demandRequests.totalDemand);
            setTotalPages(response.data.demandRequests.demandRequests.last_page);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error((error.response?.data?.message || 'Could not load the vouchers page.'));
        }
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <Box>
            <Grid container>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Card variant="outlined" sx={{ minWidth: 250, my: 2 }}>
                        <CardContent>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                    <Typography variant="body1" gutterBottom>
                                        Total requests
                                    </Typography>
                                    <Typography variant="body1" gutterBottom fontSize={20}>
                                        <b>{totalDemand}</b>
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mr: 1 }}>
                                    <Typography variant="body1" gutterBottom>
                                        Sent vouchers
                                    </Typography>
                                    <Typography variant="body1" gutterBottom fontSize={20}>
                                        <b>{demandInvitesSent}</b>
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ width: '100%', mr: 1 }}>
                                    <LinearProgress variant="determinate" value={(demandInvitesSent / totalDemand) * 100} />
                                </Box>
                                {totalDemand && (
                                    <Box sx={{ minWidth: 35 }}>
                                        <Typography variant="body2" color="text.secondary">{`${Math.round(
                                            (demandInvitesSent / totalDemand) * 100,
                                        )}%`}</Typography>
                                    </Box>
                                )}
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Box sx={{ overflowX: 'auto' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><b>Who</b></TableCell>
                            <TableCell><b>Location</b></TableCell>
                            <TableCell><b>Type</b></TableCell>
                            <TableCell><b>Created</b></TableCell>
                            <TableCell><b>Voucher sent</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {requests.map((request, index) => (
                            <TableRow
                                key={request.id}
                                onMouseEnter={() => setHoveredRow(index)}
                                onMouseLeave={() => setHoveredRow(null)}
                                sx={{ '&:hover': { cursor: 'default' } }}
                            >
                                <TableCell>{request.user.email}</TableCell>
                                <TableCell>{request.location.location_name}</TableCell>
                                <TableCell><Highlight type={request.occupancy_type}>{request.occupancy_type}</Highlight></TableCell>
                                <TableCell>{formatDate(request.created_at)}</TableCell>
                                <TableCell sx={{ pt: 0, pb: 0 }}>
                                    {hoveredRow === index && !request.invite_sent ? (
                                        sendingInviteId === request.id ? (
                                            <CircularProgress size={24} />
                                        ) : (
                                            <IconButton onClick={() => handleSendInvite(request)} size="small" color="primary">
                                                <SendIcon sx={{ fontSize: 20 }} />
                                            </IconButton>
                                        )
                                    ) : (
                                        sendingInviteId === request.id ? (
                                            <CircularProgress size={24} />) : <Highlight type={request.invite_sent ? 'approved' : 'rejected'}>{request.invite_sent ? 'Yes' : 'No'}</Highlight>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

            </Box>
            <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                sx={{ marginTop: 2, justifyContent: 'center', display: 'flex' }}
            />
        </Box>
    );
};

export default VoucherRequests;