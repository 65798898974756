import React, { useState } from 'react';
import {
    Box,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    OutlinedInput,
    InputAdornment,
    IconButton,
    FormHelperText,
    TextField
} from '@mui/material';

import PersonIcon from '@mui/icons-material/Person';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

const AttendeeDetails = ({ register, errors, handlePhoneChange, phone }) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
        setIsFocused(true);
    };
    const handleBlur = () => {
        setIsFocused(false);
    };
    return (
        <section>
            <Box paddingX={2} paddingBottom={2} border={1} borderColor="rgb(54, 54, 54)" borderRadius={5} marginBottom={3}>
                <h2>Attendee Details</h2>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={12}>
                        Please indicate the number of adults attending (Select "1" if it's just you) and the number of children under 14 years old accompanying you.
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel shrink={true} htmlFor="adults">Adults </InputLabel>
                            <Select
                                fullWidth
                                id="adults"
                                defaultValue={1}
                                input={<OutlinedInput id="adults" label="Adults" notched />}
                                {...register("adults", { required: "Number of adults is required" })}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <IconButton>
                                            <PersonIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            >
                                {[1, 2, 3, 4, 5].map((number) => (
                                    <MenuItem key={number} value={number}>
                                        {number}
                                    </MenuItem>
                                ))}
                            </Select>
                            {errors.adults && <FormHelperText error>{errors.adults.message}</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="children">Children</InputLabel>
                            <Select
                                fullWidth
                                id="children"
                                defaultValue={0}
                                input={<OutlinedInput id="children" label="Children" notched />}
                                {...register("children")}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <IconButton>
                                            <EscalatorWarningIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            >
                                {[0, 1, 2, 3, 4].map((number) => (
                                    <MenuItem key={number} value={number}>
                                        {number}
                                    </MenuItem>
                                ))}
                            </Select>
                            {errors.children && <FormHelperText error>{errors.children.message}</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <PhoneInput
                            country={'pl'}
                            value={phone}
                            onChange={handlePhoneChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            name={register("phone", { required: "Phone number is required" }).name}
                            containerStyle={{ width: '100%' }} // Adjusting width of the container
                            inputProps={{
                                style: {
                                    width: '100%', // Adjusting width of the input field
                                    border: isFocused ? '1px solid #4CC5E0' : '', // Custom border color on focus
                                    borderRadius: '8px', // Custom border radius
                                    outline: isFocused ? '1px solid #4CC5E0' : 'none', // Remove default focus outline
                                    boxShadow: 'none',
                                },
                                placeholder: 'Enter phone number', // Placeholder text for the input field
                            }}
                        />
                        {errors.phone && <FormHelperText error>{errors.phone.message}</FormHelperText>}
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            multiline
                            fullWidth
                            rows={3}
                            variant="outlined"
                            label="Comments"
                            inputProps={{ maxLength: 160 }}
                            {...register("comments")}
                        />
                    </Grid>
                </Grid>
            </Box>
        </section>
    );
}

export default AttendeeDetails;
