const quietnessDescription = (quietnessLevel) => {
    switch (quietnessLevel) {
        case 1:
            return 'next to very noisy street / very noisy area';
        case 2:
            return 'noisy most of the time';
        case 3:
            return 'quiet - sometimes noisy';
        case 4:
            return 'quiet place';
        case 5:
            return 'very quiet and peaceful place';
        default:
            return 'quietness level not specified';
    }
}

export { quietnessDescription };