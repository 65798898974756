import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CircularProgress, Typography, Box, useTheme, Button, Chip } from '@mui/material';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { formatDate, calculateDaysDifference } from '../../utils/dateAndTime';

const SlotCard = ({ slots, locationDetails, loading, fetchSlots, hasMore, currentPage, occupancyType, onSlotClick }) => {
    const navigate = useNavigate();
    const theme = useTheme();

    let lastMonthYear = null;

    const getMonthLabel = (date) => {
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const d = new Date(date);
        return `${monthNames[d.getMonth()]} ${d.getFullYear()}`;
    };

    const handleSlotClick = (slot) => {
        const locationData = {
            locationId: locationDetails.location_id,
            thumbnailUrl: locationDetails.pictures[0].file_path,
            name: locationDetails.location_name,
            address: locationDetails.property_address,
            workstations: locationDetails.workstations,
            internetSpeed: locationDetails.internet_speed,
            internetType: locationDetails.internet_type,
            quietnessLevel: locationDetails.quietness_level,
            coffee: locationDetails.coffee,
            checkIn: locationDetails.check_in_time_start,
            checkOut: locationDetails.check_out_time_end,
        };
        navigate(`/booking-confirmation/${locationDetails.location_id}/${slot.slot_id}`, { state: { slotDetails: slot, locationData, occupancyType } });
    };

    return (
        <Card sx={{
            width: '100%',
            mt: 1,
            mb: 0,
            boxShadow: 'rgba(0, 0, 0, 0.1) 1px 2px 10px',
            maxHeight: '50vh',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                width: '5px'
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: '10px'
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: 'transparent'
            }
        }}>
            <Box paddingTop={2} paddingBottom={2}>
                {slots.map((slot, index) => {
                    const monthYear = getMonthLabel(slot.start_date);
                    let displayMonthLabel = null;
                    if (monthYear !== lastMonthYear) {
                        displayMonthLabel = monthYear;
                        lastMonthYear = monthYear;
                    }
                    const chips = [];
                    if (occupancyType === 'individual') {
                        if (slot.individual_with_accomp === 1 && slot.ind_w_partner_availability === 1) {
                            chips.push("+1 person allowed");
                        }
                        chips.push(slot.allow_sharing === 1 ? "shared bedroom" : "private bedroom");
                    } else if (occupancyType === 'family' || occupancyType === 'team_integration') {
                        chips.push("100% for you");
                    }
                    return (
                        <React.Fragment key={slot.slot_id}>
                            {displayMonthLabel && (
                                <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                                    <Typography variant="subtitle1" fontSize={10}>{displayMonthLabel}</Typography>
                                </Box>
                            )}
                            <Box
                                onClick={() => handleSlotClick(slot)}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    mb: 1,
                                    ml: 1,
                                    mr: 1,
                                    paddingY: 1,
                                    border: 1,
                                    borderColor: theme.palette.action.hover,
                                    borderRadius: "20px",
                                    '&:hover': { backgroundColor: theme.palette.action.hover, cursor: 'pointer' }
                                }}>
                                <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingY: '0' }}>
                                    {/* Date range and day count */}
                                    <Box sx={{ display: 'flex', mb: 1 }}>
                                        <DateRangeOutlinedIcon sx={{ color: 'grey', mr: 1, fontSize: 'large' }} />
                                        <Typography variant="subtitle2">
                                            {formatDate(slot.start_date, false, false)} — {formatDate(slot.end_date, false, false)}
                                        </Typography>
                                        <Typography variant="caption" sx={{ color: 'grey', ml: 1 }}>
                                            ({calculateDaysDifference(slot.start_date, slot.end_date)} days)
                                        </Typography>
                                    </Box>
                                    {/* Chips */}
                                    <Box sx={{ display: 'flex', gap: '4px' }}>
                                        {chips.map((chip, idx) => (
                                            <Chip key={idx} label={chip} size="small" variant="outlined" sx={{ fontSize: '10px', color: theme.palette.primary.main, borderColor: theme.palette.primary.main }} />
                                        ))}
                                    </Box>
                                </CardContent>
                                <ArrowForwardIosIcon sx={{ color: 'grey' }} />
                            </Box>
                        </React.Fragment>
                    );
                })}
            </Box>
            {/* Load More button */}
            {hasMore && (
                <Button
                    width="50%"
                    variant="contained"
                    onClick={() => fetchSlots(currentPage, false)}
                    color="primary"
                    disabled={loading}
                    sx={{
                        position: 'absolute',
                        bottom: '-21px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        borderRadius: '20px',
                        fontSize: '14px',
                        width: '50%'
                    }}
                >
                    {loading ? <CircularProgress size={20} /> : "Load more"}

                </Button>
            )}
        </Card>
    );
};

export default SlotCard;
