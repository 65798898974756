import { useTheme } from '@mui/material/styles';

const SoundIcon = ({ width = '32', height = '28', color }) => {
    const theme = useTheme();
    const iconColor = color || theme.palette.primary.notUsed; // Default to theme color if not provided

    return (
        <svg width={width} height={height} viewBox="0 0 32 28" fill={iconColor} xmlns="http://www.w3.org/2000/svg">
            <path d="M17.6091 18.4218C17.4367 18.2485 17.239 18.0957 17.0061 17.9845C15.903 17.4569 14.5953 17.9319 14.0757 19.0195C13.5524 20.1145 14.014 21.4271 15.1116 21.9499C16.2027 22.4698 17.5182 22.0091 18.0421 20.915V20.9142L19.8056 17.2221L20.5976 19.4615L21.9784 18.9738L20.0074 13.3998L17.6091 18.4218Z" fill={iconColor} />
            <path d="M13.549 7.76592C13.1543 7.45986 12.6927 7.24854 12.185 7.17495C10.5835 6.946 9.09675 8.04639 8.86388 9.65259C8.60434 11.4295 9.98951 13.0036 11.7657 13.0036C13.1966 13.0036 14.4515 11.9514 14.6632 10.4952C14.6637 10.4922 14.6632 10.4894 14.6637 10.4864L14.6646 10.4866L15.7349 2.40117L23.3031 1.6437L22.6139 6.85776C22.227 6.55718 21.7683 6.33965 21.2497 6.26445C19.6583 6.03379 18.1625 7.12954 17.9287 8.74209C17.6691 10.519 19.0543 12.0931 20.8305 12.0931C22.2614 12.0931 23.5162 11.0409 23.728 9.58394C23.7285 9.58101 23.7279 9.57813 23.7284 9.5752L23.7294 9.57539L25.0001 0L14.4357 1.06074L13.549 7.76592Z" fill={iconColor} />
            <path d="M2.16633 25C3.54203 25 4.57603 23.7498 4.3291 22.4058L3.37543 17.1571L7.54951 15.2838L8.06497 18.1433C7.82135 18.1043 7.57139 18.0985 7.32054 18.1443C6.128 18.363 5.33526 19.5046 5.55485 20.7014C5.75305 21.7815 6.69445 22.5024 7.7119 22.5024C9.09072 22.5024 10.1258 21.2585 9.87823 19.9089L8.65267 13.1738L1.72847 16.2959L2.51637 20.6421C2.27471 20.6027 2.02469 20.5947 1.77072 20.6413C0.578763 20.8594 -0.181393 22.0074 0.0375107 23.199C0.231408 24.2569 1.12681 25 2.16633 25Z" fill={iconColor} />
            <path d="M2.19988 11.4313C3.21054 12.1003 4.57354 11.8278 5.24549 10.8134C5.57028 10.3241 5.68477 9.73765 5.56813 9.16255L4.75323 5.15283L6.73357 6.46533L7.54345 5.24365L2.6141 1.97852L3.72151 7.43452C2.808 7.30347 2.02191 7.72134 1.5817 8.38579C0.908338 9.40234 1.19127 10.7636 2.19988 11.4313Z" fill={iconColor} />
        </svg>
    );
};
export default SoundIcon;