import axios from '../axios'; // Import Axios

export function getTravelModeHeaderText(travelMode) {
    switch (travelMode) {
        case 'Car':
            return "Enter your expected car arrival date and time🚗.";
        case 'Plane':
            return "Provide your flight's landing date, time, and flight number✈️.";
        default:
            return "Please select a travel mode☝️ to fill in this section.";
    }
}

export function getDepartureHeaderText(travelMode) {
    switch (travelMode) {
        case 'Car':
            return "Indicate your car departure date and time (please consider check-out hours)🚗.";
        case 'Plane':
            return "Share your flight's departure date, time, and flight number✈️.";
        default:
            return "Please select a travel mode☝️ to fill in this section.";
    }
}

function formatDateToDDMMYYYY(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // +1 since months are 0-indexed
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
}

export function formatDataForBackend(formData, token) {
    const arrivalDateFormatted = formatDateToDDMMYYYY(formData.arrivalDate);
    const departureDateFormatted = formatDateToDDMMYYYY(formData.departureDate);

    const formattedData = {
        token: token,
        arrival: arrivalDateFormatted,
        arrival_time: formData.arrivalTime,
        departure: departureDateFormatted,
        departure_time: formData.departureTime,
        arrival_flight_number: formData.travelMode === "Plane" ? formData.flightNumber : "",
        departure_flight_number: formData.travelMode === "Plane" ? formData.flightNumberDeparture : "",
        adults: formData.adults,
        children: formData.children,
        phone: formData.phone.startsWith('+') ? formData.phone : `+${formData.phone}`,
        travel_how: formData.travelMode,
        from_airport: formData.travelMode === "Plane" ? formData.transportFromTheAirport : "",
        expected_arrival: formData.travelMode === "Plane" ? formData.estimatedArrivalFromAirport : "",
        comments: formData.comments

    };

    return formattedData;
}

export async function submitToDatabase(data, token) {
    const formattedData = formatDataForBackend(data, token);

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PREFIX}/update-workation`, formattedData);
        return response.data;
    } catch (error) {
        console.error("There was an error sending the request: ", error);
        throw error;
    }
}