import React, { useState, useContext, useEffect } from 'react';
import { DataContext } from './DataContext'; // Import DataContext
import axios from '../axios';
import { toast } from 'react-toastify';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CircularProgress, Container, Grid, Card, CardContent, Typography, TextField, Checkbox, FormControlLabel, Button, IconButton, Box, Stack } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EventIcon from '@mui/icons-material/Event';
import BedtimeIcon from '@mui/icons-material/Bedtime';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CoffeeIcon from '@mui/icons-material/Coffee';
import { formatDate, formatTime, calculateDaysDifference } from '../utils/dateAndTime';
import { quietnessDescription } from '../utils/textManipulation';
import OccupancyDescription from './Booking/OccupancyDescription';
import Highlight from '../utils/Highlight';
import DeskIcon from './SVGicons/DeskIcon';
import InternetIcon from './SVGicons/InternetIcon';
import SoundIcon from './SVGicons/SoundIcon';
import { useAuth } from '../contexts/AuthContext'; // Make sure to import useAuth
import { useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link'; // Import Material-UI Link

const SlotBookingConfirmation = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { fetchData } = useContext(DataContext);
    const { locationId } = useParams(); // Get locationId from URL
    const { slotDetails, locationData, occupancyType } = location.state ?? {}; // Fallback to empty object if state is null
    const [email, setEmail] = useState('');
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [managerApprovalUnderstood, setManagerApprovalUnderstood] = useState(false);
    const [loading, setLoading] = useState(false);
    const { user } = useAuth();
    const theme = useTheme();

    useEffect(() => {
        if (!location.state?.slotDetails || !location.state?.locationData) {
            navigate(`/location/${locationId}`);
        }
    }, [location, navigate, locationId]);


    const handleBack = () => {
        navigate(-1);
    };

    const handleBooking = async (e) => {
        e.preventDefault(); // Prevent form submission

        if ((occupancyType !== "team_integration" && termsAccepted && managerApprovalUnderstood) || (occupancyType === "team_integration" && termsAccepted)) {
            setLoading(true);

            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PREFIX}/workations/book`, {
                    locationId: locationData.locationId,
                    slotId: slotDetails.slot_id,
                    occupancyType: occupancyType,
                    approverEmail: occupancyType === "team_integration" ? user.email : email
                });

                const successMessage = response.data.message || 'Your workation booking request has been successfully submitted and is pending approval.';
                toast.success(successMessage);

                fetchData();

                navigate('/home');
            } catch (error) {
                // Attempt to use the error message from the server's response
                const errorMessage = error.response?.data?.error || 'The booking request could not be submitted. Please try again later.';
                toast.error(errorMessage);
                console.error('Error booking workation:', error);
            }

            setLoading(false);
        }
    };
    // Early return if data is not available
    if (!slotDetails || !locationData) {
        return null;
    }
    return (

        <Container maxWidth="lg">
            {/* <IconButton onClick={handleBack} aria-label="back" sx={{ mt: 2, mb: 2 }}>
                <ArrowBackIcon />
            </IconButton> */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                <IconButton onClick={handleBack} aria-label="back">
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h3" m={0}>Booking confirmation</Typography>
            </Box>
            <Grid container spacing={4} >
                {/* Left Column for Location Details and Image */}
                <Grid item xs={12} md={12} lg={8}>
                    <Card sx={{ flexGrow: 1, flexDirection: 'row', maxWidth: '100%' }}>
                        {/* Image Grid  */}
                        <Grid container spacing={2}>
                            <Grid item md={4} lg={4} >
                                <img
                                    src={`${process.env.REACT_APP_PROPERTIES_PICTURES_ROOT_URL}/${locationData.thumbnailUrl}`}
                                    alt="Location thumbnail"
                                    style={{ width: '100%', height: '100%', borderRadius: '15px', objectFit: 'cover' }}
                                />
                            </Grid>
                            <Grid item md={8} lg={8} container >
                                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ padding: 2 }}>
                                    <Stack sx={{ gap: 1 }}>
                                        <Typography variant="h3" mb={1}>{locationData.name}</Typography>
                                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                            <LocationOnIcon sx={{ color: theme.palette.primary.used, fontSize: '20px' }} />
                                            <Typography >
                                                {locationData.address}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                            <DeskIcon width="20" height="22" color={theme.palette.primary.used} />
                                            <Typography >
                                                {locationData.workstations} workstations
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                            <InternetIcon width="20" height="22" color={theme.palette.primary.used} />
                                            <Typography >
                                                {locationData.internetSpeed} Mb/s | {locationData.internetType}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                            <SoundIcon width="20" height="22" color={theme.palette.primary.used} />
                                            <Typography >
                                                {locationData.quietnessLevel}/5 {quietnessDescription(locationData.quietnessLevel)}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                            <CoffeeIcon sx={{ color: theme.palette.primary.used, fontSize: '20px' }} />
                                            <Typography >
                                                Coffee {locationData.coffee}
                                            </Typography>
                                        </Box>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ padding: 2 }}>
                                    <Stack sx={{ gap: 1 }}>
                                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                            <EventIcon sx={{ color: theme.palette.primary.used, fontSize: '20px' }} />
                                            <Typography >
                                                <b>{formatDate(slotDetails.start_date)}</b> check in {formatTime(locationData.checkIn)}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                            <BedtimeIcon sx={{ color: theme.palette.primary.used, fontSize: '20px' }} />
                                            <Typography >
                                                Stay for {calculateDaysDifference(slotDetails.start_date, slotDetails.end_date)} nights
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                            <EventIcon sx={{ color: theme.palette.primary.used, fontSize: '20px' }} />
                                            <Typography >
                                                <b>{formatDate(slotDetails.end_date)}</b> check out {formatTime(locationData.checkOut)}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                            <LaptopMacIcon sx={{ color: theme.palette.primary.used, fontSize: '20px' }} />
                                            <Typography >
                                                <Highlight type={occupancyType}>{occupancyType.split('_').join(' ')}</Highlight> workation
                                            </Typography>
                                        </Box>
                                        <OccupancyDescription
                                            occupancyType={occupancyType}
                                            withPartnerAvailability={slotDetails.ind_w_partner_availability}
                                            indWithAccomp={slotDetails.individual_with_accomp}
                                            allowSharing={slotDetails.allow_sharing}
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                {/* Right Column for Booking Details */}
                <Grid item xs={12} md={12} lg={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h3">Booking</Typography>
                            <Typography>{occupancyType === "team_integration" ? "Please ensure to accept the terms below, before proceeding" : "Provide your line manager email address that will approve your request."}</Typography>
                            <form onSubmit={handleBooking}>

                                {occupancyType !== "team_integration" && (
                                    <TextField
                                        fullWidth
                                        required
                                        type="email"
                                        label="Line manager email"
                                        variant="outlined"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        margin="normal"
                                        sx={{ mb: 2 }}
                                    />
                                )}
                                <FormControlLabel
                                    control={<Checkbox checked={termsAccepted} onChange={(e) => setTermsAccepted(e.target.checked)} required />}
                                    label={
                                        <>
                                            I accept the
                                            {' '}
                                            <Link component={RouterLink} to="/termsandconditions" style={{ textDecoration: 'underline' }}>
                                                terms and conditions
                                            </Link>
                                        </>
                                    }
                                />
                                {occupancyType !== "team_integration" && (
                                    <FormControlLabel
                                        control={<Checkbox checked={managerApprovalUnderstood} onChange={(e) => setManagerApprovalUnderstood(e.target.checked)} required />}
                                        label="I understand the workation is not booked until my line manager approves it"
                                    />
                                )}
                                <Box paddingTop={5}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        //onClick={handleBooking}
                                        disabled={loading}
                                    >
                                        {loading ? <CircularProgress size={24} /> : 'Send booking request'}
                                    </Button>
                                </Box>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

        </Container >
    );
};

export default SlotBookingConfirmation;
