import React, { useState, useContext } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    CircularProgress,
    MenuItem,
    Typography
} from '@mui/material';
import axios from '../../axios';
import { toast } from 'react-toastify';
import { useAuth } from '../../contexts/AuthContext';
import { AdminDataContext } from '../AdminDataContext';

const AddVoucherDialog = ({ open, handleClose, refreshVouchers }) => {
    const { user } = useAuth();
    const { data } = useContext(AdminDataContext);
    const { preferences } = data;

    const [inviteType, setInviteType] = useState('');
    const [inviteeEmail, setInviteeEmail] = useState('');
    const [localLoading, setLoading] = useState(false);

    const handleAddVoucher = async () => {
        try {
            setLoading(true);
            const body = {
                invite_type: inviteType,
                invitee_email: inviteeEmail,
                valid_days: 7,
                send_email: !preferences.skip_emails_when_admin_modifies,
            };

            if (user.role === 'admin') {
                body.company_id = data.selectedCompanyId;
            }

            await axios.post(
                `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PREFIX}/special-invites/create`,
                body
            );

            toast.success('Voucher added successfully.');
            refreshVouchers(); // Refresh vouchers after successful addition
            setLoading(false);
            handleClose(); // Close dialog
        } catch (error) {
            setLoading(false);
            toast.error(
                error.response?.data?.message || 'Failed to add voucher. Please try again.'
            );
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth>
            <DialogTitle>Add Voucher</DialogTitle>
            <DialogContent>
                <Typography mb={2}>
                    Select the workation type and provide the email address of the empoyee
                </Typography>
                <TextField
                    fullWidth
                    select
                    label="Workation type"
                    value={inviteType}
                    onChange={(e) => setInviteType(e.target.value)}
                >

                    {preferences.invite_individuals === 1 && <MenuItem value="individual">Individual</MenuItem>}
                    {preferences.family === 1 && <MenuItem value="family">Family</MenuItem>}
                    {preferences.team_integration === 1 && <MenuItem value="team_integration">Team Integration</MenuItem>}
                    {/* <MenuItem value="individual">Individual</MenuItem>
                    <MenuItem value="family">Family</MenuItem>
                    <MenuItem value="team_integration">Team Integration</MenuItem> */}
                </TextField>
                <TextField
                    fullWidth
                    type="email"
                    label="Invitee email"
                    value={inviteeEmail}
                    onChange={(e) => setInviteeEmail(e.target.value)}
                    margin="normal"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" variant="outlined">
                    Cancel
                </Button>
                <Button onClick={handleAddVoucher} color="primary" variant="contained" disabled={localLoading}>
                    {localLoading ? <CircularProgress size={24} /> : 'Add'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddVoucherDialog;
