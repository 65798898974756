// WorkationGoerCard.js
import React, { useState, useContext } from 'react';
import {
    CardContent,
    Stack,
    Typography,
    IconButton,
    Chip,
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
    Button,
    Box,
    TextField,
    Menu,
    MenuItem, ListItemIcon, ListItemText,
    CircularProgress,
    Divider
} from '@mui/material';
import { AdminDataContext } from '../AdminDataContext'; // Adjust the path as needed
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CheckIcon from '@mui/icons-material/Check';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PeopleIcon from '@mui/icons-material/People';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PhoneIcon from '@mui/icons-material/Phone';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LaptopIcon from '@mui/icons-material/Laptop';
import { toast } from 'react-toastify';
import axios from '../../axios';
import { useTheme } from '@mui/material/styles';
import Highlight from '../../utils/Highlight';
import { useCopyToClipboard } from 'react-use';
import ConfirmationDialog from '../SlotSettings/ConfirmationDialog';
import { ArrowForward } from '@mui/icons-material';


const WorkationGoerCard = ({ workationGoer, formatDate, formatTime, onSlotUpdate, countdown, resetCountdown, }) => {
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const { data } = useContext(AdminDataContext);
    const { preferences } = data;
    const openMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const [open, setOpen] = useState(false);
    const [showDetails, setShowDetails] = useState(false);

    const handleToggleDetails = () => {
        setShowDetails(!showDetails);
    };

    const [copySuccess, setCopySuccess] = useState(false);
    const [_, copyToClipboard] = useCopyToClipboard();

    const handleCopyToClipboard = () => {
        const url = `${process.env.REACT_APP_URL}/travel-form/${workationGoer.user.workation.request_token}`;
        copyToClipboard(url);
        setCopySuccess(true);
        toast.success('Travel form URL copied to clipboard');
        closeMenu();
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const closeMenu = () => {
        setAnchorEl(null);
    };

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [currentAction, setCurrentAction] = useState('');

    const handleAction = (actionType) => {
        setCurrentAction(actionType);
        if (['reject', 'cancel'].includes(actionType)) {
            setOpenConfirmDialog(true);
        } else {
            executeAction(actionType);
        }
    };

    const executeAction = async (actionType) => {
        setLoading(true);
        closeMenu();
        const urlMap = {
            'approve': `/requests/${workationGoer.user.workation.request_id}/force-approve`,
            'reject': `/requests/${workationGoer.user.workation.request_id}/force-reject`,
            'cancel': `/requests/${workationGoer.user.workation.request_id}/force-cancel-team-integration`
        };

        const requestBody = {
            skipEmails: preferences.skip_emails_when_admin_modifies
        };

        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PREFIX}${urlMap[actionType]}`, requestBody);
            toast.success(response.data.message || 'Action successful.');
            onSlotUpdate();
            resetCountdown();
        } catch (error) {
            toast.error(error.response?.data?.error || 'Action failed. Please try again later.');
        } finally {
            setLoading(false);
            setOpenConfirmDialog(false);
        }
    };
    const chipStyle = {
        pending: {
            backgroundColor: theme.palette.status.pending,
            color: theme.palette.status.pendingText,
            position: 'absolute',
            top: -15,
            right: 15
        },
        approved: {
            backgroundColor: theme.palette.status.approved,
            color: theme.palette.status.approvedText,
            position: 'absolute',
            top: -15,
            right: 15
        }
    };

    return (
        <>
            <Chip
                icon={<InfoOutlinedIcon color='#FFFFFF' />}
                label={workationGoer.user.workation.status.charAt(0).toUpperCase() + workationGoer.user.workation.status.slice(1)}
                onClick={handleClickOpen}
                sx={{
                    ...(workationGoer.user.workation.status === 'pending' ? chipStyle.pending : chipStyle.approved),
                    '&:hover': {
                        ...(workationGoer.user.workation.status === 'pending' ? chipStyle.pending : chipStyle.approved),
                    }
                }}
            />
            <CardContent>
                <Stack sx={{ gap: 0.6 }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
                        <div>
                            <Typography variant="subtitle1" gutterBottom sx={{ mb: -0.5 }} fontSize={19}>
                                {workationGoer.user.first_name}{workationGoer.user.last_name ? " " + workationGoer.user.last_name : ""}
                            </Typography>
                            <Typography variant="subtitle2" gutterBottom>
                                {workationGoer.user.email}
                            </Typography>
                        </div>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <IconButton onClick={openMenu}>
                                {loading ? <CircularProgress size={24} /> : <MoreHorizIcon />}
                            </IconButton>
                        </Box>
                    </Stack>


                    <Stack direction="row" sx={{ width: '100%', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            <LaptopIcon sx={{ color: theme.palette.primary.used, fontSize: '20px' }} />
                            <Typography variant="subtitle2" gutterBottom>
                                <Highlight type={workationGoer.user.workation.occupancy_type}> {workationGoer.user.workation.occupancy_type} </Highlight>
                            </Typography>
                        </Box>
                        {showDetails && (
                            <>
                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                    <PeopleIcon sx={{ color: theme.palette.primary.used, fontSize: '20px' }} />
                                    <Typography variant="body1" gutterBottom fontSize={11} m={0}>
                                        Adults
                                    </Typography>
                                    <Typography variant="body1" gutterBottom fontSize={20}>
                                        <b>{workationGoer.user.travel.adults}</b>
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                    <Typography variant="body1" gutterBottom fontSize={11} m={0}>
                                        Children
                                    </Typography>
                                    <Typography variant="body1" gutterBottom fontSize={20}>
                                        <b>{workationGoer.user.travel.children}</b>
                                    </Typography>
                                </Box>
                            </>
                        )}
                        {!showDetails && (
                            <Box sx={{ display: 'flex', gap: 0, alignItems: 'center' }}>
                                <Typography variant="body2" sx={{ color: workationGoer.user.travel.travel_how ? 'inherit' : 'lightgray' }}>
                                    {workationGoer.user.travel.travel_how ? "Travel Info" : "No travel Info yet"}
                                </Typography>
                                <IconButton onClick={handleToggleDetails} disabled={!workationGoer.user.travel.travel_how}>
                                    <ArrowForwardIcon />
                                </IconButton>
                            </Box>
                        )}
                    </Stack>
                    {showDetails && (
                        <>
                            {workationGoer.user.travel.travel_how === 'Plane' ? (
                                <>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                            <FlightLandIcon sx={{ color: theme.palette.primary.used, fontSize: '20px' }} />
                                            <Typography variant="body1" gutterBottom fontSize={11} m={0}>
                                                Arrival
                                            </Typography>
                                        </Box>
                                        {workationGoer.user.travel.arrival && (
                                            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>

                                                <Typography variant="body1" gutterBottom>
                                                    <b>{formatDate(workationGoer.user.travel.arrival)}</b> ({formatTime(workationGoer.user.travel.arrival_time)})
                                                </Typography>
                                                <Typography variant="body1" gutterBottom fontSize={10}>
                                                    {workationGoer.user.travel.arrival_flight_number}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>

                                            <FiberManualRecordIcon sx={{ color: theme.palette.primary.used, fontSize: '20px' }} />
                                            <Typography variant="body1" gutterBottom fontSize={11} m={0}>
                                                Commuting by
                                            </Typography>
                                        </Box>
                                        <Typography variant="body1" gutterBottom>
                                            {workationGoer.user.travel.from_airport}
                                        </Typography>

                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>

                                            <FlightTakeoffIcon sx={{ color: theme.palette.primary.used, fontSize: '20px' }} />
                                            <Typography variant="body1" gutterBottom fontSize={11} m={0}>
                                                Departure
                                            </Typography>
                                        </Box>
                                        {workationGoer.user.travel.departure && (

                                            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>

                                                <Typography variant="body1" gutterBottom>
                                                    <b>{formatDate(workationGoer.user.travel.departure)}</b> ({formatTime(workationGoer.user.travel.departure_time)})
                                                </Typography>
                                                <Typography variant="body1" gutterBottom fontSize={10}>
                                                    {workationGoer.user.travel.departure_flight_number}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                            <DirectionsCarIcon sx={{ color: theme.palette.primary.used, fontSize: '20px' }} />
                                            <Typography variant="body1" gutterBottom fontSize={11} m={0}>
                                                Arrival
                                            </Typography>
                                        </Box>
                                        {workationGoer.user.travel.arrival && (
                                            <Typography variant="body1" gutterBottom>
                                                <b>{formatDate(workationGoer.user.travel.arrival)}</b> ({formatTime(workationGoer.user.travel.arrival_time)})
                                            </Typography>
                                        )}
                                    </Box>

                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>

                                            <DirectionsCarIcon sx={{ color: theme.palette.primary.used, fontSize: '20px' }} />
                                            <Typography variant="body1" gutterBottom fontSize={11} m={0}>
                                                Departure
                                            </Typography>
                                        </Box>
                                        {workationGoer.user.travel.departure && (
                                            <Typography variant="body1" gutterBottom>
                                                <b>{formatDate(workationGoer.user.travel.departure)}</b> ({formatTime(workationGoer.user.travel.departure_time)})
                                            </Typography>
                                        )}
                                    </Box>
                                </>
                            )}

                            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>

                                    <PhoneIcon sx={{ color: theme.palette.primary.used, fontSize: '20px' }} />
                                    <Typography variant="body1" gutterBottom fontSize={11} m={0}>
                                        Phone
                                    </Typography>
                                </Box>
                                <Typography variant="body1" gutterBottom>
                                    {workationGoer.user.travel.phone}
                                </Typography>
                            </Box>
                            <TextField
                                label="Comments"
                                multiline
                                disabled="true"
                                fullWidth
                                defaultValue={((workationGoer.user.travel.expected_arrival ? 'Expected arrival from the airport: ' + workationGoer.user.travel.expected_arrival + '\n' : '') + (workationGoer.user.travel.comments ? workationGoer.user.travel.comments : ''))}
                                variant="outlined"
                                sx={{ mt: 2 }}
                            />
                        </>
                    )}


                </Stack>
            </CardContent>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={closeMenu}
            >
                {workationGoer.user.workation.status === 'pending' && (
                    <MenuItem onClick={() => handleAction('approve')}>
                        <ListItemIcon>
                            <CheckIcon sx={{ color: theme.palette.status.approvedText }} />
                        </ListItemIcon>
                        <ListItemText>Force approve</ListItemText>

                    </MenuItem>
                )}
                {workationGoer.user.workation.occupancy_type !== 'team_integration' && (

                    <MenuItem onClick={() => handleAction('reject')}>
                        <ListItemIcon>
                            <DeleteOutlineIcon sx={{ color: theme.palette.status.rejectedText }} />
                        </ListItemIcon>
                        <ListItemText>Force remove</ListItemText>
                    </MenuItem>
                )}
                {workationGoer.user.workation.occupancy_type === 'team_integration' && (
                    <MenuItem onClick={() => handleAction('cancel')}>
                        <ListItemIcon>
                            <DeleteOutlineIcon sx={{ color: theme.palette.status.rejectedText }} />
                        </ListItemIcon>
                        <ListItemText>Force cancel</ListItemText>
                    </MenuItem>
                )}
                <Divider />
                <MenuItem onClick={handleCopyToClipboard}>
                    <ListItemIcon>
                        <ContentCopyIcon />
                    </ListItemIcon>
                    <ListItemText>Form URL</ListItemText>
                </MenuItem>
            </Menu>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Request status</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        The workation request is {workationGoer.user.workation.status} by {workationGoer.user.workation.approver}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained">Close</Button>
                </DialogActions>
            </Dialog>
            <ConfirmationDialog
                open={openConfirmDialog}
                onClose={() => setOpenConfirmDialog(false)}
                onConfirm={() => executeAction(currentAction)}
                loading={loading}
            />
        </>

    );
};

export default WorkationGoerCard;
