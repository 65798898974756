import React, { useState } from 'react';
import { Box, Card, CardContent, TextField, Button, Typography, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { sendPasswordResetLink } from '../utils/auth'; // Import the login function
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link'; // Import Material-UI Link
import { useLoading } from './LoadingContext'; // Import useLoading


const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { setLoading } = useLoading(); // Get setLoading from context

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');

        try {
            setLoading(true);
            await sendPasswordResetLink(email);
            setLoading(false);
            toast.success('A password reset link has been sent to your email, if it exists in our system.');
            navigate('/login');
        } catch (err) {
            setLoading(false);
            setError(err.response?.data?.error || 'An unexpected error occurred.');
        }
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="80vh">
            <Card sx={{ minWidth: 275, maxWidth: 360 }}>
                <CardContent>
                    <Typography variant="h2" component="div" gutterBottom sx={{ textAlign: 'center' }}>
                        Deskou
                    </Typography>
                    <Typography variant="h1" component="div" gutterBottom>
                        Forgot Password?
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        Send a password reset request by providing your email address.
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            required
                            id="email"
                            label="Email Address"
                            type="email"
                            fullWidth
                            margin="normal"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <Box mt={2}>
                            <Button type="submit" variant="contained" color="primary" fullWidth>
                                Remind Password
                            </Button>
                        </Box>
                    </form>
                    {error && (
                        <Alert severity="error" sx={{ mt: 2 }}>
                            {error}
                        </Alert>
                    )}
                    <Box mt={2} display="flex" justifyContent="center" alignItems="center">
                        <Typography variant="body2">Go back to&nbsp;</Typography>
                        <Link to="/login" component={RouterLink}>
                            <Typography variant="body2">Log in.</Typography>
                        </Link>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};

export default ForgotPassword;
