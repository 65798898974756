import React, { useState, useContext, useEffect } from 'react';
import axios from '../../axios'; // Ensure axios is imported
import { DataContext } from '../DataContext';
import { Card, CardContent, Typography, Button, CircularProgress, Select, MenuItem, FormControl, Box, InputLabel } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';
import WorkationInfo from '../HomePageComponents/WorkationInfo';
import SlotCard from './SlotCard';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Highlight from '../../utils/Highlight';


const CheckSlots = ({ locationId, locationDetails }) => {
    const [occupancyType, setOccupancyType] = useState('');
    const [selectedOccupancyType, setSelectedOccupancyType] = useState('');
    const [slots, setSlots] = useState([]);
    const [expanded, setExpanded] = useState(true);
    const [loading, setLoadingStatus] = useState(false);
    const theme = useTheme();
    const { data } = useContext(DataContext);
    const { preferences, invites_to_use, workationsInfo } = data;
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [searchPerformed, setSearchPerformed] = useState(false);

    const hasPendingInvite = (inviteType) => {
        return invites_to_use.some(invite => invite.invite_type === inviteType && invite.status === 'pending');
    };

    const canShowIndividualMenuItem = () => {
        return locationDetails.individual && preferences.individual &&
            (!preferences.invite_individuals || (preferences.invite_individuals && hasPendingInvite('individual')));
    };

    const canShowFamilyMenuItem = () => {
        return locationDetails.family && preferences.family && hasPendingInvite('family');
    };

    const canShowTeamIntegrationMenuItem = () => {
        return locationDetails.team_integration && preferences.team_integration && hasPendingInvite('team_integration');
    };

    const handleOccupancyChange = (event) => {
        setSelectedOccupancyType(event.target.value); // Use a separate state to store the selected value
    };

    const getDropdownStyle = () => {
        switch (occupancyType) {
            case 'individual': return { backgroundColor: theme.palette.individual.main, color: theme.palette.individual.contrastText };
            case 'family': return { backgroundColor: theme.palette.family.main, color: theme.palette.family.contrastText };
            case 'team integration': return { backgroundColor: theme.palette.team_integration.main, color: theme.palette.team_integration.contrastText };
            default: return {};
        }
    };

    const beforeDate = `${workationsInfo.nextYearLimitApplies ? workationsInfo.currentYear + 1 : workationsInfo.currentYear}-12-31`;

    const fetchSlots = async (page = 1, isInitial = false) => {
        if (isInitial) {
            setSlots([]);
            setHasMore(true);
            setSearchPerformed(false);
        }

        setLoadingStatus(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PREFIX}/slots-for-users/${locationId}?occupancy_type=${selectedOccupancyType}&page=${page}&per_page=10&before_date=${beforeDate}`);
            setSlots(prevSlots => isInitial ? [...response.data.slots.data] : [...prevSlots, ...response.data.slots.data]);
            setCurrentPage(page + 1);
            setHasMore(response.data.slots.current_page < response.data.slots.last_page);
            setSearchPerformed(true);
            setOccupancyType(selectedOccupancyType);
            if (response.data.slots.data.length > 0) { setExpanded(false) };
        } catch (error) {
            console.error('Error fetching slots:', error);
            setHasMore(false);
        }
        setLoadingStatus(false);
    };

    return (
        <Box sx={{ position: 'sticky', top: 64 }}>
            <Card >
                <CardContent sx={{ padding: '0' }}>
                    <Accordion
                        expanded={expanded}
                        onChange={() => setExpanded(!expanded)}
                        sx={{ width: '100%', mt: 0, mb: 0, boxShadow: 'none' }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"

                        >
                            <Typography variant='h3'> Available slots</Typography>
                        </AccordionSummary>
                        <AccordionDetails >
                            Select the wokration type and push the search button.
                            <Box sx={{ display: 'flex', width: '100%', mt: 3, mb: 1, gap: 1 }}>
                                <FormControl variant="outlined" fullWidth sx={{ flex: 1 }}>
                                    <InputLabel id="occupancy-type-label">Workation type</InputLabel>
                                    <Select
                                        labelId="occupancy-type-label"
                                        value={selectedOccupancyType}
                                        onChange={handleOccupancyChange}
                                        label="Workation type"
                                    >
                                        {canShowIndividualMenuItem() && <MenuItem value="individual"><Highlight type="individual">Individual</Highlight></MenuItem>}
                                        {canShowFamilyMenuItem() && <MenuItem value="family"><Highlight type="family">Family</Highlight></MenuItem>}
                                        {canShowTeamIntegrationMenuItem() && <MenuItem value="team_integration"><Highlight type="team_integration">Team integration</Highlight></MenuItem>}
                                    </Select>
                                </FormControl>
                                <Button
                                    id="checkSlotsButton"
                                    variant="contained"
                                    color="primary"
                                    disabled={!selectedOccupancyType || loading}
                                    onClick={() => fetchSlots(1, true)}
                                    sx={{
                                        borderTopLeftRadius: '8px',
                                        borderBottomLeftRadius: '8px',
                                        borderTopRightRadius: '20px',
                                        borderBottomRightRadius: '20px',
                                        paddingX: '0'
                                    }}
                                >
                                    {loading ? <CircularProgress size={20} /> : <SearchIcon />}
                                </Button>

                            </Box>
                            <WorkationInfo />
                        </AccordionDetails>
                    </Accordion>
                </CardContent>
                {slots && slots.length > 0 && (
                    <SlotCard
                        slots={slots}
                        locationDetails={locationDetails}
                        loading={loading}
                        fetchSlots={fetchSlots}
                        hasMore={hasMore}
                        currentPage={currentPage}
                        occupancyType={occupancyType}
                    />
                )}
                {searchPerformed && !loading && slots.length === 0 && (
                    <Box sx={{ m: 2, gap: 2, display: 'flex', justifyContent: 'center' }}>
                        <EditCalendarIcon sx={{ color: theme.palette.primary.main }} />
                        <Typography textAlign="center">No slots available</Typography>
                    </Box>
                )}
            </Card>
        </Box>
    );
};

export default CheckSlots;
