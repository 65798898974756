//AIzaSyDvzL6MI1OPJorwiBxbFFI8I4P-dtbLWmo
import React, { useEffect, useRef, useState } from 'react';

const MapComponent = ({ lat, lng, isDarkMode }) => {
    const mapRef = useRef(null);
    const scriptId = "google-maps-script";
    //const google = window.google
    const [map, setMap] = useState(null); // State to hold the map instance

    const nightStyles = [
        { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
        { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
        { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
        {
            featureType: "administrative.locality",
            elementType: "labels.text.fill",
            stylers: [{ color: "#d59563" }],
        },
        {
            featureType: "poi",
            elementType: "labels.text.fill",
            stylers: [{ color: "#d59563" }],
        },
        {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [{ color: "#263c3f" }],
        },
        {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [{ color: "#6b9a76" }],
        },
        {
            featureType: "road",
            elementType: "geometry",
            stylers: [{ color: "#38414e" }],
        },
        {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#212a37" }],
        },
        {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#9ca5b3" }],
        },
        {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [{ color: "#746855" }],
        },
        {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [{ color: "#1f2835" }],
        },
        {
            featureType: "road.highway",
            elementType: "labels.text.fill",
            stylers: [{ color: "#f3d19c" }],
        },
        {
            featureType: "transit",
            elementType: "geometry",
            stylers: [{ color: "#2f3948" }],
        },
        {
            featureType: "transit.station",
            elementType: "labels.text.fill",
            stylers: [{ color: "#d59563" }],
        },
        {
            featureType: "water",
            elementType: "geometry",
            stylers: [{ color: "#17263c" }],
        },
        {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [{ color: "#515c6d" }],
        },
        {
            featureType: "water",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#17263c" }],
        },
    ];

    const standardStyles = [
        // ... your standard mode styles ...
    ];
    useEffect(() => {
        // Define the initMap function
        window.initMap = () => {
            const newMap = new window.google.maps.Map(mapRef.current, {
                center: { lat, lng },
                zoom: 15,
                styles: isDarkMode ? nightStyles : standardStyles,
                disableDefaultUI: false,
                zoomControl: true,
                streetViewControl: false,
                mapTypeControl: false,
            });
            setMap(newMap); // Store the map instance

            const svgMarker = {
                path: 'M125 410 c-56 -72 -111 -176 -120 -224 -7 -36 11 -83 49 -124 76 -85 223 -67 270 31 28 60 29 88 6 150 -19 51 -122 205 -148 221 -6 3 -32 -21 -57 -54z m110 -175 c35 -34 33 -78 -4 -116 -35 -35 -71 -37 -105 -7 -40 35 -43 78 -11 116 34 41 84 44 120 7z',
                fillColor: '#4CC5E0',
                fillOpacity: 1.0,
                scale: 0.06, // Adjust the size of the SVG path
                strokeColor: '#4CC5E0',
                strokeWeight: 1,
                //anchor: new google.maps.Point(200, 450)
            };

            new window.google.maps.Marker({
                position: { lat, lng },
                map: newMap,
                //icon: svgMarker,
            });
        };

        // Load the Google Maps script
        if (!document.getElementById(scriptId)) {
            const script = document.createElement('script');
            script.id = scriptId;
            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDvzL6MI1OPJorwiBxbFFI8I4P-dtbLWmo&callback=initMap`;
            script.async = true;
            script.defer = true;
            document.head.appendChild(script);
        } else if (window.google && window.google.maps) {
            // If the script is already loaded, just initialize the map
            window.initMap();
        }

        // Clean up on unmount
        return () => {
            window.initMap = null;
        };
    }, [lat, lng]);

    useEffect(() => {
        if (map) {
            map.setOptions({
                styles: isDarkMode ? nightStyles : standardStyles,
            });
        }
    }, [isDarkMode, map]);

    return (
        <div ref={mapRef} style={{ height: '100%', width: '100%' }} />
    );
};

export default MapComponent;