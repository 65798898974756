// src/axios.js
import axios from 'axios';

const baseDomain = `${process.env.REACT_APP_API_URL}`; // Your Laravel server domain
const baseURL = `${baseDomain}`;


const axiosInstance = axios.create({
    baseURL,
    withCredentials: true, // Required for Sanctum CSRF cookie
});

axiosInstance.interceptors.request.use(config => {
    const token = localStorage.getItem('token'); // Assuming you store the token in local storage
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
});

axiosInstance.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            window.dispatchEvent(new CustomEvent('unauthorized'));
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;