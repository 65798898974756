import React from 'react';
import { Grid, Typography, Box, Card, CardContent } from '@mui/material';
import DeskIcon from '../SVGicons/DeskIcon';
import SoundIcon from '../SVGicons/SoundIcon';
import InternetIcon from '../SVGicons/InternetIcon';
import EventIcon from '@mui/icons-material/Event';
import { formatTime } from '../../utils/dateAndTime';
import { quietnessDescription } from '../../utils/textManipulation';

const PropertyEquipment = ({ amenities }) => (
    <Box>
        <Typography variant='h4'>Property equipment</Typography>
        <ul style={{ paddingLeft: "20px" }}>
            {amenities.map((amenity, index) => (
                <li key={index}>
                    <Typography>{amenity.name}</Typography>
                </li>
            ))}
        </ul>
    </Box>
);

const EntertainmentAttractions = ({ amenities }) => (
    <Box>
        <Typography variant='h4'>Entertainment & atractions</Typography>
        <ul style={{ paddingLeft: "20px" }}>
            {amenities.map((amenity, index) => (
                <li key={index}>
                    <Typography>{amenity.name}</Typography>
                </li>
            ))}
        </ul>
    </Box>
);

const PropertyFacilities = ({ amenities }) => (
    <Box>
        <Typography variant='h4'>Property facilities</Typography>
        <ul style={{ paddingLeft: "20px" }}>
            {amenities.map((amenity, index) => (
                <li key={index}>
                    <Typography>{amenity.name}</Typography>
                </li>
            ))}
        </ul>
    </Box>
);

const OtherLocationDetails = ({ locationDetails }) => {
    const propertyEquipment = locationDetails.amenities.filter(a => a.category === 'property_equipment');
    const entertainmentAttractions = locationDetails.amenities.filter(a => a.category === 'entertainment_attractions');
    const propertyFacilities = locationDetails.amenities.filter(a => a.category === 'property_facilities');

    return (
        <>
            <Grid item container lg={12} gap={4} direction={'column'} >
                <Grid item container gap={3}>
                    <Grid item lg={12} sx={{ flexGrow: '1' }}>
                        <Box>
                            <Typography variant='h3'>
                                About the property
                            </Typography>

                            <Typography >
                                {locationDetails.property_description}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box gap={5} sx={{ display: 'flex', justifyContent: 'left' }}>
                            <Box>
                                <Typography variant='h4'>
                                    Property type
                                </Typography>
                                <Typography>
                                    {locationDetails.property_type}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant='h4'>
                                    Bedrooms
                                </Typography>
                                <Typography>
                                    {locationDetails.bedrooms_qty}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant='h4'>
                                    Bathrooms
                                </Typography>
                                <Typography>
                                    {locationDetails.bathrooms_qty}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box>
                            <Typography variant='h4'>
                                Rooms
                            </Typography>
                            <Typography>
                                {locationDetails.rooms_description}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid item container spacing={3}>
                        <Grid item xs={12} sm={6} md={4} >
                            <PropertyEquipment amenities={propertyEquipment} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} >
                            <EntertainmentAttractions amenities={entertainmentAttractions} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} >
                            <PropertyFacilities amenities={propertyFacilities} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item >
                    <Typography variant='h3' mb={3}>
                        Work stuff
                    </Typography>

                    <Grid item container spacing={5} >
                        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex' }}>
                            <Card sx={{ flexGrow: 1 }}>
                                <CardContent sx={{ padding: "20px" }}>
                                    <Box mb="17px" sx={{ display: 'flex' }}>
                                        <DeskIcon />
                                        <Typography ml='20px' textAlign={'center'}>{locationDetails.workstations} workstations</Typography>
                                    </Box>
                                    <Typography fontSize="12px" color='#999999' sx={{ lineHeight: '14.4px' }}>
                                        {locationDetails.work_space} {locationDetails.work_equipment}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex' }}>
                            <Card sx={{ flexGrow: 1 }}>
                                <CardContent sx={{ padding: "20px" }}>
                                    <Box mb="17px" sx={{ display: 'flex' }}>
                                        <SoundIcon />
                                        <Typography ml='20px' textAlign={'match-parent'}>{locationDetails.quietness_level}/5 {quietnessDescription(locationDetails.quietness_level)}</Typography>
                                    </Box>
                                    <Typography fontSize="12px" color='#999999' sx={{ lineHeight: '14.4px' }}>

                                        {locationDetails.quietness_and_area}
                                    </Typography>

                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex' }}>
                            <Card sx={{ flexGrow: 1 }}>
                                <CardContent sx={{ padding: "20px" }}>
                                    <Box mb="17px" sx={{ display: 'flex' }}>
                                        <InternetIcon />
                                        <Typography ml='20px' textAlign={'center'}>{locationDetails.internet_speed} Mb/s {locationDetails.internet_type} connection</Typography>
                                    </Box>
                                    <Typography fontSize="12px" color='#999999' sx={{ lineHeight: '14.4px' }}>

                                        {locationDetails.internet_information}
                                    </Typography>

                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Box gap={5} sx={{ display: 'flex', justifyContent: 'left' }}>
                        <Box xs={12} sm={6} md={4}>
                            <Typography variant='h4'>
                                What equipment you need to take with you
                            </Typography>
                            <Typography>
                                {locationDetails.what_to_prepare_for_work}
                            </Typography>
                        </Box>
                        <Box xs={12} sm={6} md={4}>
                            <Typography variant='h4'>
                                Coffee
                            </Typography>
                            <Typography>
                                {locationDetails.coffee}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>

                <Grid item>
                    <Typography variant='h3' mb={3}>
                        Parking & public transport
                    </Typography>
                    <Grid item container spacing={2} >
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography variant='h4'>
                                Parking
                            </Typography>
                            <Typography>
                                {locationDetails.parking}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography variant='h4'>
                                Public transport
                            </Typography>
                            <Typography>
                                {locationDetails.public_transport_to_property}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography variant='h3' mb={3}>
                        Rules of the property
                    </Typography>
                    <Grid item container spacing={2} >
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography variant='h4'>
                                Quiet hours
                            </Typography>
                            <Typography>
                                From {formatTime(locationDetails.quiet_hour_start)} to {formatTime(locationDetails.quiet_hour_end)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography variant='h4'>
                                Smoking
                            </Typography>
                            <Typography>
                                {locationDetails.smoking}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography variant='h4'>
                                Animals
                            </Typography>
                            <Typography>
                                {locationDetails.animals_allowed}
                            </Typography>
                        </Grid>
                        {locationDetails.important_information && (
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography variant='h4'>
                                    Important information
                                </Typography>
                                <Typography>
                                    {locationDetails.important_information}
                                </Typography>
                            </Grid>
                        )}
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography variant='h4'>
                                Check-in & check-out details
                            </Typography>
                            <Typography fontSize="14px" color='#999999' sx={{ mb: 0.5 }} >
                                Check-in
                            </Typography>
                            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                <EventIcon sx={{ color: "grey" }} />
                                <Typography variant='h4' mb={0}>
                                    From {formatTime(locationDetails.check_in_time_start)} {locationDetails.check_in_time_end && (
                                        "To " + formatTime(locationDetails.check_in_time_end))}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography fontSize="14px" color='#999999' sx={{ mb: 0.5 }} >
                                Check-out
                            </Typography>
                            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                <EventIcon sx={{ color: "grey" }} />
                                <Typography variant='h4' mb={0}>
                                    {locationDetails.check_out_time_start && (
                                        "From" + formatTime(locationDetails.check_out_time_start))}Until {formatTime(locationDetails.check_out_time_end)}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography fontSize="14px" color='#999999' sx={{ mb: 0.5 }} >
                                Check-in Instructions
                            </Typography>
                            <Typography>
                                {locationDetails.check_in_instruction}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
        </>
    );
};

export default OtherLocationDetails;
