
import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'; // Adjust the path as necessary

const ProtectedRoute = () => {
    const { user, handleUnauthorized } = useAuth(); // Destructure handleUnauthorized

    useEffect(() => {
        const handleUnauthorizedAccess = () => {
            handleUnauthorized();
        };

        window.addEventListener('unauthorized', handleUnauthorizedAccess);

        return () => {
            window.removeEventListener('unauthorized', handleUnauthorizedAccess);
        };
    }, [handleUnauthorized]); // Ensure handleUnauthorized is included in the dependency array

    return Boolean(user) ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;