import * as React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = () => (
    //<Box mt={8} py={2} bgcolor="background.paper" textAlign="center">
    <Box mt={2} py={2} bgcolor="background.paper" textAlign="center">
        <Typography variant="body2" color="textSecondary">
            © Deskou Workation. All rights reserved.
        </Typography>
    </Box>
);

export default Footer;