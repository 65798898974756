import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
    palette: {
        primary: {
            main: '#4CC5E0',
            card: '#fff',
            contrastText: '#fff',
            notUsed: 'grey',
            used: 'lightgrey',
        },
        status: {
            approved: '#C1E7D9', // Pastel green background for approved
            approvedText: '#0A6640', // Much darker green text for approved
            rejected: '#FFD1D1', // Pastel red background for rejected
            rejectedText: '#8C1515', // Darker red text for rejected
            pending: '#F9DCB9', // Pastel yellow-orange background for pending
            pendingText: '#C46E0D', // Much darker orange text for pending        
        },
        text: {
            primary: 'rgb(54, 54, 54)', // This will set the default text color
        },
        individual: {
            main: '#A7C7E7', // Light Pastel Blue
            contrastText: '#34568B', // Stronger Blue
        },
        family: {
            main: '#BEE1E6', // Soft Green
            contrastText: '#317873', // Deep Green
        },
        team_integration: {
            main: '#C3B1E1', // Light Lavender
            contrastText: '#6A4C93', // Darker Purple
        },
    },
    typography: {
        fontFamily: [
            'Lato',
            '-apple-system',
            'system-ui',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            'Ubuntu',
            'sans-serif',
        ].join(','),
        h1: {
            fontSize: '48px',
            fontWeight: 900,
            margin: '20px 0',
            WebkitFontSmoothing: 'antialiased',
        },
        h2: {
            fontSize: '30px',
            color: '#4CC5E0', // Primary color
            fontWeight: 900,
            WebkitFontSmoothing: 'antialiased',
        },
        h3: {
            fontSize: '20px',
            fontWeight: 700,
            WebkitFontSmoothing: 'antialiased',
            marginBottom: '5px',
        },
        h4: {
            fontSize: '15px',
            fontWeight: 700,
            //WebkitFontSmoothing: 'antialiased',
            marginBottom: '5px',
        },
        subtitle1: {
            fontSize: '15px',
            fontWeight: 900,
            WebkitFontSmoothing: 'antialiased',
        },
        subtitle2: {
            fontSize: '13px',
            fontWeight: 400,
            WebkitFontSmoothing: 'antialiased',
        },
        body1: {
            fontSize: '14px',
            fontWeight: 500,
            color: 'rgb(54, 54, 54)',
        },
        bodyGrey: {
            fontFamily: 'Lato', // Ensure Lato is loaded in your project
            fontSize: '12px',
            fontWeight: 400,
            letterSpacing: '0em', // Added based on Figma
            textAlign: 'left', // Added based on Figma
            color: '#999999',
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    overflowY: 'scroll', // Always show vertical scrollbar
                    // Scrollbar styles
                    '&::-webkit-scrollbar': {
                        width: '5px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'rgba(0, 0, 0, 0.6)', // Or use theme.palette.primary.main
                        borderRadius: '10px',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: 'transparent',
                    },
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: 'black',
                    textDecoration: 'underline'
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    position: 'relative',
                    overflow: 'visible',
                    borderRadius: '20px',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '50px',
                    fontSize: '20px',
                    fontWeight: 700,
                    padding: '13px 35px',
                    boxShadow: 'none',
                    textTransform: 'none',
                    lineHeight: 1,
                },
                containedPrimary: {
                    backgroundColor: '#4CC5E0',
                    '&:hover': {
                        backgroundColor: '#4CC5E0', // Darken the color slightly on hover
                        boxShadow: 'none',
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: 'lightgrey', // Replace with the color you want
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    // Add other style overrides here
                },
                input: {
                    // Add input style overrides here (if any)
                },
                notchedOutline: {
                    borderRadius: '8px', // Change this value to your preferred border radius
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    //boxShadow: 'rgba(0, 0, 0, 0.15) 2px 2px 10px',
                    boxShadow: 'none',
                    borderRadius: '20px',
                    border: '1px solid rgb(54, 54, 54)', // Setting border color here
                    maxWidth: '360px',
                },
            },
        },
    },
});

const darkTheme = createTheme({
    ...lightTheme.palette,

    palette: {
        mode: 'dark',
        primary: {
            main: '#1C7C8C',
            card: '#121212',
            contrastText: '#fff',
            notUsed: '#dcdada',
            used: 'grey',
        },
        status: {
            approved: '#C1E7D9', // Pastel green background for approved
            approvedText: '#0A6640', // Much darker green text for approved
            rejected: '#FFD1D1', // Pastel red background for rejected
            rejectedText: '#8C1515', // Darker red text for rejected
            pending: '#F9DCB9', // Pastel yellow-orange background for pending
            pendingText: '#C46E0D', // Much darker orange text for pending        
        },

        text: {
            primary: 'rgba(255, 255, 255, 0.7)', // This will set the default text color
        },
        individual: {
            main: '#507C9E', // Darker Blue
            contrastText: '#E0F1FA', // Lighter Blue
        },
        family: {
            main: '#6A9A8C', // Darker Green
            contrastText: '#D4EDE8', // Lighter Green
        },
        team_integration: {
            main: '#8E7CB1', // Darker Lavender
            contrastText: '#EDE5F2', // Lighter Purple
        },
    },
    typography: {
        ...lightTheme.typography,
        h2: {
            fontSize: '30px',
            color: '#1C7C8C', // Primary color
            fontWeight: 900,
            WebkitFontSmoothing: 'antialiased',
        },
        h3: {
            fontSize: '20px',
            color: 'rgba(255, 255, 255, 0.7)',
            fontWeight: 700,
            WebkitFontSmoothing: 'antialiased',
            marginBottom: '5px',
        },
        body1: {
            fontSize: '14px',
            fontWeight: 500,
            color: 'rgba(255, 255, 255, 0.7)',
        },
    },

    components: {
        ...lightTheme.components,
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '50px',
                    fontSize: '20px',
                    fontWeight: 700,
                    padding: '13px 35px',
                    boxShadow: 'none',
                    textTransform: 'none',
                    lineHeight: 1,
                },
                containedPrimary: {
                    backgroundColor: '#1C7C8C',
                    '&:hover': {
                        backgroundColor: '#1C7C8C', // Darken the color slightly on hover
                        boxShadow: 'none',
                    },
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: 'rgba(255, 255, 255, 0.7)',
                    textDecoration: 'underline',
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    color: '#ffffff',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    },
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    color: '#ffffff',
                },
                secondary: {
                    color: 'rgba(255, 255, 255, 0.7)',
                },
            },
        },
        // MuiAccordionSummary: {
        //     styleOverrides: {
        //         root: {
        //             backgroundColor: '#121212', // Custom background color for Accordion Summary
        //             // Add any other styles you want to customize
        //         },
        //     },
        // },
        // MuiAccordionDetails: {
        //     styleOverrides: {
        //         root: {
        //             backgroundColor: '#121212', // Custom background color for Accordion Details
        //             // Add any other styles you want to customize
        //         },
        //     },
        // },
    },
});

export { lightTheme, darkTheme };
