import React, { useState, useContext } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, FormControlLabel, Switch, Box, CircularProgress, Stack, Select, MenuItem, InputLabel, FormControl, Tooltip, Typography } from '@mui/material';
import axios from '../../axios';
import { toast } from 'react-toastify';
import { AdminDataContext } from '../AdminDataContext';
import { useAuth } from '../../contexts/AuthContext';
import Highlight from '../../utils/Highlight';

import { formatDate } from '../../utils/dateAndTime';


const AddEmployeeDialog = ({ open, onClose, slot, onSlotUpdate, resetCountdown }) => {
    const { data } = useContext(AdminDataContext);
    const { preferences } = data;
    const { user } = useAuth();

    const [occupancyType, setOccupancyType] = useState('');
    const [approverEmail, setApproverEmail] = useState('');
    const [requestorEmail, setRequestorEmail] = useState('');
    const [approvalRequired, setApprovalRequired] = useState(false);
    const [loading, setLoading] = useState(false);

    const canToggleApproval = preferences.skip_emails_when_admin_modifies !== 1;

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [error, setError] = useState('');

    const validateAndSave = async () => {
        if (!requestorEmail || (approvalRequired && !approverEmail) || !occupancyType) {
            toast.error('Please fill all required fields.');
            return;
        }

        setLoading(true);

        const payload = {
            locationId: slot.location_id,
            slotId: slot.slot_id,
            occupancyType,
            approverEmail: approvalRequired ? approverEmail : user.email,
            requestorEmail,
            approvalRequired: approvalRequired ? "1" : "0",
            skipEmails: preferences.skip_emails_when_admin_modifies,
            ...firstName && { firstName },
            ...lastName && { lastName }
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PREFIX}/workations/book`, payload);
            toast.success('Employee added successfully!');
            onClose();
            onSlotUpdate();
            resetCountdown();
        } catch (error) {
            const errorMsg = error.response?.data?.error || 'Please try again later.';
            if (errorMsg.includes('First name and last name are required for new users')) {
                setError('That is a new Deskou user, please also provide first and last name of the user.');
            } else {
                toast.error(errorMsg);
            }
        } finally {
            setLoading(false);
        }
    };


    return (
        <Dialog open={open} onClose={(e) => { e.stopPropagation(); onClose(); }} fullWidth maxWidth="sm">
            <DialogTitle>Add employee</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <Typography><b>{`${formatDate(slot.start_date)} — ${formatDate(slot.end_date)}`}</b></Typography>

                    <Typography>Provide the details of the employee you would like to sign up for slot.</Typography>
                    <TextField
                        fullWidth
                        label="Employee email"
                        type="email"
                        value={requestorEmail}
                        onChange={(e) => setRequestorEmail(e.target.value)}
                        onFocus={(e) => {
                            e.stopPropagation();
                            e.preventDefault(); // You might experiment with this line to see its effects
                        }}
                        onBlur={(e) => {
                            e.stopPropagation();
                        }}
                        required
                    />
                    {error && (
                        <Stack spacing={2}>
                            <Typography color="error">{error}</Typography>
                            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                <TextField
                                    fullWidth
                                    label="First Name"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    onFocus={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault(); // You might experiment with this line to see its effects
                                    }}
                                    onBlur={(e) => {
                                        e.stopPropagation();
                                    }}
                                    required
                                />
                                <TextField
                                    fullWidth
                                    label="Last Name"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    onFocus={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault(); // You might experiment with this line to see its effects
                                    }}
                                    onBlur={(e) => {
                                        e.stopPropagation();
                                    }}
                                    required
                                />
                            </Box>
                        </Stack>
                    )}
                    <Stack direction="column" spacing={1}>
                        <Tooltip title={canToggleApproval ? "" : "Approvals require email notifications to be enabled in the Company Settings."}>
                            <span>
                                <FormControlLabel
                                    control={<Switch checked={approvalRequired && canToggleApproval} onChange={() => setApprovalRequired(!approvalRequired)} disabled={!canToggleApproval} />}
                                    label="Workation requires approval"
                                />
                            </span>
                        </Tooltip>
                        {approvalRequired && (
                            <TextField
                                fullWidth
                                label="Approver email"
                                type="email"
                                //value={approverEmail}
                                onChange={(e) => setApproverEmail(e.target.value)}
                                onFocus={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault(); // You might experiment with this line to see its effects
                                }}
                                onBlur={(e) => {
                                    e.stopPropagation();
                                }}
                                required={approvalRequired}
                            />
                        )}
                        <Typography>The available workation types are based on the slot and company settings.</Typography>
                        <FormControl fullWidth required
                            onFocus={(e) => {
                                e.stopPropagation();
                                e.preventDefault(); // You might experiment with this line to see its effects
                            }}>
                            <InputLabel id="occupancy-type-label">Workation type</InputLabel>
                            <Select
                                labelId="occupancy-type-label"
                                value={occupancyType}
                                onChange={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setOccupancyType(e.target.value)
                                }}
                                label="Workation type"
                                required
                            >
                                {slot.individual === 1 && preferences.individual === 1 && <MenuItem value="individual"><Highlight type="individual">Individual</Highlight></MenuItem>}
                                {slot.family === 1 && preferences.family === 1 && <MenuItem value="family"><Highlight type="family">Family</Highlight></MenuItem>}
                                {slot.team_integration === 1 && preferences.team_integration === 1 && <MenuItem value="team_integration"><Highlight type="team_integration">Team Integration</Highlight></MenuItem>}
                            </Select>
                        </FormControl>

                    </Stack>

                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={(e) => { e.stopPropagation(); onClose(); }} variant="outlined" color="primary">Cancel</Button>
                <Button onClick={validateAndSave} variant="contained" color="primary" disabled={loading}>
                    {loading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddEmployeeDialog;
