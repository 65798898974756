import React from 'react';
import { Box, Grid, TextField, InputAdornment, FormControlLabel, Checkbox, FormControl, InputLabel, OutlinedInput, Select, MenuItem, FormHelperText, Typography } from '@mui/material';
import FlightLandIcon from '@mui/icons-material/FlightLand';


function Arrival({ watchedTravelMode, getTravelModeHeaderText, register, errors, shouldDisplayCheckbox, userData }) {
    return (
        <section>
            <Box paddingX={2} paddingBottom={2} border={1} borderColor="rgb(54, 54, 54)" borderRadius={5} marginBottom={3}>
                <h2>Arrival to Workation</h2>
                <Grid container spacing={4}>

                    <Grid item xs={12} md={12}>{getTravelModeHeaderText(watchedTravelMode)}</Grid>
                    {(watchedTravelMode === 'Plane' || watchedTravelMode === 'Car') && (
                        <>
                            <Grid item xs={12} md={watchedTravelMode === 'Car' ? 6 : watchedTravelMode === 'Plane' ? 4 : 4}>

                                <TextField
                                    label="Arrival Date"
                                    type="date"
                                    name="arrivalDate"
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    fullWidth
                                    {...register("arrivalDate", { required: "Arrival date is required" })}
                                    error={Boolean(errors.arrivalDate)}
                                    helperText={errors.arrivalDate?.message}
                                />
                            </Grid>
                            <Grid item xs={12} md={watchedTravelMode === 'Car' ? 6 : watchedTravelMode === 'Plane' ? 4 : 4}>

                                <TextField
                                    label="Arrival Time"
                                    type="time"
                                    name="arrivalTime"
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    fullWidth
                                    {...register("arrivalTime", { required: "Arrival time is required" })}
                                    error={Boolean(errors.arrivalTime)}
                                    helperText={errors.arrivalTime?.message}
                                />
                            </Grid>

                        </>
                    )}
                    {(watchedTravelMode === 'Plane') && (
                        <>
                            <Grid item xs={12} md={4}>

                                <TextField
                                    label="Flight Number"
                                    disabled={watchedTravelMode !== "Plane"}
                                    type="text"
                                    name="flightNumber"
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    fullWidth
                                    {...register("flightNumber", { required: "Flight number is required" })}
                                    error={Boolean(errors.flightNumber)}
                                    helperText={errors.flightNumber?.message}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <FlightLandIcon />
                                            </InputAdornment>
                                        ),
                                        inputProps: {
                                            maxLength: 8
                                        }
                                    }}
                                />
                            </Grid>

                            {/* conditional checkbox */}

                            {
                                shouldDisplayCheckbox && (
                                    <Grid item xs={12} md={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    {...register("earlyArrivalCheckbox", { required: "Please acknowledge your early arrival." })}
                                                />
                                            }
                                            label={
                                                <span style={{ color: "grey", fontSize: "11px" }}>
                                                    I arrive before my workation slot and understand I need to arrange my own accommodation until then.
                                                </span>
                                            }
                                        />
                                        {errors.earlyArrivalCheckbox && <FormHelperText error>{errors.earlyArrivalCheckbox.message}</FormHelperText>}
                                    </Grid>
                                )
                            }
                            {/* transport from the airport */}
                            <Grid item xs={12} md={12}>How will you travel from the airport to the workation destination?</Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel shrink={true} htmlFor="transportFromTheAirport">Transport from the airport</InputLabel>
                                    <Select
                                        fullWidth
                                        disabled={watchedTravelMode !== "Plane"}
                                        id="transportFromTheAirport"
                                        defaultValue=""
                                        input={
                                            <OutlinedInput
                                                id="transportFromTheAirport"
                                                label="Transport from the airport"
                                                notched
                                            />
                                        }
                                        {...register("transportFromTheAirport", { required: "Transport from the airport is required" })}
                                    >
                                        {["Rental car", "Public transport", "Taxi", "I do not know yet"].map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {errors.transportFromTheAirport && <FormHelperText error>{errors.transportFromTheAirport.message}</FormHelperText>}
                                </FormControl>
                            </Grid>

                            {/* Estimated arrival from the airport */}
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <InputLabel shrink={true} htmlFor="estimatedArrivalFromAirport">Estimated arrival from the airport</InputLabel>
                                    <Select
                                        fullWidth
                                        id="estimatedArrivalFromAirport"
                                        defaultValue=""
                                        input={
                                            <OutlinedInput
                                                id="estimatedArrivalFromAirport"
                                                label="Estimated arrival from the airport"
                                                notched
                                            />
                                        }
                                        {...register("estimatedArrivalFromAirport", { required: "Estimated arrival from the airport is required" })}
                                    >
                                        {["As soon as I leave the airport", "I will arrive later (details in comments)"].map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {errors.estimatedArrivalFromAirport && <FormHelperText error>{errors.estimatedArrivalFromAirport.message}</FormHelperText>}
                                </FormControl>
                            </Grid>
                        </>
                    )}
                </Grid>

            </Box>
        </section >
    );
}

export default Arrival;