import React, { useContext } from 'react';
import { DataContext } from './DataContext'; // Import DataContext
import { useAuth } from '../contexts/AuthContext'; // Make sure to import useAuth
import { useTheme } from '@mui/material/styles';
import { Card, Container, CardMedia, CardContent, Typography, Grid, Box, Divider, Chip, Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';
import WorkationInfo from './HomePageComponents/WorkationInfo'; // Import useLoading
import TicketCard from './HomePageComponents/TicketCard';
import WorkationCard from './Workation/WorkationCard';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const Home = () => {
    const { data, loading } = useContext(DataContext);
    const { locations, workations, preferences, workationsInfo } = data;
    const { user } = useAuth();
    const theme = useTheme();


    const getCurrentOrNextWorkation = () => {
        const today = new Date();
        // Sort workations by start_date in ascending order
        const sortedWorkations = workations.sort((a, b) => new Date(a.start_date) - new Date(b.start_date));
        const current = sortedWorkations.find(w => new Date(w.start_date) <= today && new Date(w.end_date) >= today);

        if (current) {
            return { workation: current, type: "Current" };
        }

        const nextUpcoming = sortedWorkations.find(w => new Date(w.start_date) > today);
        return nextUpcoming ? { workation: nextUpcoming, type: "Upcoming" } : null;
    };

    const calculateDaysUntil = (expiryDate, today = new Date()) => {
        const timeDiff = new Date(expiryDate) - today; // Difference in milliseconds
        const daysUntil = timeDiff / (1000 * 60 * 60 * 24);

        if (daysUntil < 1) {
            // If less than 1 day, calculate hours instead
            const hoursUntil = Math.ceil(timeDiff / (1000 * 60 * 60));
            return { value: hoursUntil, unit: 'hours' };
        } else {
            // Use floor for days to avoid rounding up too early
            const daysUntilRounded = Math.floor(daysUntil);
            return { value: daysUntilRounded, unit: 'days' };
        }
    };

    const formatSpecialInvites = (invites, today = new Date()) => {
        return invites.map(invite => {
            const { value, unit } = calculateDaysUntil(invite.expiring_time, today);

            return {
                ...invite,
                days_until_expiry: `${value} ${unit}`, // Include unit
                expiry_date: invite.expiring_time.split(" ")[0], // Extract just the date part
            };
        });
    };

    const currentOrNextWorkationInfo = getCurrentOrNextWorkation();
    // Format special invites

    const formattedInvites = data.invites_to_use ? formatSpecialInvites(data.invites_to_use) : [];

    return (
        <Container maxWidth="lg">
            <Grid container paddingY={1} spacing={6}>
                <Grid item container alignContent={'flex-start'}>
                    <Grid item xs={12} sm={12} mb={2}>
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }} mb={3}>
                            <Typography>
                                Hello, {user ? user.email : "Loading..."}
                            </Typography> <Typography fontSize={23}>👋</Typography>
                        </Box>
                        {loading.locations ? (
                            <>
                                <Skeleton variant="text" height={60} width={220} sx={{ borderRadius: "10px" }} />
                            </>
                        ) : (
                            <>
                                <Typography variant="h3">
                                    Your workation vouchers
                                </Typography>
                                <WorkationInfo />
                            </>
                        )}
                    </Grid>
                    {loading.locations ? (
                        <>
                            <Skeleton variant="rectangular" height={80} width={300} sx={{ borderRadius: "10px" }} />
                        </>
                    ) : (
                        <Grid item container spacing={2}>
                            {preferences.individual === 1 && preferences.invite_individuals === 0 && (
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <TicketCard
                                        invite={{ invite_type: 'individual' }} // Dummy invite object for individual workation
                                        isSpecial={false}
                                        workationsInfo={workationsInfo}
                                        preferences={preferences}
                                    />
                                </Grid>
                            )}
                            {formattedInvites.length > 0 &&
                                formattedInvites.map((invite, index) => (
                                    <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                                        <TicketCard
                                            invite={invite}
                                            isSpecial={true}
                                            qty={1}
                                            workationsInfo={workationsInfo}
                                            preferences={preferences}
                                        />
                                    </Grid>
                                ))
                            }
                            {formattedInvites.length === 0 && (preferences.invite_individuals === 1 || preferences.family === 1 || preferences.team_integration === 1) && (
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <TicketCard
                                        invite={{ invite_type: 'special' }}
                                        isSpecial={true}
                                        qty={0}
                                        workationsInfo={workationsInfo}
                                        preferences={preferences}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    )}
                </Grid>
                <Grid item lg={12}>
                    {loading.workations ? (
                        <Grid item xs={12} lg={8}>
                            <Skeleton variant="text" height={60} width={200} sx={{ borderRadius: "10px" }} />
                            <Skeleton variant="rectangular" height={200} sx={{ borderRadius: "20px" }} />
                        </Grid>
                    ) : (
                        <>
                            <Typography variant="h3" mb={2}>
                                {/* Always display a header, but adjust the text based on the condition */}
                                {currentOrNextWorkationInfo ? `${currentOrNextWorkationInfo.type} workation` : "Upcoming workation"}
                            </Typography>
                            {currentOrNextWorkationInfo ? (
                                <WorkationCard workation={currentOrNextWorkationInfo.workation} />
                            ) : (
                                <Typography>You don't have any upcoming workations. Explore the options below.</Typography>
                            )}
                        </>
                    )}
                </Grid>

                <Grid item container>
                    <Grid item mb={2}>
                        {loading.locations ? (
                            <>
                                <Skeleton variant="text" height={60} width={220} sx={{ borderRadius: "10px" }} />
                            </>
                        ) : (
                            <>
                                <Typography variant="h3" id="availableWorkationLocations">Available workation locations</Typography>
                            </>
                        )}
                    </Grid>
                    <Grid item container columnSpacing={2}>
                        {loading.locations ? (
                            [1, 2, 3].map((n) => (
                                <Grid item xs={12} sm={6} md={4} lg={4} mb={3} key={n}>
                                    <Skeleton variant="rectangular" height={260} sx={{ borderRadius: "20px" }} />
                                </Grid>
                            ))
                        ) : locations.length === 0 ? (
                            // Display message when there are no locations
                            <Grid item>
                                <Typography>
                                    You don't have any Workation locations available.
                                </Typography>
                            </Grid>
                        ) : (
                            locations.map(location => (
                                <Grid item xs={12} sm={6} md={4} lg={4} mb={3} key={location.location_id}>
                                    <Link to={`/location/${location.location_id}`} style={{ textDecoration: 'none' }}>
                                        <Card >
                                            <CardMedia
                                                component="img"
                                                height="130"
                                                width="10"
                                                image={`${process.env.REACT_APP_PROPERTIES_PICTURES_ROOT_URL}/${location.thumbnail}`}
                                                alt={location.location_name}
                                            />
                                            <CardContent>
                                                <Typography display="flex" justifyContent="space-between">
                                                    <Typography gutterBottom variant="subtitle1">
                                                        {location.location_name}
                                                    </Typography>
                                                    <Typography gutterBottom variant="subtitle2" display="flex" alignItems="center">
                                                        <LocationOnIcon color="primary" />{location.property_address}
                                                    </Typography>
                                                </Typography>
                                                <Divider />
                                                <Typography gutterBottom variant="body1">
                                                    Available for:
                                                </Typography>
                                                <Box display='flex' sx={{ gap: 0.5 }}>
                                                    {location.individual === 1 && <Chip label="Individual" style={{ backgroundColor: theme.palette.individual.main, color: theme.palette.individual.contrastText }} />}
                                                    {location.family === 1 && <Chip label="Family" style={{ backgroundColor: theme.palette.family.main, color: theme.palette.family.contrastText }} />}
                                                    {location.team_integration === 1 && <Chip label="Team Integration" style={{ backgroundColor: theme.palette.team_integration.main, color: theme.palette.team_integration.contrastText }} />}
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Link>
                                </Grid>
                            ))
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Container >
    );
};

export default Home;
