import React, { useState } from 'react';
import { Container, Box, Tabs, Tab, IconButton, Stack } from '@mui/material';
import { ArrowBack as ArrowBackIcon, FilterList as FilterListIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import FilterDialog from './Vouchers/FilterDialog';
import VoucherRequests from './Vouchers/VoucherRequests'; // Import your VoucherRequests sub-component
import Vouchers from './Vouchers/Vouchers'; // Import your Vouchers sub-component
import { useTheme } from '@mui/material/styles';


const VouchersMain = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const [currentPage, setCurrentPage] = useState(1);

    const [value, setValue] = useState(0);
    const [filterOpen, setFilterOpen] = useState(false);
    const [filterSet, setFilterSet] = useState(false);
    const [filters, setFilters] = useState({});

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setCurrentPage(1);
    };

    const handleBack = () => {
        navigate(-1);
    };

    const handleOpenFilter = () => {
        setFilterOpen(true);
    };

    const handleCloseFilter = () => {
        setFilterOpen(false);
    };

    const handleApplyFilter = (filterValues) => {
        setFilters(filterValues);
        setCurrentPage(1);  // Reset the page count to 1
        setFilterOpen(false);
    };

    return (
        <Container maxWidth="lg" sx={{ mt: '20px' }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0 }}>
                    <IconButton onClick={handleBack} aria-label="back">
                        <ArrowBackIcon />
                    </IconButton>
                    {/* <Typography variant="h3">Vouchers</Typography> */}
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="voucher tabs"
                        sx={{
                            '.MuiTab-root': { // Apply styles directly to the Tab components
                                fontWeight: theme.typography.h3.fontWeight,
                                fontSize: theme.typography.h3.fontSize,
                                letterSpacing: theme.typography.h3.letterSpacing,
                                fontFamily: theme.typography.h3.fontFamily,
                                textTransform: 'none', // Disable uppercase text transform
                                color: theme.palette.text.primary // Use primary text color from theme
                            }
                        }}
                    >                    <Tab label="Voucher Requests" />
                        <Tab label="Vouchers" />
                    </Tabs>

                </Box>
                <IconButton onClick={handleOpenFilter} color={filterSet ? 'primary' : 'default'}>
                    <FilterListIcon />
                </IconButton>
            </Stack>
            {value === 0 && <VoucherRequests filters={filters} currentPage={currentPage} setCurrentPage={setCurrentPage} />}
            {value === 1 && <Vouchers filters={filters} currentPage={currentPage} setCurrentPage={setCurrentPage} />}


            <FilterDialog open={filterOpen} onClose={handleCloseFilter} onFilter={handleApplyFilter} setFilterSet={setFilterSet} tabOpened={value} />

        </Container>
    );
};

export default VouchersMain;
