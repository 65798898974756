import React from 'react';
import { Dialog, Button, ImageList, ImageListItem, ImageListItemBar, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';


const PicturesDialog = ({ locationDetails, handleCloseGallery, open }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleCloseGallery}
            sx={{
                paddingTop: 2,
                paddingBottom: 2,
                paddingLeft: theme => ({
                    xs: theme.spacing(0), // For extra-small screens (mobile)
                    sm: theme.spacing(2), // For small screens and up
                }),
                paddingRight: theme => ({
                    xs: theme.spacing(0), // For extra-small screens (mobile)
                    sm: theme.spacing(2), // For small screens and up
                })
            }}                >
            <Button
                startIcon={<CloseIcon />}
                onClick={handleCloseGallery}
                disableRipple
                sx={{
                    position: 'absolute', // Position the button absolutely
                    top: theme.spacing(0),
                    right: theme.spacing(0),
                    color: theme.palette.text.primary,
                    borderColor: theme.palette.background.paper,
                    backgroundColor: theme.palette.background.paper,
                    fontSize: '11px',
                    zIndex: 1,
                    fontWeight: 500,
                    textTransform: 'none',
                    padding: '8px 18px',
                    '&:hover': {
                        backgroundColor: theme.palette.background.paper, // Darken the color slightly on hover
                        borderColor: theme.palette.background.paper,
                    },
                }}
            >
                Close
            </Button>

            <ImageList variant="masonry" cols={isMobile ? 1 : isTablet ? 2 : 3} gap={8} sx={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }} // Add padding here
            >
                {locationDetails.pictures.map((picture, index) => (
                    <ImageListItem key={picture.file_path} >
                        <img
                            src={`${process.env.REACT_APP_PROPERTIES_PICTURES_ROOT_URL}/${picture.file_path}`}
                            alt={`Image ${index}`}
                            loading="lazy"
                            style={{ borderRadius: '8px' }} // Apply border radius here
                        />
                        {picture.description && (
                            <ImageListItemBar
                                //title={`Image ${index + 1}`} // Title, can be customized
                                position="below"
                                subtitle={`${picture.description}`} // Subtitle or description
                            />
                        )}
                    </ImageListItem>

                ))}
            </ImageList>
        </Dialog>
    );
};

export default PicturesDialog;
