import React, { useState, useEffect } from 'react';
import { Card, CardContent, TextField, useTheme, Button, Typography, Box, Dialog, DialogTitle, DialogContent, DialogActions, Grid, CardActionArea, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { login } from '../utils/auth'; // Import the login function
import { useLoading } from './LoadingContext'; // Import useLoading
import Alert from '@mui/material/Alert'; // Import for displaying error messages
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link'; // Import Material-UI Link
import { toast } from 'react-toastify';
import axios from '../axios';

const LoginForm = () => {
    const theme = useTheme();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { user, setUser } = useAuth(); // Assuming setUser is used to update the user in AuthContext
    const navigate = useNavigate();
    const { loading, setLoading } = useLoading(); // Get setLoading from context
    const [error, setError] = useState(''); // State to hold error message
    const [companies, setCompanies] = useState([]);
    const [selectedCompanyId, setSelectedCompanyId] = useState('');
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        if (user && !user.company) {
            fetchCompanies(); // Fetch companies if user doesn't have a company ID
        } else if (user) {
            navigate('/home'); // Redirect to home if already logged in and company ID is assigned
        }
    }, [user, navigate]);

    const fetchCompanies = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PREFIX}/user/get-companies-for-domain`);
            setCompanies(response.data.companies);
            setLoading(false);
            setOpenDialog(true);
        } catch (error) {
            setLoading(false);
            setError(error.message);
        }
    };

    const handleCompanySelect = (companyId) => {
        setSelectedCompanyId(companyId);
    };

    const handleCompanyConfirm = async () => {
        try {

            setLoading(true);
            await axios.put(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PREFIX}/user/update-company-id`, { company_id: selectedCompanyId });

            // Find the selected company from the list of companies
            const selectedCompany = companies.find(company => company.company_id === selectedCompanyId);
            if (!selectedCompany) {
                throw new Error("Selected company not found.");
            }

            setUser({ ...user, company: selectedCompany }); // Update user context with selected company

            setLoading(false);
            setOpenDialog(false);
            toast.success("You've been successfully assigned to " + selectedCompany.company_name + " entity. Happy workation!⛱️");
            navigate('/home'); // Redirect to home page after updating company
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        const result = await login(email, password);
        setLoading(false);

        if (!result.success) {
            setError(result.message);
            return;
        }

        setUser(result.data);

        // If the user doesn't have a company ID, open the dialog to select a company
        if (!result.data.company) {
            fetchCompanies();
        } else {
            // Redirect to '/admin-dashboard' if the role is 'admin' or 'coordinator', else redirect to '/home'
            const redirectPath = (result.data.role === 'admin' || result.data.role === 'coordinator') ? '/admin-dashboard' : '/home';
            navigate(redirectPath);
        }
    };


    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="80vh">
            <Card sx={{ minWidth: 275, maxWidth: 360 }}>
                <CardContent>
                    <Typography variant="h2" component="div" gutterBottom sx={{ textAlign: 'center' }}>
                        Deskou
                    </Typography>
                    <Typography variant="h1" component="div" gutterBottom sx={{ textAlign: 'center' }}>
                        Welcome!
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        Log in to the Deskou Workation App.
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            required
                            id="username"
                            type="email"
                            label="Email Address"
                            name="username"
                            fullWidth
                            margin="normal"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            required
                            id="password"
                            label="Password"
                            type="password"
                            name="password"
                            fullWidth
                            margin="normal"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                            <Link to="/forgotpassword" component={RouterLink}>
                                <Typography variant="body2">Forgot password?</Typography>
                            </Link>
                        </Box>

                        <Box mt={2}>
                            <Button type="submit" variant="contained" color="primary" fullWidth>
                                Log in
                            </Button>
                        </Box>
                    </form>
                    {error && (
                        <Alert severity="error" style={{ marginTop: '10px' }}>
                            {error}
                        </Alert>
                    )}
                    <Box mt={2} display="flex" justifyContent="center" alignItems="center">
                        <Typography variant="body2">Don't have an account?&nbsp;</Typography>
                        <Link to="/register" component={RouterLink}>
                            <Typography variant="body2">Sign up.</Typography>
                        </Link>
                    </Box>
                </CardContent>
            </Card>
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                sx={{ '& .MuiDialog-paper': { maxWidth: '360px' } }} // This targets the Dialog's paper component directly
                PaperProps={{ sx: { maxWidth: '360px', margin: 'auto' } }} // Sets maximum width and auto margins for centering
            >                <DialogTitle>Select company site</DialogTitle>

                <DialogContent>

                    <Grid container spacing={2} paddingY={2}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography> Almost there!🥳 Before we start, please select the entity of the company you belong to.</Typography>
                        </Grid>
                        {companies.map(company => (
                            <Grid item key={company.company_id} xs={12} sm={12} md={12}>
                                <Card
                                    variant="outlined"
                                    onClick={() => handleCompanySelect(company.company_id)}
                                    sx={{
                                        cursor: 'pointer',
                                        border: 1,
                                        borderColor: theme.palette.action.hover,
                                        backgroundColor: selectedCompanyId === company.company_id ? 'rgba(76, 197, 224, 0.5)' : 'inherit', // Super light blue color
                                        transition: 'none', // No transition
                                        userSelect: 'none', // Disable text selection and default highlight
                                        '&:hover': {
                                            backgroundColor: 'rgba(76, 197, 224, 0.5)', // Change color on hover
                                        },
                                    }}
                                >
                                    <CardActionArea sx={{ pointerEvents: 'none' }}>
                                        <CardContent>
                                            <Typography variant="h4">{company.company_name}</Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', gap: '10px' }}>
                    <Button variant='outlined' onClick={() => setOpenDialog(false)}>
                        Cancel
                    </Button>
                    <Button variant='contained' onClick={handleCompanyConfirm} color="primary" disabled={!selectedCompanyId || loading} sx={{ minWidth: 145 }}>
                        {loading ? <CircularProgress size={20} color="inherit" /> : 'Confirm'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default LoginForm;
