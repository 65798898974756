import React from 'react';
import { useLoading } from './LoadingContext';
import CircularProgress from '@mui/material/CircularProgress';

const GlobalSpinner = () => {
    const { loading } = useLoading();

    if (!loading) return null;

    return (
        <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <CircularProgress />
        </div>
    );
};

export default GlobalSpinner;
