import React, { useState } from 'react';
import { Card, CardContent, TextField, Button, Typography, Box, Alert } from '@mui/material';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import { useLoading } from './LoadingContext'; // Import useLoading


const PasswordResetForm = () => {
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const { token } = useParams();
    const { setLoading } = useLoading(); // Get setLoading from context

    // Parse the query string for the email
    const { email } = queryString.parse(location.search);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (password !== passwordConfirmation) {
            setError("Passwords do not match.");
            return;
        }

        try {
            setLoading(true);
            const response = await axios.post(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PREFIX}/reset-password`, {
                token,
                email,
                password,
                password_confirmation: passwordConfirmation
            });
            setLoading(false);
            toast.success('Your password has been changed. You can now log in.');
            // Handle the response here. For example, show a success message or redirect.
            navigate('/login'); // Redirect to login page after successful password reset
        } catch (err) {
            setLoading(false);
            setError(err.response?.data?.error || 'An unexpected error occurred.');
        }
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="80vh">
            <Card sx={{ minWidth: 275, maxWidth: 360 }}>
                <CardContent>
                    <Typography variant="h2" component="div" gutterBottom sx={{ textAlign: 'center' }}>
                        Deskou
                    </Typography>
                    <Typography variant="h1" component="div" gutterBottom sx={{ textAlign: 'center' }}>
                        Reset Password
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        Enter your new password below.
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            required
                            label="New Password"
                            type="password"
                            fullWidth
                            margin="normal"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <TextField
                            required
                            label="Confirm New Password"
                            type="password"
                            fullWidth
                            margin="normal"
                            value={passwordConfirmation}
                            onChange={(e) => setPasswordConfirmation(e.target.value)}
                        />
                        <Box mt={2}>
                            <Button type="submit" variant="contained" color="primary" fullWidth>
                                Reset Password
                            </Button>
                        </Box>
                    </form>
                    {error && (
                        <Alert severity="error" sx={{ mt: 2 }}>
                            {error}
                        </Alert>
                    )}
                </CardContent>
            </Card>
        </Box>
    );
};

export default PasswordResetForm;
