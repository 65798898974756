import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Container, Toolbar, Typography, IconButton, Menu, MenuItem, Box, useTheme, useMediaQuery, Slide, useScrollTrigger, Divider, ListItemIcon, ListItemText } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { logout } from '../utils/auth';
import { useLoading } from './LoadingContext';
import DarkModeIcon from '@mui/icons-material/DarkMode'; // Moon icon
import WbSunnyIcon from '@mui/icons-material/WbSunny'; // Sun icon

function HideOnScroll(props) {
    const { children } = props;
    const trigger = useScrollTrigger();
    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

const Header = ({ toggleTheme, isDarkMode }) => {
    const { user, setUser } = useAuth();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isAuthenticated = Boolean(user);
    const { setLoading } = useLoading();

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMyWorkationsClick = () => {
        handleClose();
        navigate('/my-workations');
    };
    const handleHomeClick = () => {
        handleClose();
        navigate('/');
    };

    const handleCompanySettingsClick = () => {
        handleClose();
        navigate('/company-settings');
    };

    const handleVouchersClick = () => {
        handleClose();
        navigate('/vouchers');
    };

    const handleLogout = async () => {
        setLoading(true);
        setAnchorEl(null);
        await logout();
        setLoading(false);
        setUser(null);
        navigate('/login', { replace: true });
    };

    // Determine the appropriate home link based on user role
    const homeLink = user && (user.role === 'admin' || user.role === 'coordinator') ? '/admin-dashboard' : '/home';

    return (
        <>
            <HideOnScroll>
                <AppBar position="sticky">
                    <Container maxWidth="xl">
                        <Toolbar sx={{ justifyContent: 'space-between', paddingX: isMobile ? 2 : 5 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Link to={homeLink} style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                                    <img src="/DESKOU-SYGNET2.png" alt="Deskou Logo" style={{ height: '30px' }} />
                                    {!isMobile && (
                                        <Typography variant="caption" component="div" sx={{ color: 'white', marginLeft: 1 }}>
                                            Deskou Workation
                                        </Typography>
                                    )}
                                </Link>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <IconButton onClick={toggleTheme} color="inherit">
                                    {isDarkMode ? <DarkModeIcon /> : <WbSunnyIcon />}
                                </IconButton>
                                <Typography variant="caption">
                                    {isDarkMode ? 'dark mode' : 'light mode'}
                                </Typography>
                                {isAuthenticated && (
                                    <>
                                        <IconButton color="inherit" onClick={handleMenu}>
                                            <PersonIcon />
                                        </IconButton>
                                        <Menu
                                            id="menu-appbar"
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl)}
                                            onClose={handleClose}
                                        >
                                            <MenuItem onClick={handleHomeClick}>Home</MenuItem>
                                            {(user.role === 'admin' || user.role === 'coordinator') && <MenuItem onClick={handleCompanySettingsClick}>Company Settings</MenuItem>}
                                            {(user.role === 'admin' || user.role === 'coordinator') && <MenuItem onClick={handleVouchersClick}>Vouchers</MenuItem>}
                                            {user.role === 'user' && <MenuItem onClick={handleMyWorkationsClick}>My Workations</MenuItem>}
                                            <Divider />
                                            <MenuItem onClick={handleLogout}>
                                                <ListItemIcon>
                                                    <LogoutIcon fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText>Logout</ListItemText>
                                            </MenuItem>
                                        </Menu>
                                    </>
                                )}
                            </Box>
                        </Toolbar>
                    </Container>
                </AppBar>
            </HideOnScroll>
        </>
    );
};

export default Header;
