import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, ButtonGroup, TextField, FormControl, FormControlLabel, Radio, RadioGroup, MenuItem, Select, InputLabel, Typography, Stack } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import CloseIcon from '@mui/icons-material/Close';  // Import Close icon
import Highlight from '../../utils/Highlight';


const FilterDialog = ({ open, onClose, onFilter, setFilterSet, tabOpened }) => {
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [workationType, setWorkationType] = useState('');
    const [voucherSent, setVoucherSent] = useState('');
    const [voucherStatus, setVoucherStatus] = useState('');

    const handleFilter = () => {
        if (fromDate || toDate || workationType || voucherSent || voucherStatus) {
            onFilter({
                from_date: fromDate,
                to_date: toDate,
                occupancy_type: workationType,
                invite_sent: voucherSent === 'yes' ? 1 : voucherSent === 'no' ? 0 : undefined,
                status: voucherStatus ? voucherStatus : undefined
            });
            setFilterSet(true);
            onClose();
        } else if (!fromDate && !toDate && !workationType && !voucherSent && !voucherStatus) {
            onFilter({
                from_date: fromDate,
                to_date: toDate,
                occupancy_type: workationType,
                invite_sent: voucherSent === 'yes' ? 1 : voucherSent === 'no' ? 0 : undefined,
                status: voucherStatus ? voucherStatus : undefined
            });
            setFilterSet(false);
            onClose();
        }
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>Set Filters</DialogTitle>
            <DialogContent>
                <Typography mb={2}>
                    {tabOpened === 0 ? "Select the creation date range and workation type" : "Select the expiration date range and workation type"}
                </Typography>
                <Stack direction="row" spacing={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="From"
                            value={fromDate}
                            onChange={setFromDate}
                            renderInput={(params) => <TextField {...params} />}
                            sx={{ width: '100%' }}
                        />
                        <DatePicker
                            label="To"
                            value={toDate}
                            onChange={setToDate}
                            renderInput={(params) => <TextField {...params} />}
                            sx={{ width: '100%' }}
                        />
                    </LocalizationProvider>
                </Stack>
                <FormControl fullWidth margin="normal">
                    <InputLabel id="workation-type-label">Workation Type</InputLabel>
                    <Select
                        labelId="workation-type-label"
                        value={workationType}
                        label="Workation Type"
                        onChange={(e) => setWorkationType(e.target.value)}
                    >
                        <MenuItem value="individual"><Highlight type='individual'>Individual</Highlight></MenuItem>
                        <MenuItem value="family"><Highlight type='family'>Family</Highlight></MenuItem>
                        <MenuItem value="team_integration"><Highlight type='team_integration'>Team integration</Highlight></MenuItem>
                    </Select>
                </FormControl>

                {tabOpened === 0 && (
                    <FormControl component="fieldset" margin="normal">
                        <Typography>
                            Vouchers sent
                        </Typography>
                        <RadioGroup
                            row
                            name="voucher-sent"
                            value={voucherSent}
                            onChange={(e) => setVoucherSent(e.target.value)}
                        >
                            <FormControlLabel value="yes" control={<Radio />} label={<Highlight type='approved'>Yes</Highlight>} />
                            <FormControlLabel value="no" control={<Radio />} label={<Highlight type='pending'>No</Highlight>} />
                        </RadioGroup>
                    </FormControl>
                )}
                {tabOpened === 1 && (
                    <FormControl component="fieldset" margin="normal">
                        <Typography>
                            Vouchers status
                        </Typography>
                        <RadioGroup
                            row
                            name="voucher-status"
                            value={voucherStatus}
                            onChange={(e) => setVoucherStatus(e.target.value)}
                        >
                            <FormControlLabel value="accepted" control={<Radio />} label={<Highlight type='pending'>used</Highlight>} />
                            <FormControlLabel value="pending" control={<Radio />} label={<Highlight type='approved'>not used</Highlight>} />
                            <FormControlLabel value="expired" control={<Radio />} label={<Highlight type='rejected'>expired</Highlight>} />
                        </RadioGroup>
                    </FormControl>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant='outlined'>Cancel</Button>
                <ButtonGroup sx={{
                    borderRadius: 20,
                    boxShadow: 'none',
                    '& .MuiButton-root': {
                        borderRightColor: 'rgba(255, 255, 255, 0.5)', // Example: light white border for light themes
                        '&:last-child': {
                            borderRight: 'none', // Remove border for the last button
                        }
                    }
                }} variant="contained" color="primary">

                    <Button onClick={handleFilter}>Filter</Button>
                    <Button sx={{ px: 2 }} onClick={() => {
                        setFromDate(null);
                        setToDate(null);
                        setWorkationType('');
                        setVoucherSent('');
                        setVoucherStatus('');
                    }}
                        disabled={!fromDate && !toDate && !workationType && !voucherSent && !voucherStatus}
                    >
                        <CloseIcon />
                    </Button>
                </ButtonGroup>
            </DialogActions>
        </Dialog >
    );
};

export default FilterDialog;
