// CheckSlotsButton.jsx
import React from 'react';
import { Box, Button, Slide, AppBar, useScrollTrigger } from '@mui/material';

function HideOnScroll({ children, appearOn, show }) {
    const trigger = useScrollTrigger();

    // Determine visibility based on scrolling and the 'show' prop
    const shouldShow = appearOn === 'down' ? trigger && show : !trigger && show;

    return (
        <Slide appear={false} direction={appearOn === 'down' ? 'up' : 'down'} in={shouldShow}>
            {children}
        </Slide>
    );
}

const CheckSlotsButton = ({ onClick, show }) => {
    return (
        <HideOnScroll appearOn="down" show={show}>
            <Box sx={{
                position: 'fixed',
                bottom: 0,
                zIndex: 1100,
                width: '100%', // Full width
                backgroundColor: 'background.paper',
                padding: 1,
                display: 'flex',
                justifyContent: 'center',
                borderTop: '1px solid',
                borderTopColor: 'divider'
            }}>
                <Button onClick={onClick} variant="contained" color="primary" sx={{
                    fontSize: '17px',
                    fontWeight: 600,
                    padding: '10px 24px',
                }}>
                    Check slots
                </Button>
            </Box>
        </HideOnScroll>
    );
};

export default CheckSlotsButton;
