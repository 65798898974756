import React, { useState, useEffect } from 'react';
import TravelMode from './TravelFormComponents/TravelMode'
import Arrival from './TravelFormComponents/Arrival'
import Departure from './TravelFormComponents/Departure'
import AttendeeDetails from './TravelFormComponents/AttendeeDetails';
import { useForm } from 'react-hook-form';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography, Box, Stack, Container, Button } from '@mui/material';
import axios from '../../axios'; // Adjust the path as needed
import { useParams } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';



import { getTravelModeHeaderText, getDepartureHeaderText, formatDataForBackend, submitToDatabase } from '../../utils/travelFormUtils';

export default function TravelForm(props) {

    const { handleSubmit: handleHookFormSubmit, setValue, register, watch, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const [phone, setPhone] = React.useState('');
    const watchedArrivalDate = watch("arrivalDate");
    const [hasError, setHasError] = useState(false);
    const [userData, setUserData] = useState({});
    const [loading, setLoading] = useState(true);
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);

    const parsedSlotFrom = userData.slot ? userData.slot.start_date : null;
    const parsedSlotTo = userData.slot ? userData.slot.end_date : null;
    const shouldDisplayCheckbox = watchedArrivalDate && new Date(watchedArrivalDate) < new Date(parsedSlotFrom);

    const watchedTravelMode = watch("travelMode");
    const departureDate = watch("departureDate");
    const displayDepartureCheckbox = departureDate && new Date(departureDate) > new Date(parsedSlotTo);
    const { token } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PREFIX}/retrieve-data?token=${token}`);
                const data = response.data;
                if (data.error) {
                    setHasError(true);
                } else {
                    setUserData(data);
                    setIsFormVisible(true);
                }
                setLoading(false);
            } catch (error) {
                console.error('There was an error!', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []); // Make sure to add any dependencies if needed

    const handlePhoneChange = (value) => {
        setPhone(value);
        setValue("phone", value); // This sets the value for React Hook Form
    };

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        setIsSuccess(false);
        setIsError(false);

        try {
            const response = await submitToDatabase(data, token); // Pass the token here
            if (response.status === 'success') {
                setIsSuccess(true);
                // navigate('/home');
                toast.success('Form submitted successfully');
            } else {
                setIsError(true);
            }
        } catch (error) {
            setIsError(true);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Container style={{ padding: 0 }}>
            {loading ?
                (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        {/* <CircularProgress /> */}
                    </div>
                ) :
                hasError ? (
                    <Typography variant="h6" color="error">
                        Your token is invalid or you're not registered for Workation.
                    </Typography>
                ) : isSuccess ? (
                    // <Typography variant="body1" color="primary">

                    // </Typography>
                    // <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    //     Your submission was successful🥳! You can close the tab.
                    // </Box>
                    <Container>
                        {window.scrollTo(0, 0)}
                        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={10}>
                            <b>Your submission was successful🥳! You can close the tab.</b>
                        </Box>
                    </Container>
                ) : isError ? (
                    <Typography variant="body1" color="error">
                        There was an error submitting the form. Please try again later.
                    </Typography>
                ) : isFormVisible ? (

                    <Container maxWidth="sm"> {/* or sm, lg, xl as per your preference */}

                        <form onSubmit={handleHookFormSubmit(onSubmit)} >
                            <Stack spacing={2}>

                                <TravelMode userData={userData} register={register} errors={errors} />
                                <Arrival watchedTravelMode={watchedTravelMode} getTravelModeHeaderText={getTravelModeHeaderText} register={register} errors={errors} shouldDisplayCheckbox={shouldDisplayCheckbox} userData={userData} />
                                <Departure watchedTravelMode={watchedTravelMode} register={register} errors={errors} displayDepartureCheckbox={displayDepartureCheckbox} getDepartureHeaderText={getDepartureHeaderText} />
                                <AttendeeDetails register={register} errors={errors} handlePhoneChange={handlePhoneChange} phone={phone} />
                                {/*Button*/}
                                <Box display="flex" justifyContent="center" paddingBottom={5}>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        startIcon={isSubmitting ? <CircularProgress size={24} color="inherit" /> : <SendIcon style={{ color: 'white' }} />}
                                        style={{
                                            borderRadius: '50px',
                                            width: '50%', // Adjust as needed
                                            color: 'white' // this will change the text color to white
                                        }}
                                    >
                                        Submit
                                    </Button>
                                </Box>
                            </Stack>
                        </form>
                    </Container >
                ) : (
                    <Typography variant="h1" color="error">
                        Sorry, we're having trouble loading the form right now. Please try again later.
                    </Typography>
                )}
        </Container >
    );
}