import React, { createContext, useContext, useState, useEffect } from 'react';
import { checkAuth, getTokenFromStorage } from '../utils/auth';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const verifyUser = async () => {
            const token = getTokenFromStorage(); // Get the token from storage
            if (token) {
                const authenticatedUser = await checkAuth();

                if (authenticatedUser) {
                    setUser(authenticatedUser); // Set the user object if authenticated
                } else {
                    setUser(null); // Set to null if not authenticated
                }
            } else {
                setUser(null);
            }
            setLoading(false);
        };

        verifyUser();
    }, []);

    const handleUnauthorized = () => {
        setUser(null);
        localStorage.removeItem('token'); // Adjust this as per your token storage strategy
        // Additional cleanup if needed
    };

    return (
        <AuthContext.Provider value={{ user, setUser, loading, handleUnauthorized }}>
            {!loading && children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
