import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useLoading } from './LoadingContext';

const RouteHandler = ({ children }) => {
    const location = useLocation();
    const { setLoading } = useLoading();

    useEffect(() => {
        setLoading(true); // Start loading immediately on route change

        // Fetch data here if needed, then set loading to false
        // For now, we'll simulate a delay
        const timer = setTimeout(() => setLoading(false), 300);

        return () => clearTimeout(timer);
    }, [location, setLoading]);

    return children; // Render the children components
};

export default RouteHandler;
