import React from 'react';
import { Card, CardMedia, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

const LocationPicture = ({ locationDetails, handleOpenGallery }) => {
    const theme = useTheme();
    return (
        <>
            <Card sx={{
                position: 'relative', // Set the Card to position relative
                height: '100%',
                width: '100%',
                maxWidth: '100%',
                //maxHeight: '425px'
            }}>
                <CardMedia
                    component="img"
                    image={`${process.env.REACT_APP_PROPERTIES_PICTURES_ROOT_URL}/${locationDetails.pictures[0].file_path}`}
                    alt={locationDetails.location_name}
                    onClick={() => handleOpenGallery()}
                    sx={{ cursor: 'pointer', height: '100%', width: '100%', objectFit: 'cover' }}
                />
                <Button
                    startIcon={<PhotoCameraIcon />}
                    onClick={() => handleOpenGallery()}
                    disableRipple
                    sx={{
                        position: 'absolute', // Position the button absolutely
                        bottom: theme.spacing(2), // 2 spacing units from the bottom
                        right: theme.spacing(2), // 2 spacing units from the right
                        color: theme.palette.text.primary,
                        borderColor: theme.palette.background.paper,
                        backgroundColor: theme.palette.background.paper,
                        fontSize: '11px',
                        fontWeight: 500,
                        textTransform: 'none',
                        padding: '8px 18px',
                        '&:hover': {
                            backgroundColor: theme.palette.background.paper, // Darken the color slightly on hover
                            borderColor: theme.palette.background.paper,
                        },
                    }}
                >
                    All photos
                </Button>
            </Card>
        </>
    );
};

export default LocationPicture;
