import React, { useContext, useState, useEffect } from 'react';
import { AdminDataContext } from './AdminDataContext'; // Verify correct import path
import { useAuth } from '../contexts/AuthContext';
import { useTheme } from '@mui/material/styles';
import { Select, MenuItem, FormControl, InputLabel, Grid, Container, Box, IconButton, Menu, Typography, Skeleton, Card, CardMedia, CardContent, Divider, Chip } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useNavigate } from 'react-router-dom';

import LocationOnIcon from '@mui/icons-material/LocationOn';

const AdminDashboard = () => {
    const { data, handleCompanyChange, loading } = useContext(AdminDataContext);
    const { locations } = data;
    const { user } = useAuth();
    const [selectedCompany, setSelectedCompany] = useState('');
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentLocationId, setCurrentLocationId] = useState('');
    const navigate = useNavigate();

    const handleCardClick = (locationId) => {
        // Navigate function should only be called if the menu is not active
        if (!anchorEl) {
            navigate(`/configure-location/${locationId}`);
        }
    };

    const handleClick = (event, locationId) => {
        event.stopPropagation(); // Stop the event from propagating at the beginning

        // Toggle menu: close if already showing for this location, open otherwise
        if (anchorEl && currentLocationId === locationId) {
            setAnchorEl(null);
        } else {
            setAnchorEl(event.currentTarget);
            setCurrentLocationId(locationId);
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    useEffect(() => {
        // Check if there's a stored company id in context/localStorage and set it
        const initialCompany = data.selectedCompanyId || (data.companies.length > 0 ? data.companies[0].company_id : '');
        if (!selectedCompany && initialCompany) {
            setSelectedCompany(initialCompany);
            handleCompanyChange(initialCompany); // Update the selected company ID in the context
        }
    }, [data.companies, data.selectedCompanyId]);

    // Handle dropdown change
    const handleDropdownChange = (event) => {
        const newCompanyId = event.target.value;
        setSelectedCompany(newCompanyId);
        handleCompanyChange(newCompanyId); // Update the selected company ID in the context
    };

    return (
        <Container maxWidth="lg">
            <Grid container paddingY={1} spacing={6}>
                <Grid item container alignContent={'flex-start'}>
                    <Grid item xs={12} sm={12} mb={2}>
                        <Typography variant="h3">Admin Dashboard</Typography>
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }} mb={3}>
                            <Typography>Hello, {user ? user.email : "Loading..."}</Typography>
                            <Typography fontSize={23}>👋</Typography>
                        </Box>
                        {user && user.role === 'admin' && (
                            <FormControl sx={{ minWidth: 200 }}>
                                <InputLabel id="select-company-label">Select Company</InputLabel>
                                <Select
                                    labelId="select-company-label"
                                    value={selectedCompany}
                                    label="Select Company"
                                    onChange={handleDropdownChange}
                                >
                                    {data.companies.map(company => (
                                        <MenuItem key={company.company_id} value={company.company_id}>
                                            {company.company_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    </Grid>
                </Grid>
                <Grid item container>
                    <Grid item mb={2}>
                        {loading.locations ? (
                            <>
                                <Skeleton variant="text" height={60} width={220} sx={{ borderRadius: "10px" }} />
                            </>
                        ) : (
                            <>
                                <Typography variant="h3" id="availableWorkationLocations">Available workation locations</Typography>
                            </>
                        )}
                    </Grid>
                    <Grid item container columnSpacing={2}>
                        {loading.locations ? (
                            [1, 2, 3].map((n) => (
                                <Grid item xs={12} sm={6} md={4} lg={4} mb={3} key={n}>
                                    <Skeleton variant="rectangular" height={260} sx={{ borderRadius: "20px" }} />
                                </Grid>
                            ))
                        ) : locations.length === 0 ? (
                            // Display message when there are no locations
                            <Grid item>
                                <Typography>
                                    You don't have any Workation locations available.
                                </Typography>
                            </Grid>
                        ) : (
                            locations.map(location => (
                                <Grid item xs={12} sm={6} md={4} lg={4} mb={3} key={location.location_id}>
                                    <Card
                                        sx={{ position: 'relative', cursor: 'pointer' }}
                                        onClick={() => handleCardClick(location.location_id)}
                                    >
                                        <CardMedia
                                            component="img"
                                            height="130"
                                            image={`${process.env.REACT_APP_PROPERTIES_PICTURES_ROOT_URL}/${location.thumbnail}`}
                                            alt={location.location_name}
                                        />
                                        <IconButton
                                            sx={{
                                                position: 'absolute',
                                                right: 8,
                                                top: 8,
                                                backgroundColor: 'rgba(255, 255, 255, 0.3)', // Transparent white background
                                                '&:hover': {
                                                    backgroundColor: 'rgba(255, 255, 255, 0.5)', // Slightly less transparent on hover for better visibility
                                                }
                                            }}
                                            onClick={(e) => handleClick(e, location.location_id)}
                                        >
                                            <MoreHorizIcon />
                                        </IconButton>
                                        <Menu
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl) && currentLocationId === location.location_id}
                                            onClose={handleClose}
                                        >
                                            <MenuItem onClick={(event) => { navigate(`/configure-location/${currentLocationId}`); handleClose(); event.stopPropagation(); }}>
                                                Configure
                                            </MenuItem>
                                            <MenuItem onClick={(event) => { navigate(`/location/${currentLocationId}`); handleClose(); event.stopPropagation(); }}>
                                                See location
                                            </MenuItem>
                                        </Menu>
                                        <CardContent>
                                            <Typography display="flex" justifyContent="space-between">
                                                <Typography gutterBottom variant="subtitle1">
                                                    {location.location_name}
                                                </Typography>
                                                <Typography gutterBottom variant="subtitle2" display="flex" alignItems="center">
                                                    <LocationOnIcon color="primary" />{location.property_address}
                                                </Typography>
                                            </Typography>
                                            <Divider />
                                            <Typography gutterBottom variant="body1">
                                                Available for:
                                            </Typography>
                                            <Box display='flex' sx={{ gap: 0.5 }}>
                                                {(location.individual === 1 && data.preferences.individual === 1) && <Chip label="Individual" style={{ backgroundColor: theme.palette.individual.main, color: theme.palette.individual.contrastText }} />}
                                                {(location.family === 1 && data.preferences.family === 1) && <Chip label="Family" style={{ backgroundColor: theme.palette.family.main, color: theme.palette.family.contrastText }} />}
                                                {(location.team_integration === 1 && data.preferences.team_integration === 1) && <Chip label="Team Integration" style={{ backgroundColor: theme.palette.team_integration.main, color: theme.palette.team_integration.contrastText }} />}
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Container >
    );
};

export default AdminDashboard;
