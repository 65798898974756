import React from 'react';
import { Box, Typography, FormControl, Grid, RadioGroup, FormControlLabel, Radio, FormHelperText } from '@mui/material';
import { formatDate } from '../../../utils/dateAndTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useTheme } from '@mui/material/styles';


function TravelMode({ register, userData, errors }) {
    const theme = useTheme();

    return (
        <section><Box padding={2} marginBottom={0}>
            <h2>Hi, {userData.requestor.first_name ? userData.requestor.first_name : userData.requestor.email ? userData.requestor.email : ""}👋!</h2>
            <p>We're excited for your upcoming workation! Kindly fill out this survey so we can tailor the property to your needs and ensure a seamless arrival.</p>
        </Box>
            <Box
                padding={2}
                border={1}
                borderColor="divider"
                borderRadius={5}
                marginBottom={3}
                bgcolor={theme.palette.primary.main}
                color={theme.palette.primary.main}
                sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}
            >
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <LocationOnIcon sx={{ color: theme.palette.primary.contrastText, fontSize: '20px' }} />
                    <Typography variant="body1" sx={{ color: theme.palette.primary.contrastText }}>{userData.location.location_name}</Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <CalendarMonthIcon sx={{ color: theme.palette.primary.contrastText, fontSize: '20px' }} />
                    <Typography variant="body1" sx={{ color: theme.palette.primary.contrastText }}>{formatDate(userData.slot.start_date)} — {formatDate(userData.slot.end_date)}</Typography>
                </Box>
            </Box>
            <Box paddingX={2} paddingBottom={2} border={1} borderColor="rgb(54, 54, 54)" borderRadius={5} marginBottom={3}>
                <h2>Travel mode</h2>
                <FormControl component="fieldset">
                    <Grid item xs={12} md={12}>How will you be traveling to {userData.location.location_name}?</Grid>
                    <RadioGroup
                        aria-label="travelMode"
                        name="travelMode"
                    >
                        <FormControlLabel value="Car" control={<Radio />} {...register("travelMode", { required: "Travel mode is required" })} label="Car 🚗" />
                        <FormControlLabel value="Plane" control={<Radio />} {...register("travelMode", { required: "Travel mode is required" })} label="Plane ✈️" />
                    </RadioGroup>
                    {errors.travelMode && <FormHelperText error>{errors.travelMode.message}</FormHelperText>}
                </FormControl>
            </Box></section>
    );
}
export default TravelMode;