import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import HomeIcon from '@mui/icons-material/Home';
import Box from '@mui/material/Box';

const HandleRequest = () => {
    const { token, action } = useParams();
    const navigate = useNavigate();
    const [response, setResponse] = useState(null);

    useEffect(() => {
        // You might need to adjust the API call based on your setup
        fetch(`${process.env.REACT_APP_API_URL}/requests/${token}/${action}`)
            .then(res => res.json())
            .then(data => setResponse(data))
            .catch(error => {
                console.error('Error:', error);
                setResponse({ error: 'Failed to process the request.' });
            });
    }, [token, action]);

    const handleMessage = () => {
        if (response?.error) {
            return <Typography variant="h4" align="center">{response.error}</Typography>;
        }
        return <Typography variant="h4" align="center">{response?.message}</Typography>;
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
            {response ? (
                <>
                    {handleMessage()}
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<HomeIcon />}
                        onClick={() => navigate('/')}
                        style={{ marginTop: '20px' }}
                    >
                        Home Page
                    </Button>
                </>
            ) : (
                <Typography variant="h4" align="center">Loading...</Typography>
            )}
        </Box>
    );
};

export default HandleRequest;
