import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Navigate } from 'react-router-dom';

const RoleBasedRoute = ({ children, allowedRoles }) => {
    const { user } = useAuth();

    if (!user) {
        return <Navigate to="/login" />;
    }

    // If the user role is not allowed, redirect accordingly
    if (!allowedRoles.includes(user.role)) {
        if (['admin', 'coordinator'].includes(user.role)) {
            return <Navigate to="/admin-dashboard" />;
        } else if (user.role === 'user') {
            return <Navigate to="/home" />;
        }
        // Fallback redirection if role isn't recognized
        return <Navigate to="/login" />;
    }

    return children;
};

export default RoleBasedRoute;

