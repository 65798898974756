// SlotSettingsMenu.js
import React, { useState, useEffect } from 'react';
import { IconButton, Menu, MenuItem, ListItemIcon, CircularProgress, Typography, Box, Button } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import CallMergeIcon from '@mui/icons-material/CallMerge';
import SettingsIcon from '@mui/icons-material/Settings';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Activate icon
import CancelIcon from '@mui/icons-material/Cancel'; // Deactivate icon
import SlotSettingsDialog from './SlotSettingsDialog';
import SplitSlotDialog from './SplitSlotDialog';
import MergeSlotDialog from './MergeSlotDialog';
import AddEmployeeDialog from './AddEmployeeDialog';


import axios from '../../axios';  // Ensure axios is correctly imported
import { toast } from 'react-toastify';  // Ensure you have react-toastify configured

const SlotSettingsMenu = ({ slot, locationInfo, onSlotUpdate, countdown, resetCountdown, handleDialogToggle }) => {
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [splitSlotOpen, setSplitSlotOpen] = useState(false);
    const [mergeSlotOpen, setMergeSlotOpen] = useState(false);
    const [addEmployeeOpen, setAddEmployeeOpen] = useState(false);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [loading, setLocalLoading] = useState(false);

    const handleOpenSettingsMenu = (event) => {
        setMenuAnchorEl(event.currentTarget);
        event.stopPropagation();  // Prevents the accordion from expanding
    };

    const handleCloseMenu = (event) => {
        setMenuAnchorEl(null);
        event.stopPropagation();
    };

    const handleOpenSettingsDialog = (event) => {
        handleDialogToggle();
        setSettingsOpen(true);
        handleCloseMenu(event);
    };

    const handleCloseSettingsDialog = () => {
        handleDialogToggle();
        setSettingsOpen(false);
    };

    const handleOpenSplitSlotDialog = (event) => {
        handleDialogToggle();
        setSplitSlotOpen(true);
        handleCloseMenu(event);
    };

    const handleCloseSplitSlotDialog = () => {
        handleDialogToggle();
        setSplitSlotOpen(false);
    };

    const handleOpenMergeSlotDialog = (event) => {
        handleDialogToggle();
        setMergeSlotOpen(true);
        handleCloseMenu(event);
    };

    const handleCloseMergeSlotDialog = () => {
        handleDialogToggle();
        setMergeSlotOpen(false);
    };

    const handleOpenAddEmployeeDialog = (event) => {
        handleDialogToggle();
        setAddEmployeeOpen(true);
        handleCloseMenu(event);
    };

    const handleCloseAddEmployeeDialog = () => {
        handleDialogToggle();
        setAddEmployeeOpen(false);
    };

    const handleToggleActivation = async (event) => {
        event.stopPropagation(); // Stop propagation to prevent accordion interaction
        const action = slot.is_active ? 'deactivate' : 'activate';
        try {
            setLocalLoading(true);
            handleCloseMenu(event);  // Optionally refresh or close the menu
            const response = await axios.put(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PREFIX}/slot/${action}/${slot.slot_id}`);
            toast.success(`Slot ${slot.is_active ? 'deactivated' : 'activated'} successfully!`);
            resetCountdown(); // Reset countdown for all slots
            onSlotUpdate();
            setLocalLoading(false);
        } catch (error) {
            toast.error(`Failed to ${action} slot. ` + error.response?.data?.error || 'Please try again later.');
            setLocalLoading(false);
        }
    };

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {countdown ? <Typography variant="body2">{countdown}s.</Typography> : ""}
                <IconButton onClick={handleOpenSettingsMenu} aria-label="settings">
                    {loading ? <CircularProgress size={24} /> : <TuneIcon />}
                </IconButton>
            </Box>
            <Menu
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={handleCloseMenu}
            >
                <MenuItem onClick={handleOpenSettingsDialog}>
                    <ListItemIcon>
                        <SettingsIcon />
                    </ListItemIcon>
                    Slot Settings
                </MenuItem>
                <MenuItem onClick={handleToggleActivation}>
                    <ListItemIcon>
                        {slot.is_active ? <CancelIcon /> : <CheckCircleIcon />}
                    </ListItemIcon>
                    {slot.is_active ? 'Deactivate Slot' : 'Activate Slot'}
                </MenuItem>
                <MenuItem onClick={handleOpenSplitSlotDialog}>
                    <ListItemIcon>
                        <CallSplitIcon />
                    </ListItemIcon>
                    Split Slot
                </MenuItem>
                <MenuItem onClick={handleOpenMergeSlotDialog}>
                    <ListItemIcon>
                        <CallMergeIcon />
                    </ListItemIcon>
                    Merge Slot
                </MenuItem>
                {(slot.entire_property_available === 1 || slot.ind_availability === 1 || slot.ind_w_partner_availability === 1) && (
                    <MenuItem
                        onClick={handleOpenAddEmployeeDialog}
                    >
                        <Button
                            startIcon={<AddIcon />}
                            onClick={handleOpenAddEmployeeDialog}
                            variant='contained'
                            sx={{
                                fontSize: '12px',
                                fontWeight: 500,
                                textTransform: 'none',
                                padding: '8px 18px',
                            }}
                        >
                            Add Employee    </Button>

                    </MenuItem>
                )}

            </Menu>
            {settingsOpen && (
                <SlotSettingsDialog
                    open={settingsOpen}
                    onClose={handleCloseSettingsDialog}
                    slot={slot}
                    locationInfo={locationInfo}
                    onSlotUpdate={onSlotUpdate}  // Pass onSlotUpdate to SlotSettingsDialog
                    resetCountdown={resetCountdown}
                />
            )}
            {splitSlotOpen && (
                <SplitSlotDialog
                    open={splitSlotOpen}
                    onClose={handleCloseSplitSlotDialog}
                    slot={slot}
                    onSlotUpdate={onSlotUpdate}
                    resetCountdown={resetCountdown}
                />
            )}
            {mergeSlotOpen && (
                <MergeSlotDialog
                    open={mergeSlotOpen}
                    onClose={handleCloseMergeSlotDialog}
                    slot={slot}
                    onSlotUpdate={onSlotUpdate}
                    resetCountdown={resetCountdown}
                />
            )}
            {addEmployeeOpen && (
                <AddEmployeeDialog
                    open={addEmployeeOpen}
                    onClose={handleCloseAddEmployeeDialog}
                    slot={slot}
                    onSlotUpdate={onSlotUpdate}
                    resetCountdown={resetCountdown}
                />
            )}
        </>
    );
};

export default SlotSettingsMenu;
