import { useTheme } from '@mui/material/styles';

const DeskIcon = ({ width = '32', height = '28', color }) => {
    const theme = useTheme();
    const iconColor = color || theme.palette.primary.notUsed; // Default to theme color if not provided

    return (
        <svg width={width} height={height} viewBox="0 0 32 28" fill={iconColor} xmlns="http://www.w3.org/2000/svg">
            <path d="M31.4669 16H0.533578C0.239178 16 0.000244141 15.7611 0.000244141 15.4667V13.3333C0.000244141 13.0389 0.239178 12.8 0.533578 12.8H31.4669C31.7613 12.8 32.0003 13.0389 32.0003 13.3333V15.4667C32.0003 15.7611 31.7613 16 31.4669 16ZM1.06691 14.9333H30.9336V13.8667H1.06691V14.9333Z" fill={iconColor} />
            <path d="M2.78267 27.4551H0.55667C0.37692 27.4551 0.207744 27.3853 0.103122 27.2673C-0.00149961 27.1492 -0.0282116 26.9981 0.0307774 26.8617L5.03928 15.2343C5.11663 15.0541 5.3281 14.9333 5.56517 14.9333H7.79117C7.97092 14.9333 8.1401 15.0031 8.24472 15.1212C8.34934 15.2392 8.37605 15.3904 8.31706 15.5268L3.30856 27.1541C3.23121 27.3344 3.01974 27.4551 2.78267 27.4551ZM1.338 26.5607H2.38644L7.00984 15.8278H5.9614L1.338 26.5607Z" fill={iconColor} />
            <path d="M31.4436 27.4551H29.2176C28.9805 27.4551 28.769 27.3344 28.6916 27.1541L23.683 15.5268C23.624 15.3899 23.6513 15.2388 23.7553 15.1212C23.86 15.0031 24.0291 14.9333 24.2089 14.9333H26.435C26.672 14.9333 26.8835 15.0541 26.9609 15.2343L31.9695 26.8617C32.0285 26.9985 32.0013 27.1497 31.8972 27.2673C31.7926 27.3853 31.6239 27.4551 31.4436 27.4551ZM29.6138 26.5607H30.6623L26.0387 15.8278H24.9902L29.6138 26.5607Z" fill={iconColor} />
            <path d="M27.7336 13.8667H24.5336C24.2392 13.8667 24.0002 13.6278 24.0002 13.3334V9.06672C24.0002 8.77232 24.2392 8.53339 24.5336 8.53339H27.7336C28.028 8.53339 28.2669 8.77232 28.2669 9.06672V13.3334C28.2669 13.6278 28.028 13.8667 27.7336 13.8667ZM25.0669 12.8001H27.2002V9.60005H25.0669V12.8001Z" fill={iconColor} />
            <path d="M5.86688 13.8667H2.66688C2.37248 13.8667 2.13354 13.6278 2.13354 13.3334V9.06672C2.13354 8.77232 2.37248 8.53339 2.66688 8.53339H5.86688C6.16128 8.53339 6.40021 8.77232 6.40021 9.06672V13.3334C6.40021 13.6278 6.16128 13.8667 5.86688 13.8667ZM3.20021 12.8001H5.33355V9.60005H3.20021V12.8001Z" fill={iconColor} />
            <path d="M28.8002 11.7334H27.7335C27.4391 11.7334 27.2002 11.4945 27.2002 11.2001V9.06672C27.2002 8.77232 27.4391 8.53339 27.7335 8.53339H28.8002C29.6823 8.53339 30.4002 9.25125 30.4002 10.1334C30.4002 11.0155 29.6823 11.7334 28.8002 11.7334ZM28.2669 10.6667H28.8002C29.0946 10.6667 29.3335 10.4273 29.3335 10.1334C29.3335 9.83952 29.0946 9.60005 28.8002 9.60005H28.2669V10.6667Z" fill={iconColor} />
            <path d="M4.26673 9.59999C3.97233 9.59999 3.7334 9.36106 3.7334 9.06666V5.33332C3.7334 5.03892 3.97233 4.79999 4.26673 4.79999C4.56113 4.79999 4.80007 5.03892 4.80007 5.33332V9.06666C4.80007 9.36106 4.56113 9.59999 4.26673 9.59999Z" fill={iconColor} />
            <path d="M4.79961 9.59999C4.71961 9.59999 4.63801 9.58185 4.56121 9.54399C4.29774 9.41225 4.19107 9.09172 4.32281 8.82825L5.92281 5.62825C6.05454 5.36425 6.37508 5.25758 6.63854 5.38985C6.90201 5.52158 7.00868 5.84212 6.87694 6.10558L5.27694 9.30559C5.18361 9.49225 4.99534 9.59999 4.79961 9.59999Z" fill={iconColor} />
            <path d="M3.73413 9.59999C3.53839 9.59999 3.35013 9.49225 3.25679 9.30505L1.65679 6.10505C1.52506 5.84158 1.63173 5.52105 1.89519 5.38932C2.15813 5.25652 2.47866 5.36425 2.61093 5.62772L4.21093 8.82772C4.34266 9.09119 4.23599 9.41172 3.97253 9.54345C3.89573 9.58185 3.81413 9.59999 3.73413 9.59999Z" fill={iconColor} />
            <path d="M18.1335 13.8666H13.8668C12.9847 13.8666 12.2668 13.1488 12.2668 12.2666C12.2668 11.3845 12.9847 10.6666 13.8668 10.6666H18.1335C19.0157 10.6666 19.7335 11.3845 19.7335 12.2666C19.7335 13.1488 19.0157 13.8666 18.1335 13.8666ZM13.8668 11.7333C13.5724 11.7333 13.3335 11.9728 13.3335 12.2666C13.3335 12.5605 13.5724 12.8 13.8668 12.8H18.1335C18.4279 12.8 18.6669 12.5605 18.6669 12.2666C18.6669 11.9728 18.4279 11.7333 18.1335 11.7333H13.8668Z" fill={iconColor} />
            <path d="M22.4002 9.06667H9.60023C9.30583 9.06667 9.06689 8.82774 9.06689 8.53334V0.533334C9.06689 0.238934 9.30583 0 9.60023 0H22.4002C22.6946 0 22.9336 0.238934 22.9336 0.533334V8.53334C22.9336 8.82774 22.6946 9.06667 22.4002 9.06667ZM10.1336 8.00001H21.8669V1.06667H10.1336V8.00001Z" fill={iconColor} />
            <path d="M22.4002 9.06664H9.60023C9.30583 9.06664 9.06689 8.82771 9.06689 8.53331V6.39997C9.06689 6.10557 9.30583 5.86664 9.60023 5.86664H22.4002C22.6946 5.86664 22.9336 6.10557 22.9336 6.39997V8.53331C22.9336 8.82771 22.6946 9.06664 22.4002 9.06664ZM10.1336 7.99997H21.8669V6.93331H10.1336V7.99997Z" fill={iconColor} />
            <path d="M17.6003 11.7333H14.4003C14.2403 11.7333 14.0894 11.6619 13.988 11.5381C13.8867 11.4149 13.8462 11.2523 13.8776 11.0955L14.411 8.4288C14.4606 8.1792 14.6792 8 14.9336 8H17.067C17.3214 8 17.54 8.1792 17.5896 8.4288L18.123 11.0955C18.1544 11.2523 18.1139 11.4149 18.0126 11.5381C17.9118 11.6619 17.7603 11.7333 17.6003 11.7333ZM15.051 10.6667H16.9502L16.6302 9.06667H15.3715L15.051 10.6667Z" fill={iconColor} />
        </svg>
    );
};
export default DeskIcon;