import React, { createContext, useState, useEffect } from 'react';
import axios from '../axios'; // Adjust the path as needed
import { useLoading } from './LoadingContext'; // Import useLoading

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
    const [data, setData] = useState({
        locations: [],
        preferences: {},
        workations: [],
        invites_to_use: [],
        workationsInfo: { workationsToUse: 0, expirationYear: new Date().getFullYear() } // Updated line
    });
    const [localLoading, setLocalLoading] = useState({ locations: true, workations: true });
    const { setLoading } = useLoading(); // Use setLoading from LoadingContext for global spinner

    const calculateWorkationsToUse = (preferences, bookedIndividualWorkationsCurrentYear, bookedIndividualWorkationsNextYear) => {
        const today = new Date();
        //const currentYear = today.getFullYear();
        //const individualWorkationsBooked = workations.filter(w => w.occupancy_type === "individual").length;
        const nextYearLimitApplies = today.getMonth() + 1 >= (12 - preferences.months_to_refresh_limit);
        //const totalLimit = preferences.limit_in_the_year * (nextYearLimitApplies ? 2 : 1);
        const individualYearLimit = preferences.limit_in_the_year;
        const thisYearWorkationsToUse = individualYearLimit - bookedIndividualWorkationsCurrentYear;
        const nextYearWorkationsToUse = individualYearLimit - bookedIndividualWorkationsNextYear;
        //const expirationYear = nextYearLimitApplies ? currentYear + 1 : currentYear;

        return { thisYearWorkationsToUse, nextYearWorkationsToUse, nextYearLimitApplies };
    };

    const fetchData = async () => {
        setLoading(true); // Set global loading state for spinner
        setLocalLoading({ locations: true, workations: true }); // Set local loading states

        try {
            // Fetch locations first
            const locationsResponse = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PREFIX}/workation-locations`);
            const locationsData = locationsResponse.data;

            // Then fetch workations
            const workationsResponse = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PREFIX}/workations/workations-user`);
            const workationsData = workationsResponse.data.workations;
            const bookedIndividualWorkationsCurrentYear = workationsResponse.data.bookedIndividualWorkationsCurrentYear;
            const bookedIndividualWorkationsNextYear = workationsResponse.data.bookedIndividualWorkationsNextYear;
            const currentYear = workationsResponse.data.currentYear;

            // Calculate workationsToUse
            const { thisYearWorkationsToUse, nextYearWorkationsToUse, nextYearLimitApplies } = calculateWorkationsToUse(locationsData.preferences, bookedIndividualWorkationsCurrentYear, bookedIndividualWorkationsNextYear);

            // Update state with fetched data and calculated workationsToUse
            setData(prevData => ({
                ...prevData,
                locations: locationsData.locations,
                preferences: locationsData.preferences,
                invites_to_use: locationsData.invites_to_use,
                workations: workationsData,
                workationsInfo: { thisYearWorkationsToUse, nextYearWorkationsToUse, nextYearLimitApplies, currentYear } // Updated line
            }));

            setLocalLoading(prevLoading => ({ ...prevLoading, locations: false, workations: false }));
        } catch (error) {
            console.error('Error fetching data:', error);
            setLocalLoading({ locations: false, workations: false });
        } finally {
            setLoading(false); // Unset global loading state for spinner
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <DataContext.Provider value={{ data, setData, fetchData, loading: localLoading }}>
            {children}
        </DataContext.Provider>
    );
};