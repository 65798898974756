import React, { useState, useEffect } from 'react';
import { Card, CardContent, TextField, Button, Typography, FormControlLabel, Checkbox, Box, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useAuth } from '../contexts/AuthContext';

import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link'; // Import Material-UI Link
import { useLoading } from './LoadingContext'; // Import useLoading




const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [firstName, setFirstName] = useState('');
    const { user } = useAuth(); // Assuming setUser is used to update the user in AuthContext
    const [lastName, setLastName] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { setLoading } = useLoading(); // Get setLoading from context

    useEffect(() => {
        if (user) {
            navigate('/home'); // Redirect to home if already logged in and company ID is assigned
        }
    }, [user, navigate]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');

        try {
            setLoading(true);
            await axios.post(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_PREFIX}/register`, {
                email,
                firstName,
                lastName,
                password,
                password_confirmation: passwordConfirmation
            });
            setLoading(false);
            toast.success('Registration successful! Please check your email to confirm registration.');
            navigate('/login');
        } catch (error) {
            setLoading(false);
            if (error.response?.data?.errors) {
                const firstErrorKey = Object.keys(error.response.data.errors)[0];
                const firstErrorMessage = error.response.data.errors[firstErrorKey][0];
                setError(firstErrorMessage);
            } else {
                setError('An unexpected error occurred.');
            }
        }
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="80vh">
            <Card sx={{ minWidth: 275, maxWidth: 360 }}>
                <CardContent>
                    <Typography variant="h2" component="div" gutterBottom sx={{ textAlign: 'center' }}>
                        Deskou
                    </Typography>
                    <Typography variant="h1" component="div" gutterBottom sx={{ textAlign: 'center' }}>
                        Sign up
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary" >
                        Rigister to Deskou Workation App and enojy your Workation!
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            required
                            label="Email Address"
                            fullWidth
                            margin="normal"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            fullWidth
                            label="First Name"
                            margin="normal"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                        />
                        <TextField
                            fullWidth
                            label="Last Name"
                            margin="normal"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                        />
                        <TextField
                            required
                            label="Password"
                            type="password"
                            fullWidth
                            margin="normal"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value)
                                setPasswordConfirmation(e.target.value)
                            }
                            }
                        />
                        {/* <TextField
                            required
                            label="Confirm Password"
                            type="password"
                            fullWidth
                            margin="normal"
                            value={passwordConfirmation}
                            onChange={(e) => setPasswordConfirmation(e.target.value)}
                        /> */}
                        <FormControlLabel
                            control={<Checkbox required />}
                            label={
                                <span>
                                    I accept the
                                    {' '}
                                    <RouterLink to="/termsandconditions" style={{ color: 'inherit' }}>  {/* Adjust color as needed */}
                                        terms and conditions
                                    </RouterLink>.
                                </span>
                            }
                        />
                        <Box mt={2}>
                            <Button type="submit" variant="contained" color="primary" fullWidth>
                                Sign Up
                            </Button>
                        </Box>
                    </form>
                    {error && (
                        <Alert severity="error" sx={{ mt: 2 }}>
                            {error}
                        </Alert>
                    )}
                    <Box mt={2} display="flex" justifyContent="center" alignItems="center">
                        <Typography variant="body2">Already have an account?&nbsp;</Typography>
                        <Link to="/login" component={RouterLink}>
                            <Typography variant="body2">Log in.</Typography>
                        </Link>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};
export default Register;
