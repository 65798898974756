import React from 'react';
import { Container, IconButton, Typography, Link, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const TermsAndConditions = () => {

    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };
    return (
        <Container maxWidth="md" sx={{ paddingY: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                <IconButton onClick={handleBack} aria-label="back">
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h3" m={0}>Terms and Conditions</Typography>
            </Box>
            <Typography paragraph>
                Welcome to Deskou Workation! These terms and conditions outline the rules and regulations for the use of Deskou Workation's Website, located at app.deskou.com.
            </Typography>
            <Typography paragraph>
                By accessing this website, we assume you accept these terms and conditions. Do not continue to use Deskou Workation if you do not agree to take all of the terms and conditions stated on this page.
            </Typography>
            <Typography variant="h4">Access to the App</Typography>
            <Typography paragraph>
                Access to the Deskou Workation app is restricted to employees of companies that have an agreement with Deskou Workation. Access will be granted through valid company email domains only.
            </Typography>
            <Typography variant="h4">Account Registration and Use</Typography>
            <Typography paragraph>
                Employees may sign up for available workation slots at designated locations. Slots may be available for individual workations, with the possibility of bringing one accompanying person, or for family and team integration workations, subject to company policies and slot settings.
            </Typography>
            <Typography variant="h4">Vouchers and Approval</Typography>
            <Typography paragraph>
                Certain workations may require a voucher, which will expire after a set period. Workation bookings are subject to approval by the employee's line manager, in accordance with specific company rules detailed separately.
            </Typography>
            <Typography variant="h4">Limitations and Liability</Typography>
            <Typography paragraph>
                Deskou Workation is not liable for any direct, indirect, special, or consequential loss or damage arising out of the use or inability to use the services provided.
            </Typography>
            <Typography variant="h4">Contact Information</Typography>
            <Typography paragraph>
                For any problems or inquiries, please contact us at <Link href="mailto:hello@deskou.com">hello@deskou.com</Link>.
            </Typography>
            <Typography variant="h4">Changes to Terms and Conditions</Typography>
            <Typography paragraph>
                Deskou Workation reserves the right to modify these terms and conditions at any time. We will notify users of any changes through our website or app.
            </Typography>
            <Typography paragraph>
                These terms and conditions were last updated on 14.02.2024.
            </Typography>
        </Container>
    );
};

export default TermsAndConditions;
